<script>
import { mapGetters } from 'vuex';
import { useAdmin } from 'dashboard/composables/useAdmin';

import BoardList from './list/Index.vue';
import ItemLayout from './ItemLayout.vue'; // Import new layout component
import ItemHeader from './ItemHeader.vue'; // Import new header component
import ItemEmptyState from './ItemEmptyState.vue'; // Import new empty state component
import Spinner from 'dashboard/components-next/spinner/Spinner.vue';

export default {
  components: {
    BoardList,
    ItemLayout,
    ItemHeader,
    ItemEmptyState,
    Spinner,
  },
  emits: ['editBoard'],
  setup() {
    const { isAdmin } = useAdmin();
    return {
      isAdmin,
    };
  },
  computed: {
    ...mapGetters({
      currentUserID: 'getCurrentUserID',
      uiFlags: 'wgptBoardLists/getUIFlags',
    }),
    boardId() {
      return this.$route.params.boardId;
    },
    board() {
      return this.$store.getters['wgptBoards/getBoard'](this.boardId);
    },
    canReadBoard() {
      if (this.isAdmin) return true;
      const managers = this.board?.config?.authorization?.managers ?? [];
      if (managers.includes(this.currentUserID)) return true;
      const operators = this.board?.config?.authorization?.operators ?? [];
      if (operators.includes(this.currentUserID)) return true;
      const readers = this.board?.config?.authorization?.readers ?? [];
      if (readers.includes(this.currentUserID)) return true;
      return false;
    },
  },
  mounted() {
    this.$store.dispatch('wgptBoards/show', this.boardId);
  },
  methods: {
    editBoard() {
      this.$emit('editBoard', this.board);
    },
  },
};
</script>

<template>
  <ItemLayout :show-header-actions="board">
    <template #header>
      <ItemHeader :board="board" @edit="editBoard" />
    </template>
    <template #content>
      <div
        v-if="uiFlags.isFetching"
        class="w-full flex items-center justify-center py-10 text-n-slate-11"
      >
        <Spinner />
      </div>
      <div v-show="!uiFlags.isFetching" class="contents">
        <BoardList v-if="canReadBoard" :board="board" />
        <ItemEmptyState
          v-else
          :title="$t('WGPT_BOARDS.ITEM.UNAUTHORIZED.TITLE')"
          :subtitle="$t('WGPT_BOARDS.ITEM.UNAUTHORIZED.SUBTITLE')"
        />
      </div>
    </template>
  </ItemLayout>
</template>
