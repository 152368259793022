<script>
import { required } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';

import InputNext from 'dashboard/components-next/input/Input.vue';
import TextAreaNext from 'dashboard/components-next/textarea/TextArea.vue';
import ButtonNext from 'dashboard/components-next/button/Button.vue';
import FormSwitch from 'v3/components/Form/Switch.vue';

export default {
  components: {
    InputNext,
    TextAreaNext,
    ButtonNext,
    FormSwitch,
  },
  props: {
    canManageLists: {
      type: Boolean,
      default: false,
    },
    board: {
      type: Object,
      default: () => {},
    },
    list: {
      type: Object,
      default: () => {},
    },
  },
  emits: ['delete', 'close', 'submit'],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      name: '',
      description: '',
      isArchive: false,
    };
  },
  validations: {
    name: {
      required,
    },
    description: {},
    isArchive: {},
  },
  computed: {
    readonly() {
      return this.board.closed || !this.canManageLists;
    },
    state() {
      return {
        name: this.name,
        description: this.description,
        archive: this.isArchive,
      };
    },
  },
  mounted() {
    this.name = this.list.name;
    this.description = this.list.description;
    this.isArchive = this.list.archive;
  },
  methods: {
    onClose() {
      this.$emit('close');
    },
    onDelete() {
      this.$emit('delete');
    },
    async onSubmit() {
      this.$emit('submit');
    },
  },
};
</script>

<template>
  <form class="flex flex-col gap-8 pb-16" @submit.prevent="onSubmit">
    <InputNext
      v-model.trim="name"
      type="text"
      :label="$t('WGPT_BOARDS.ITEM.LIST.EDIT.FORM.NAME.LABEL')"
      :disabled="readonly"
      :message="
        v$.name.$error ? $t('WGPT_BOARDS.ITEM.LIST.EDIT.FORM.NAME.ERROR') : ''
      "
      :message-type="v$.name.$error ? 'error' : 'info'"
      @input="v$.name.$touch"
    />

    <TextAreaNext
      v-model.trim="description"
      :label="$t('WGPT_BOARDS.ITEM.LIST.EDIT.FORM.DESCRIPTION.LABEL')"
      custom-text-area-class="!outline !outline-0"
      rows="3"
      :disabled="readonly"
      @input="v$.description.$touch"
    />

    <div
      class="flex items-center justify-between w-full gap-2 p-4 border border-solid border-ash-200 rounded-xl"
    >
      <div class="flex flex-row items-center gap-2">
        <fluent-icon
          icon="checkmark-circle"
          type="solid"
          class="flex-shrink-0 text-green-500"
          size="18"
        />
        <span class="text-sm text-ash-900">
          {{ $t('WGPT_BOARDS.ITEM.LIST.EDIT.FORM.ARCHIVE.LABEL') }}
        </span>
      </div>
      <FormSwitch
        v-model="isArchive"
        :disabled="readonly"
        @change="v$.isArchive.$touch"
      />
    </div>

    <template v-if="canManageLists">
      <div class="w-full h-px bg-slate-50 dark:bg-slate-800/50" />

      <div class="flex flex-col-">
        <div class="flex items-end justify-between w-full gap-4">
          <div class="flex flex-col gap-2">
            <h6 class="text-base font-medium text-n-slate-12">
              {{ $t('WGPT_BOARDS.ITEM.LIST.EDIT.FORM.DELETE_LIST') }}
            </h6>
            <span class="text-sm text-n-slate-11">
              {{ $t('WGPT_BOARDS.ITEM.LIST.EDIT.FORM.DELETE_LIST_DESC') }}
            </span>
          </div>
          <div class="shrink-0">
            <ButtonNext
              :label="$t('WGPT_BOARDS.ITEM.LIST.EDIT.FORM.DELETE')"
              color="ruby"
              class="max-w-56 !w-fit"
              type="button"
              @click.prevent="onDelete"
            />
          </div>
        </div>
      </div>
    </template>
  </form>
</template>
