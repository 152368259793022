<script>
import { useAlert } from 'dashboard/composables/wgpt';
import EditDates from '../dates/EditDates.vue';

import ButtonNext from 'dashboard/components-next/button/Button.vue';
import Dropdown from 'dashboard/components-next/wgpt/Dropdown/Dropdown.vue';

export default {
  components: {
    EditDates,
    Dropdown,
    ButtonNext,
  },
  props: {
    board: {
      type: Object,
      default: () => {},
    },
    list: {
      type: Object,
      default: () => {},
    },
    card: {
      type: Object,
      default: () => {},
    },
    disabled: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      showDropdown: false,
      innerDropdownOpen: false,
    };
  },
  methods: {
    openDropdown() {
      this.showDropdown = true;
    },
    closeDropdown() {
      if (this.innerDropdownOpen) return;
      this.showDropdown = false;
    },
    async onDatesChange(data) {
      if (this.readonly) return;
      try {
        const boardId = this.board.id;
        const listId = this.list.id;
        const id = this.card.id;
        await this.$store.dispatch('wgptBoardListCards/update', {
          boardId,
          listId,
          id,
          ...data,
        });
      } catch (error) {
        useAlert(
          this.$t('WGPT_BOARDS.ITEM.LIST.CARD.EDIT.DATES.API.ERROR_MESSAGE')
        );
      }
    },
    onDropdownOpen() {
      this.innerDropdownOpen = true;
    },
    onDropdownClose() {
      this.innerDropdownOpen = false;
    },
  },
};
</script>

<template>
  <div class="relative">
    <Dropdown>
      <template #trigger="{ toggle }">
        <ButtonNext
          :label="$t('WGPT_BOARDS.ITEM.LIST.CARD.EDIT.SIDEBAR.DATES.LABEL')"
          size="sm"
          variant="faded"
          color="slate"
          icon="i-lucide-calendar-clock"
          :disabled="disabled"
          class="w-full !justify-start"
          @click="toggle"
        />
      </template>
      <template #default="{ toggle }">
        <EditDates
          :board="board"
          :list="list"
          :card="card"
          :on-submit="onDatesChange"
          @close="toggle"
          @dropdown-open="onDropdownOpen"
          @dropdown-close="onDropdownClose"
        />
      </template>
    </Dropdown>
  </div>
</template>

<style lang="scss" scoped>
li.disabled {
  @apply opacity-50 pointer-events-none select-none;
}
</style>
