<script>
import { mapGetters } from 'vuex';
import AddLabel from './Labels.vue';
import AddContacts from './Contacts.vue';
import AddMembers from './Members.vue';
import AddDate from './Dates.vue';
import AddAttachments from './Attachments.vue';
import { useAlert } from 'dashboard/composables/wgpt';
import canDismissMixin from '../../mixins/preventDismissMixin';
import { isAInboxViewRoute } from 'dashboard/helper/routeHelpers';
import AutomationSection from './AutomationSection.vue';
import ConfirmDeleteCardDialog from '../DeleteCardDialog.vue';
import ButtonNext from 'dashboard/components-next/button/Button.vue';

export default {
  components: {
    AddLabel,
    AddContacts,
    AddMembers,
    AddDate,
    AddAttachments,
    AutomationSection,
    ButtonNext,
    ConfirmDeleteCardDialog,
  },
  mixins: [canDismissMixin],
  props: {
    board: {
      type: Object,
      default: () => {},
    },
    list: {
      type: Object,
      default: () => {},
    },
    card: {
      type: Object,
      default: () => {},
    },
    readonly: {
      type: Boolean,
      default: true,
    },
    labels: {
      type: Array,
      default: () => [],
    },
    agents: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['onDelete'],
  data() {
    return {
      showOptionsMenu: false,
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'wgptBoardListCards/getUIFlags',
    }),
    additionalActions() {
      const additionalActions = [
        {
          label: this.$t('WGPT_BOARDS.ITEM.LIST.CARD.EDIT.SIDEBAR.DELETE'),
          icon: 'i-lucide-trash',
          handler: this.openDeleteCardPopup,
          disabled: this.readonly,
          color: 'ruby',
          variant: 'outline',
        },
      ];
      return additionalActions;
    },
  },
  methods: {
    openDeleteCardPopup() {
      this.$refs.confirmDeleteCardDialogRef.open();
    },
    async deleteCard() {
      try {
        const boardId = this.board.id;
        const listId = this.list.id;
        const id = this.card.id;
        await this.$store.dispatch('wgptBoardListCards/delete', {
          boardId,
          listId,
          id,
        });

        if (isAInboxViewRoute(this.$route.name)) {
          this.$router.push({ name: 'inbox_view' });
        }
        useAlert(
          this.$t('WGPT_BOARDS.ITEM.LIST.CARD.DELETE.API.SUCCESS_MESSAGE')
        );
        this.$refs.confirmDeleteCardDialogRef.close();
        this.$nextTick(() => {
          this.$emit('onDelete');
        });
      } catch (error) {
        useAlert(
          this.$t('WGPT_BOARDS.ITEM.LIST.CARD.DELETE.API.ERROR_MESSAGE')
        );
      }
    },
  },
};
</script>

<template>
  <div>
    <div class="flex justify-between">
      <label>
        {{ $t('WGPT_BOARDS.ITEM.LIST.CARD.EDIT.ADD_TO_CARD') }}
      </label>
    </div>
    <ul class="list-none ml-0 mb-0 flex flex-col gap-1.5">
      <AddMembers
        :board="board"
        :list="list"
        :card="card"
        :agents="agents"
        :disabled="readonly"
      />
      <AddLabel
        :board="board"
        :list="list"
        :card="card"
        :labels="labels"
        :disabled="readonly"
      />
      <AddDate
        :board="board"
        :list="list"
        :card="card"
        :labels="labels"
        :disabled="readonly"
      />
      <AddContacts
        :board="board"
        :list="list"
        :card="card"
        :disabled="readonly"
      />
      <AddAttachments
        :board="board"
        :list="list"
        :card="card"
        :disabled="readonly"
      />
    </ul>

    <AutomationSection
      :board="board"
      :list="list"
      :card="card"
      :disabled="readonly"
    />

    <div class="flex justify-between mt-5">
      <label>
        {{ $t('WGPT_BOARDS.ITEM.LIST.CARD.EDIT.SIDEBAR.ACTIONS') }}
      </label>
    </div>
    <ul class="list-none ml-0 mb-0 flex flex-col gap-1.5">
      <ButtonNext
        v-for="(action, index) in additionalActions"
        :key="index"
        :label="action.label"
        size="sm"
        :variant="action.variant"
        :color="action.color"
        :icon="action.icon"
        :disabled="action.disabled"
        class="w-full !justify-start"
        @click="action.handler"
      />
    </ul>

    <ConfirmDeleteCardDialog
      ref="confirmDeleteCardDialogRef"
      :card="card"
      :on-confirm="deleteCard"
      :is-loading="uiFlags.isDeleting"
    />
  </div>
</template>
