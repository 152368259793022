export default [
  {
    id: 1,
    name: '📍 Project Roadmap',
    description:
      "Visualize your project's journey with clearly defined goals and milestones.",
    slug: 'project-roadmap',
    meta: {
      articles_count: 0,
    },
  },
  {
    id: 2,
    name: '🗓️ Content Calendar',
    description: 'Plan, draft, and schedule your content effortlessly.',
    slug: 'content-calendar',
    meta: {
      articles_count: 0,
    },
  },
  {
    id: 3,
    name: '💼 Sales Pipeline',
    description: 'Track leads and deals from prospecting to closing.',
    slug: 'sales-pipeline',
    meta: {
      articles_count: 0,
    },
  },
  {
    id: 4,
    name: '👔 Hiring Process',
    description: 'Organize job applications and hiring stages for your team.',
    slug: 'hiring-process',
    meta: {
      articles_count: 0,
    },
  },
  {
    id: 5,
    name: '⚙️ Product Development',
    description: 'Manage features and bug fixes from ideation to release.',
    slug: 'product-development',
    meta: {
      articles_count: 0,
    },
  },
  {
    id: 6,
    name: '🎉 Event Planning',
    description: 'Coordinate and execute all tasks for a successful event.',
    slug: 'event-planning',
    meta: {
      articles_count: 0,
    },
  },
  {
    id: 7,
    name: '🤝 Client Onboarding',
    description: 'Streamline the onboarding process for new clients.',
    slug: 'client-onboarding',
    meta: {
      articles_count: 0,
    },
  },
  {
    id: 8,
    name: '📝 Personal Tasks',
    description: 'Keep track of your daily to-dos and long-term goals.',
    slug: 'personal-tasks',
    meta: {
      articles_count: 0,
    },
  },
  {
    id: 9,
    name: '📊 Marketing Campaign',
    description: 'Plan and execute your marketing strategies effectively.',
    slug: 'marketing-campaign',
    meta: {
      articles_count: 0,
    },
  },
  {
    id: 10,
    name: '🎨 Design Workflow',
    description: 'Organize design assets and track review cycles.',
    slug: 'design-workflow',
    meta: {
      articles_count: 0,
    },
  },
  {
    id: 11,
    name: '🚀 Team Sprint',
    description: "Manage your team's sprint backlog and tasks collaboratively.",
    slug: 'team-sprint',
    meta: {
      articles_count: 0,
    },
  },
  {
    id: 12,
    name: '🐞 Bug Tracker',
    description: 'Identify, prioritize, and resolve software issues.',
    slug: 'bug-tracker',
    meta: {
      articles_count: 0,
    },
  },
  {
    id: 13,
    name: '📘 Learning Goals',
    description:
      'Track progress and milestones for personal growth and education.',
    slug: 'learning-goals',
    meta: {
      articles_count: 0,
    },
  },
  {
    id: 14,
    name: '✈️ Travel Itinerary',
    description: 'Plan and organize every detail of your upcoming trips.',
    slug: 'travel-itinerary',
    meta: {
      articles_count: 0,
    },
  },
];
