<script>
import WootDropdownItem from 'shared/components/ui/dropdown/DropdownItem.vue';
import Avatar from 'dashboard/components-next/avatar/Avatar.vue';

export default {
  components: {
    WootDropdownItem,
    Avatar,
  },
  props: {
    contactId: {
      type: [String, Number],
      default: '',
    },
  },
  emits: ['toggle', 'notFound', 'found'],
  data() {
    return {
      exists: undefined,
      contactCache: null,
    };
  },
  computed: {
    contactReactive() {
      return this.$store.getters['contacts/getContact'](this.contactId);
    },
    contact() {
      return this.contactCache ?? this.contactReactive;
    },
    loading() {
      return !this.contactCache;
    },
  },
  watch: {
    contactReactive: {
      handler(value) {
        if (!this.contactCache || value.id === this.contactId) {
          this.contactCache = value;
        }
      },
      immediate: true,
    },
    contactCache: {
      handler(value) {
        if (!value) return;
        if (value.id === this.contactId) {
          this.exists = true;
          this.$emit('found');
        } else {
          this.exists = false;
          this.$emit('notFound');
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.$store.dispatch('contacts/show', { id: this.contactId });
  },
  methods: {
    onclick(contact) {
      this.$emit('toggle', contact);
    },
  },
};
</script>

<!-- eslint-disable-next-line vue/no-root-v-if -->
<template>
  <div v-if="exists">
    <div
      v-if="loading"
      class="h-10 bg-slate-100 dark:bg-slate-500/25 wgpt-animate-pulse rounded mb-1"
    />

    <WootDropdownItem v-else>
      <button
        class="inline-flex items-center justify-start w-full h-8 min-w-0 gap-2 px-2 py-1.5 transition-all duration-200 ease-in-out border-0 rounded-lg z-60 hover:bg-n-alpha-1 dark:hover:bg-n-alpha-2 disabled:cursor-not-allowed disabled:pointer-events-none disabled:opacity-50 text-n-slate-12 bg-n-alpha-1 dark:bg-n-solid-active"
        @click="() => onclick(contact)"
        @keydown.enter="() => onclick(contact)"
      >
        <slot name="thumbnail" :item="contact">
          <Avatar
            :name="contact.name"
            :src="contact.thumbnail"
            :size="20"
            rounded-full
          />
        </slot>
        <span v-if="contact.name" class="min-w-0 text-sm truncate">{{
          contact.name
        }}</span>
      </button>
    </WootDropdownItem>
  </div>
</template>

<style lang="scss" scoped>
.multiselect-dropdown--item {
  @apply justify-between w-full;

  &.active {
    @apply bg-slate-25 dark:bg-slate-700 border-slate-50 dark:border-slate-900 font-medium;
  }

  &:focus {
    @apply bg-slate-25 dark:bg-slate-700;
  }

  &:hover {
    @apply bg-slate-50 dark:bg-slate-800 text-slate-800 dark:text-slate-100;
  }
}

.wgpt-animate-pulse {
  animation: wgptAnimatePulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

@keyframes wgptAnimatePulse {
  50% {
    opacity: 0.5;
  }
}
</style>
