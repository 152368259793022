<script>
import Icon from 'dashboard/components-next/icon/Icon.vue';

export default {
  components: {
    Icon,
  },
  props: {
    card: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<template>
  <div
    class="inline-flex items-center rounded-[4px] border border-slate-100 dark:border-slate-700/50 divide-x divide-slate-100 dark:divide-slate-700/50 bg-none"
  >
    <div v-if="card" class="flex items-center gap-0.5 py-0.5 px-1.5">
      <Icon
        class="text-slate-600 dark:text-slate-300"
        icon="i-lucide-layout-dashboard"
        :size="14"
      />
      <span class="font-medium text-slate-600 dark:text-slate-200 text-xs">
        {{ card.key }}
      </span>
    </div>
  </div>
</template>
