<script setup>
import { ref } from 'vue';

const props = defineProps({
  content: {
    type: String,
    default: '',
  },
  duration: {
    type: Number,
    default: 2000,
  },
  placement: {
    type: String,
    default: 'top',
  },
  popperClass: {
    type: String,
    default: '',
  },
  strategy: {
    type: String,
    default: 'fixed',
  },
});

const tooltipRef = ref(null);
const isShown = ref(false);
let timeoutId = null;

const show = () => {
  isShown.value = true;
  if (timeoutId) {
    clearTimeout(timeoutId);
  }
  timeoutId = setTimeout(() => {
    isShown.value = false;
    timeoutId = null;
  }, props.duration);
};

defineExpose({ show });
</script>

<template>
  <div
    ref="tooltipRef"
    v-tooltip="{
      content: content,
      container: false,
      shown: isShown,
      triggers: [],
      hideTriggers: [],
      strategy: strategy,
      placement: placement,
      popperClass: popperClass,
    }"
    class="inline"
  >
    <slot />
  </div>
</template>
