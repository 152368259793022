<script>
import { useAlert } from 'dashboard/composables/wgpt';
import { useAccount } from 'dashboard/composables/useAccount';
import { mapGetters } from 'vuex';
import { FEATURE_FLAGS } from 'dashboard/featureFlags.js';

import ExecuteCardAutomationDialog from '../ExecuteCardAutomationDialog.vue';
import ButtonNext from 'dashboard/components-next/button/Button.vue';

export default {
  components: {
    ButtonNext,
    ExecuteCardAutomationDialog,
  },
  props: {
    board: {
      type: Object,
      default: () => {},
    },
    list: {
      type: Object,
      default: () => {},
    },
    card: {
      type: Object,
      default: () => {},
    },
    disabled: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    const { accountId } = useAccount();

    return {
      accountId,
    };
  },
  data() {
    return {
      selectedAutomation: {},
    };
  },
  computed: {
    ...mapGetters({
      isFeatureEnabledonAccount: 'accounts/isFeatureEnabledonAccount',
      automations: 'wgptBoards/getBoardAutomations',
      cardsUiFlags: 'wgptBoardListCards/getUIFlags',
      boardsUiFlags: 'wgptBoards/getUIFlags',
    }),
    isFeatureEnabled() {
      return this.isFeatureEnabledonAccount(
        this.accountId,
        FEATURE_FLAGS.WGPT_AUTOMATIONS
      );
    },
    isNotLoading() {
      return !this.boardsUiFlags.isLoading;
    },
    isNotEmpty() {
      return this.automations.length > 0;
    },
  },
  methods: {
    openConfirmDialog(automation) {
      this.selectedAutomation = automation;
      this.$refs.executeCardAutomationDialogRef.open();
    },
    async executeAutomation() {
      if (this.disabled) return;
      try {
        const options = {
          boardId: this.board.id,
          listId: this.list.id,
          cardId: this.card.id,
          id: this.selectedAutomation.id,
        };
        await this.$store.dispatch(
          'wgptBoardListCards/executeAutomation',
          options
        );
        this.$refs.executeCardAutomationDialogRef.close();
        useAlert(
          this.$t(
            'WGPT_BOARDS.ITEM.LIST.CARD.EDIT.SIDEBAR.AUTOMATION.API.SUCCESS'
          )
        );
      } catch (error) {
        useAlert(
          this.$t(
            'WGPT_BOARDS.ITEM.LIST.CARD.EDIT.SIDEBAR.AUTOMATION.API.ERROR_MESSAGE'
          )
        );
      }
    },
  },
};
</script>

<template>
  <div v-show="isFeatureEnabled && isNotLoading && isNotEmpty">
    <div class="flex justify-between mt-5">
      <label>
        {{ $t('WGPT_BOARDS.ITEM.LIST.CARD.EDIT.SIDEBAR.AUTOMATION.LABEL') }}
      </label>
    </div>
    <ul class="list-none ml-0 mb-0 flex flex-col gap-1.5">
      <ButtonNext
        v-for="automation in automations"
        :key="automation.id"
        v-tooltip.left="{
          content: automation.description,
          triggers: ['hover'],
          popperClass: 'wgpt-boards-automation-button-tooltip',
          autoHide: true,
          container: false,
          delay: { show: 500, hide: 0 },
        }"
        :label="automation.name"
        size="sm"
        variant="faded"
        color="slate"
        icon="i-lucide-workflow"
        :disabled="disabled || !automation.active"
        class="w-full !justify-start"
        @click="openConfirmDialog(automation)"
      />
    </ul>

    <ExecuteCardAutomationDialog
      ref="executeCardAutomationDialogRef"
      :automation="selectedAutomation"
      :on-confirm="executeAutomation"
      :is-loading="cardsUiFlags.isExecuting"
    />
  </div>
</template>

<style lang="scss">
.wgpt-boards-automation-button-tooltip {
  max-width: 300px;
  white-space: wrap;
}
</style>
