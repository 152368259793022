<script>
import { mapGetters } from 'vuex';
import { useAdmin } from 'dashboard/composables/useAdmin';

import BoardCard from './components/BoardCard.vue';
import OverviewEmptyState from './OverviewEmptyState.vue';

export default {
  components: {
    BoardCard,
    OverviewEmptyState,
  },
  props: {
    boards: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: '',
    },
    showEmptyState: {
      type: Boolean,
      default: false,
    },
    showCount: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['edit'],
  setup() {
    const { isAdmin } = useAdmin();
    return {
      isAdmin,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
    }),
  },
  methods: {
    handleClick(boardId) {
      this.$router.push({
        name: 'board_item',
        params: {
          boardId,
        },
      });
    },
  },
};
</script>

<template>
  <div
    v-if="boards.length"
    class="border-slate-25 dark:border-slate-700 text-black-900 dark:text-slate-300"
  >
    <div
      v-if="title"
      class="flex-grow-0 flex-shrink-0 flex-[25%] min-w-0 mb-3 flex items-center gap-4"
    >
      <h4 class="text-md font-medium text-black-900 dark:text-slate-300">
        {{ title }}
      </h4>
      <template v-if="showCount">
        <div class="w-px h-3.5 rounded my-auto bg-slate-75 dark:bg-slate-800" />
        <span
          class="min-w-0 text-sm font-medium truncate text-slate-800 dark:text-slate-100"
        >
          {{
            $t('WGPT_BOARDS.OVERVIEW.HEADER.BOARDS_COUNT', {
              count: boards.length,
            })
          }}
        </span>
      </template>
    </div>
    <div class="grid grid-cols-2 gap-4">
      <BoardCard
        v-for="board in boards"
        :key="board.id"
        :board="board"
        :current-user="currentUser"
        :is-admin="isAdmin"
        @click="handleClick(board.id)"
        @edit="e => $emit('edit', e, board)"
      />
    </div>
  </div>

  <OverviewEmptyState v-else-if="showEmptyState" class="pt-14" />
</template>
