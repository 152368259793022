<script setup>
import {
  ref,
  watch,
  computed,
  defineProps,
  nextTick,
  onBeforeUnmount,
} from 'vue';
import { OnClickOutside } from '@vueuse/components';
import { useEmitter } from 'dashboard/composables/emitter';
import { emitter } from 'shared/helpers/mitt';
import { useToggle } from '@vueuse/core';
import getUuid from 'widget/helpers/uuid';

const props = defineProps({
  align: {
    type: String,
    default: 'start',
  },
  bodyClass: {
    type: String,
    default: '',
  },
});

const emit = defineEmits(['close']);

const [isOpen, onToggle] = useToggle(false);

const dropdownReference = ref(null);
const dropdownDialogRef = ref(null);
const stack = ref([]);
const canDismiss = computed(() => stack.value.length === 0);
const positionStyle = ref('');
const id = getUuid();

const toggle = () => {
  if (canDismiss.value) {
    onToggle();
  } else {
    onToggle(true);
  }
};

const closeMenu = () => {
  if (isOpen.value) {
    toggle();
  }
};

useEmitter('WGPT_DROPDOWN_TOGGLE', ({ id: stackId, state }) => {
  if (stackId === id) return;

  if (state) {
    stack.value = [...stack.value, stackId];
  } else {
    stack.value = stack.value.filter(item => item !== stackId);
  }
});

const onShow = () => {
  emitter.emit('WGPT_DROPDOWN_TOGGLE', { id, state: true });
};

const onHide = () => {
  emitter.emit('WGPT_DROPDOWN_TOGGLE', { id, state: false });
};

const dir = computed(() => {
  const el = dropdownReference.value;
  if (!el) return 'auto';
  return getComputedStyle(el).direction;
});

const computePosition = () => {
  const el = dropdownReference.value;
  if (!el) return;
  const { top, left, right } = el.getBoundingClientRect();
  let align = props.align;
  if (dir.value === 'rtl') {
    align = align === 'start' ? 'end' : 'start';
  }
  const gap = 8;
  const start = left;
  const end = window.innerWidth - right;
  positionStyle.value = `--top: calc(${top}px + ${gap}px); --${align}: ${align === 'end' ? end : start}px`;
};

watch(isOpen, () => {
  if (isOpen.value) {
    computePosition();
    onShow();
    nextTick(() => {
      dropdownDialogRef.value?.showModal();
    });
  } else {
    onHide();
    emit('close');
    dropdownDialogRef.value.close();
  }
});

onBeforeUnmount(() => {
  onHide();
});
</script>

<template>
  <div
    class="dropdown-container relative z-20"
    :class="[`dropdown-container-${props.align}`]"
  >
    <slot name="trigger" :toggle="() => toggle()" />
    <div ref="dropdownReference" class="dropdown-reference" />
    <Teleport v-if="isOpen" to="body">
      <dialog
        ref="dropdownDialogRef"
        class="dropdown-body bg-n-alpha-3 backdrop-blur-[100px] border-0 outline outline-1 outline-n-container absolute rounded-xl z-50 gap-2 flex flex-col min-w-[136px] shadow-lg"
        :class="[bodyClass, positionStyle]"
        :style="positionStyle"
        :dir="dir"
        @close="closeMenu"
      >
        <OnClickOutside @trigger="closeMenu">
          <div tabindex="0">
            <slot :toggle="() => toggle()" />
          </div>
        </OnClickOutside>
      </dialog>
    </Teleport>
  </div>
</template>

<style lang="scss" scoped>
dialog::backdrop {
  @apply bg-transparent cursor-pointer;
}

.dropdown-body {
  margin-top: var(--top);
  margin-left: var(--start, auto);
  margin-right: var(--end, auto);
}
</style>
