<script setup>
import { ref } from 'vue';
import { useStore } from 'dashboard/composables/store';
import { useMapGetter } from 'dashboard/composables/store';
import { emitter } from 'shared/helpers/mitt';
import { useI18n } from 'vue-i18n';
import { useAlert } from 'dashboard/composables/wgpt';
import getUuid from 'widget/helpers/uuid';

import Dialog from 'dashboard/components-next/dialog/Dialog.vue';

const props = defineProps({
  board: {
    type: Object,
    default: () => ({}),
  },
  list: {
    type: Object,
    default: () => ({}),
  },
  card: {
    type: Object,
    default: () => ({}),
  },
  activity: {
    type: Object,
    default: () => ({}),
  },
});

const { t } = useI18n();
const store = useStore();
const dialogRef = ref(null);
const uiFlags = useMapGetter('wgptBoardListCardActivities/getUIFlags');
const id = getUuid();

const onShow = () => {
  emitter.emit('WGPT_DROPDOWN_TOGGLE', { id, state: true });
};

const onHide = () => {
  emitter.emit('WGPT_DROPDOWN_TOGGLE', { id, state: false });
};

const open = () => {
  dialogRef.value?.open();
  onShow();
};

const close = () => {
  dialogRef.value?.close();
  onHide();
};

const handleDialogConfirm = async () => {
  try {
    const boardId = props.board.id;
    const listId = props.list.id;
    const cardId = props.card.id;
    const activityId = props.activity.id;
    await store.dispatch('wgptBoardListCardActivities/delete', {
      boardId,
      listId,
      cardId,
      id: activityId,
    });
    useAlert(
      t('WGPT_BOARDS.ITEM.LIST.CARD.EDIT.ACTIVITIES.DELETE.API.SUCCESS_MESSAGE')
    );
  } catch (error) {
    useAlert(
      t('WGPT_BOARDS.ITEM.LIST.CARD.EDIT.ACTIVITIES.DELETE.API.ERROR_MESSAGE')
    );
  } finally {
    close();
  }
};

defineExpose({ open });
</script>

<template>
  <Dialog
    ref="dialogRef"
    type="alert"
    :title="t('WGPT_BOARDS.ITEM.LIST.CARD.EDIT.ACTIVITIES.DELETE.TITLE')"
    :description="t('WGPT_BOARDS.ITEM.LIST.CARD.EDIT.ACTIVITIES.DELETE.DESC')"
    :confirm-button-label="
      t('WGPT_BOARDS.ITEM.LIST.CARD.EDIT.ACTIVITIES.DELETE.BUTTON_TEXT')
    "
    :cancel-button-label="
      t('WGPT_BOARDS.ITEM.LIST.CARD.EDIT.ACTIVITIES.DELETE.CANCEL')
    "
    :is-loading="uiFlags.isDeleting"
    :disable-confirm-button="uiFlags.isDeleting"
    @confirm="handleDialogConfirm"
    @close="onHide"
  />
</template>
