<script>
import { mapGetters } from 'vuex';
import CommentEditor from './CommentEditor.vue';
import Comments from './Comments.vue';

import Icon from 'dashboard/components-next/icon/Icon.vue';

export default {
  components: {
    CommentEditor,
    Comments,
    Icon,
  },
  props: {
    board: {
      type: Object,
      default: () => {},
    },
    card: {
      type: Object,
      default: () => {},
    },
    list: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
    }),
    readonly() {
      return this.board.closed;
    },
  },
  methods: {
    async uploadFileToStorage(file) {
      const data = {
        file,
        boardId: this.board.id,
        listId: this.list.id,
        id: this.card.id,
      };
      const fileInfo = await this.$store.dispatch(
        'wgptBoardListCards/addAttachment',
        data
      );
      return fileInfo.file_url;
    },
  },
};
</script>

<template>
  <div>
    <div class="flex gap-6">
      <Icon icon="i-lucide-list" class="mt-1 w-5 h-5 text-slate-500" />
      <div class="flex-1 w-full">
        <label>
          {{ $t('WGPT_BOARDS.ITEM.LIST.CARD.EDIT.FORM.ACTIVITY.LABEL') }}
        </label>
      </div>
    </div>

    <CommentEditor
      v-if="!readonly"
      class="mt-4"
      :board="board"
      :card="card"
      :list="list"
      :upload-file="uploadFileToStorage"
    />

    <Comments
      class="mt-5"
      :board="board"
      :card="card"
      :list="list"
      :current-user-id="currentUser.id"
      :readonly="readonly"
      :upload-file="uploadFileToStorage"
    />
  </div>
</template>
