<script setup>
import { reactive, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useVuelidate } from '@vuelidate/core';
import { required, minLength } from '@vuelidate/validators';

import Input from 'dashboard/components-next/input/Input.vue';
import TextArea from 'dashboard/components-next/textarea/TextArea.vue';
import Button from 'dashboard/components-next/button/Button.vue';

const emit = defineEmits(['submit']);

const { t } = useI18n();
const state = reactive({
  name: '',
  description: '',
});

const rules = {
  name: { required, minLength: minLength(1) },
};

const v$ = useVuelidate(rules, state);

const nameError = computed(() =>
  v$.value.name.$error ? t('WGPT_BOARDS.ADD.FORM.NAME.ERROR') : ''
);

const onSubmit = e => {
  emit('submit', e);
};

defineExpose({ state, v$ });
</script>

<template>
  <form class="flex flex-col gap-4" @submit.prevent="onSubmit">
    <Button class="hidden" type="button" />

    <Input
      v-model="state.name"
      :label="t('WGPT_BOARDS.ADD.FORM.NAME.LABEL')"
      :message="nameError"
      :message-type="nameError ? 'error' : 'info'"
      custom-input-class="!h-10"
      @input="v$.name.$touch"
    />
    <TextArea
      v-model="state.description"
      :label="t('WGPT_BOARDS.ADD.FORM.DESCRIPTION.LABEL')"
      show-character-count
      custom-text-area-class="!outline !outline-0"
    />
  </form>
</template>
