<script>
import { useAlert } from 'dashboard/composables/wgpt';
import ContactMultiselectDropdown from '../../../../../components/ContactMultiselectDropdown.vue';
import ContactAvatar from './ContactAvatar.vue';
import ContactInfo from './ContactInfo.vue';

import ButtonNext from 'dashboard/components-next/button/Button.vue';
import Dropdown from 'dashboard/components-next/wgpt/Dropdown/Dropdown.vue';

export default {
  components: {
    ContactMultiselectDropdown,
    Dropdown,
    ContactAvatar,
    ContactInfo,
    ButtonNext,
  },
  props: {
    board: {
      type: Object,
      default: () => {},
    },
    list: {
      type: Object,
      default: () => {},
    },
    card: {
      type: Object,
      default: () => {},
    },
    readonly: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      showSearchDropdown: false,
      showContactInfo: false,
      notFoundContactIds: [],
      selectedContact: {},
    };
  },
  computed: {
    contactIds() {
      return this.card.config?.contacts ?? [];
    },
    hasAnyContactVisible() {
      if (!this.contactIds.length) return false;
      return this.contactIds.some(
        contactId => !this.notFoundContactIds.includes(contactId)
      );
    },
    primaryContactId() {
      const availableContacts = this.contactIds.filter(
        id => !this.notFoundContactIds.includes(id)
      );

      return availableContacts.at(0) || -1;
    },
  },
  methods: {
    async onUpdate(selectedItems) {
      if (this.readonly) return;
      try {
        const boardId = this.board.id;
        const listId = this.list.id;
        const id = this.card.id;
        const data = {
          config: {
            ...this.card.config,
            contacts: selectedItems.filter(
              c => !this.notFoundContactIds.includes(c)
            ),
          },
        };
        await this.$store.dispatch('wgptBoardListCards/update', {
          boardId,
          listId,
          id,
          ...data,
        });
      } catch (error) {
        useAlert(
          this.$t('WGPT_BOARDS.ITEM.LIST.CARD.EDIT.CONTACTS.API.ERROR_MESSAGE')
        );
      }
    },
    addItem({ id }) {
      const result = this.contactIds.concat(id);
      this.onUpdate(result);
    },
    removeItem({ id }, toggle) {
      const result = this.contactIds.filter(
        selectedContact => selectedContact !== id
      );
      this.onUpdate(result);

      if (result.length > 0) return;
      toggle();
    },
    onContactNotFound(contactId) {
      if (this.notFoundContactIds.includes(contactId)) return;
      this.notFoundContactIds.push(contactId);
    },
    onContactFound(contactId) {
      if (!this.notFoundContactIds.includes(contactId)) return;
      this.notFoundContactIds = this.notFoundContactIds.filter(
        notFoundContactId => notFoundContactId !== contactId
      );
    },
    openContactInfo(contact, toggle) {
      this.selectedContact = contact;
      this.showContactInfo = true;
      toggle();
    },
    markAsPrimary(contactId) {
      const contactsBefore = this.card.config.contacts ?? [];
      const contacts = [
        contactId,
        ...contactsBefore.filter(c => c !== contactId),
      ];
      this.onUpdate(contacts);
    },
  },
};
</script>

<!-- eslint-disable-next-line vue/no-root-v-if -->
<template>
  <div v-show="hasAnyContactVisible">
    <label>
      {{ $t('WGPT_BOARDS.ITEM.LIST.CARD.EDIT.CONTACTS.LABEL') }}
    </label>

    <div class="flex gap-1 overflow-hidden flex-wrap">
      <div v-for="contactId in contactIds" :key="contactId">
        <Dropdown>
          <template #trigger="{ toggle }">
            <ContactAvatar
              :contact-id="contactId"
              :is-primary="primaryContactId == contactId"
              @found="onContactFound(contactId)"
              @not-found="onContactNotFound(contactId)"
              @open="contact => openContactInfo(contact, toggle)"
            />
          </template>
          <template #default="{ toggle }">
            <ContactInfo
              :contact="selectedContact"
              :is-primary="primaryContactId == contactId"
              :readonly="readonly"
              @close="toggle"
              @mark-as-primary="markAsPrimary(contactId)"
            />
          </template>
        </Dropdown>
      </div>

      <Dropdown>
        <template #trigger="{ toggle }">
          <ButtonNext
            v-if="!readonly"
            size="sm"
            color="slate"
            variant="faded"
            icon="i-lucide-plus"
            class="!rounded-xl"
            @click="toggle"
          />
        </template>
        <template #default="{ toggle }">
          <ContactMultiselectDropdown
            :selected-ids="contactIds"
            @add="addItem"
            @remove="e => removeItem(e, toggle)"
            @close="toggle"
          />
        </template>
      </Dropdown>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.contact-dropdown ::v-deep {
  .dropdown-pane {
    @apply w-44;
  }
}

.contact-info-popup ::v-deep {
  .dropdown-pane {
    @apply w-80 p-4;
  }
}
</style>
