<script setup>
import { nextTick, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'dashboard/composables/store';
import { useMapGetter } from 'dashboard/composables/store';
import { useAlert } from 'dashboard/composables';

import Dialog from 'dashboard/components-next/dialog/Dialog.vue';
import ListForm from './EditList.vue';

const props = defineProps({
  board: {
    type: Object,
    default: () => ({}),
  },
  list: {
    type: Object,
    default: () => ({}),
  },
  canManageLists: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['delete']);
const { t } = useI18n();

const store = useStore();

const isOpen = ref(false);
const dialogRef = ref(null);
const listFormRef = ref(null);

const uiFlags = useMapGetter('wgptBoardLists/getUIFlags');

const handleSubmit = async () => {
  if (!listFormRef.value) return;
  const { state, v$ } = listFormRef.value;

  try {
    if (v$.$invalid) {
      v$.$touch();
      return;
    }

    const boardId = props.board.id;
    const id = props.list.id;
    const payload = state;

    await store.dispatch('wgptBoardLists/update', { boardId, id, ...payload });

    const successMessage = t('WGPT_BOARDS.ITEM.LIST.EDIT.API.SUCCESS_MESSAGE');
    useAlert(successMessage);
    dialogRef.value.close();
  } catch (error) {
    const errorMessage =
      error.message || t('WGPT_BOARDS.ITEM.LIST.EDIT.API.ERROR_MESSAGE');
    useAlert(errorMessage);
  }
};

const handleOpen = () => {
  isOpen.value = true;
};

const handleClose = () => {
  isOpen.value = false;
};

const close = () => {
  dialogRef.value.close();
  handleClose();
};

const open = () => {
  handleOpen();
  nextTick(() => {
    dialogRef.value.open();
  });
};

const handleDelete = () => {
  close();
  emit('delete');
};

defineExpose({ open, close });
</script>

<template>
  <Dialog
    ref="dialogRef"
    type="edit"
    :title="t('WGPT_BOARDS.ITEM.LIST.EDIT.TITLE')"
    :description="t('WGPT_BOARDS.ITEM.LIST.EDIT.DESC')"
    :is-loading="uiFlags.isUpdating"
    :disable-confirm-button="uiFlags.isUpdating"
    :show-confirm-button="!listFormRef?.readonly"
    :confirm-button-label="t('WGPT_BOARDS.EDIT.FORM.SAVE')"
    overflow-y-auto
    @confirm="handleSubmit"
    @close="handleClose"
  >
    <ListForm
      v-if="isOpen"
      ref="listFormRef"
      mode="edit"
      :list="list"
      :board="board"
      :can-manage-lists="canManageLists"
      @delete="handleDelete"
      @submit="handleSubmit"
    />
  </Dialog>
</template>
