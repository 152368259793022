<script setup>
import { ref } from 'vue';
import { useStore } from 'dashboard/composables/store';
import { emitter } from 'shared/helpers/mitt';
import { useI18n } from 'vue-i18n';
import { useAlert } from 'dashboard/composables/wgpt';
import getUuid from 'widget/helpers/uuid';

import Dialog from 'dashboard/components-next/dialog/Dialog.vue';

const props = defineProps({
  board: {
    type: Object,
    default: () => ({}),
  },
  list: {
    type: Object,
    default: () => ({}),
  },
  card: {
    type: Object,
    default: () => ({}),
  },
  attachment: {
    type: Object,
    default: () => ({}),
  },
});

const { t } = useI18n();
const store = useStore();
const dialogRef = ref(null);
const isLoading = ref(false);
const id = getUuid();

const onShow = () => {
  emitter.emit('WGPT_DROPDOWN_TOGGLE', { id, state: true });
};

const onHide = () => {
  emitter.emit('WGPT_DROPDOWN_TOGGLE', { id, state: false });
};

const open = () => {
  dialogRef.value?.open();
  onShow();
};

const close = () => {
  dialogRef.value?.close();
  onHide();
};

const handleDialogConfirm = async () => {
  isLoading.value = true;
  try {
    const boardId = props.board.id;
    const listId = props.list.id;
    const cardId = props.card.id;
    const attachmentId = props.attachment.id;
    await store.dispatch('wgptBoardListCards/removeAttachment', {
      boardId,
      listId,
      cardId,
      id: attachmentId,
    });
    useAlert(
      t(
        'WGPT_BOARDS.ITEM.LIST.CARD.EDIT.ATTACHMENTS.DELETE.API.SUCCESS_MESSAGE'
      )
    );
  } catch (error) {
    useAlert(
      t('WGPT_BOARDS.ITEM.LIST.CARD.EDIT.ATTACHMENTS.DELETE.API.ERROR_MESSAGE')
    );
  } finally {
    isLoading.value = false;
    close();
  }
};

defineExpose({ open });
</script>

<template>
  <Dialog
    ref="dialogRef"
    type="alert"
    :title="t('WGPT_BOARDS.ITEM.LIST.CARD.EDIT.ATTACHMENTS.DELETE.TITLE')"
    :description="t('WGPT_BOARDS.ITEM.LIST.CARD.EDIT.ATTACHMENTS.DELETE.DESC')"
    :confirm-button-label="
      t('WGPT_BOARDS.ITEM.LIST.CARD.EDIT.ATTACHMENTS.DELETE.BUTTON_TEXT')
    "
    :cancel-button-label="
      t('WGPT_BOARDS.ITEM.LIST.CARD.EDIT.ATTACHMENTS.DELETE.CANCEL')
    "
    :is-loading="isLoading"
    :disable-confirm-button="isLoading"
    @confirm="handleDialogConfirm"
    @close="onHide"
  />
</template>
