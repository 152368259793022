<script>
import { mapGetters } from 'vuex';
import FilterItem from './FilterItem.vue';
import Thumbnail from 'dashboard/components/widgets/Thumbnail.vue';
import { FILTER_ITEM_MAX_LENGTH } from './helpers/constant';
import { mapToOptions } from '../../helpers/dropdownHelper';

import ButtonNext from 'dashboard/components-next/button/Button.vue';
import Icon from 'dashboard/components-next/icon/Icon.vue';
import CheckBox from 'dashboard/components-next/wgpt/Form/CheckBox.vue';
import Dropdown from 'dashboard/components-next/wgpt/Dropdown/Dropdown.vue';
import DropdownMenuContent from '../DropdownMenuContent.vue';
import Avatar from 'dashboard/components-next/avatar/Avatar.vue';

export default {
  components: {
    FilterItem,
    Thumbnail,
    ButtonNext,
    Icon,
    Dropdown,
    DropdownMenuContent,
    CheckBox,
    Avatar,
  },
  props: {
    modelValue: {
      type: Array,
      default: () => [],
    },
    memberIds: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      updatedValue: this.modelValue,
      showSearchDropdown: false,
      event: {},
    };
  },
  computed: {
    ...mapGetters({
      agents: 'agents/getAgents',
    }),
    allMembers() {
      return this.agents.filter(({ id }) => {
        return this.memberIds.includes(id);
      });
    },
    allMembersList() {
      return mapToOptions(this.allMembers, 'id', 'name').map(item => {
        return {
          ...item,
          isSelected: this.updatedValue.includes(item.value),
        };
      });
    },
    members() {
      return this.allMembers.slice(0, FILTER_ITEM_MAX_LENGTH);
    },
    unloadedMembers() {
      return this.updatedValue.filter(
        id => !this.members.find(member => member.id === id)
      );
    },
    hasAnyItem() {
      return this.members.length > 0;
    },
    showBadge() {
      return this.unloadedMembers.length > 0;
    },
    showMoreOptions() {
      return this.allMembers.length > FILTER_ITEM_MAX_LENGTH;
    },
  },
  watch: {
    updatedValue(value) {
      this.$emit('update:modelValue', value);
    },
    modelValue(value, oldValue) {
      if (value.join() === oldValue.join()) return;
      this.updatedValue = value;
    },
  },
  methods: {
    toggleDropdown(e) {
      this.event = e;
      this.showSearchDropdown = !this.showSearchDropdown;
    },
    closeDropdown() {
      this.showSearchDropdown = false;
    },
    handleAction({ value }) {
      if (this.updatedValue.includes(value)) {
        this.updatedValue = this.updatedValue.filter(v => v !== value);
      } else {
        this.updatedValue.push(value);
      }
    },
  },
};
</script>

<template>
  <div v-show="hasAnyItem">
    <label class="text-sm mb-2 text-slate-600 dark:text-slate-400">{{
      $t('WGPT_BOARDS.FILTER.MEMBERS.TITLE')
    }}</label>
    <FilterItem v-for="member in members" :key="member.id" class="group py-1.5">
      <template #start>
        <CheckBox v-model="updatedValue" :value="member.id" />
      </template>
      <div class="flex flex-row justify-between">
        <Thumbnail
          :src="member.thumbnail"
          :username="member.name"
          size="24px"
        />
      </div>
      <span class="truncate">{{ member.name }}</span>
    </FilterItem>

    <Dropdown>
      <template #trigger="{ toggle }">
        <ButtonNext
          v-if="showMoreOptions"
          variant="link"
          class="!ms-6 !mt-2 [&>*]:pointer-events-none"
          @click="toggle"
        >
          <div v-if="showBadge" class="w-[24px] flex justify-center">
            <div class="badge">
              <span>
                {{ unloadedMembers.length }}
              </span>
            </div>
          </div>
          {{ $t('WGPT_BOARDS.FILTER.BUTTON_TXT.SHOW_MORE_OPTIONS') }}
          <Icon icon="i-lucide-chevron-down" class="w-4 h-4" />
        </ButtonNext>
      </template>
      <template #default="{ toggle }">
        <div>
          <div class="pt-2 ps-3 pe-1 mb-0.5 flex justify-between items-center">
            <label class="text-sm font-medium text-n-slate-12">
              {{
                $t(
                  'WGPT_BOARDS.ITEM.LIST.CARD.EDIT.SIDEBAR.MEMBERS.DROPDOWN.TITLE'
                )
              }}
            </label>
            <ButtonNext
              icon="i-lucide-x"
              size="xs"
              color="slate"
              variant="ghost"
              class="opacity-50 hover:opacity-100"
              @click="toggle"
            />
          </div>
          <DropdownMenuContent
            :menu-items="allMembersList"
            show-search
            :search-placeholder="
              $t(
                'WGPT_BOARDS.ITEM.LIST.CARD.EDIT.SIDEBAR.MEMBERS.DROPDOWN.PLACEHOLDER'
              )
            "
            :search-empty-state="
              $t(
                'WGPT_BOARDS.ITEM.LIST.CARD.EDIT.SIDEBAR.MEMBERS.DROPDOWN.NO_RESULT'
              )
            "
            class="ltr:right-0 rtl:left-0 xl:ltr:left-0 xl:rtl:right-0 top-full"
            @action="handleAction"
          >
            <template #thumbnail="{ item }">
              <Avatar :src="item.thumbnail" :name="item.label" :size="20" />
            </template>
          </DropdownMenuContent>
        </div>
      </template>
    </Dropdown>
  </div>
</template>

<style lang="scss" scoped>
.badge {
  @apply inline-block bg-slate-50 dark:bg-woot-800 rounded-md text-slate-600 dark:text-slate-100 h-5 text-xxs font-semibold px-1 min-w-[16px] text-center;
}
</style>
