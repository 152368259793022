<script>
import { isSameYear } from 'date-fns';
import { messageStamp } from 'shared/helpers/timeHelper';

export default {
  props: {
    now: {
      type: Date,
      default: new Date(),
    },
    startDate: {
      type: [String, Number],
      default: '',
    },
    endDate: {
      type: [String, Number],
      default: '',
    },
    complete: {
      type: Boolean,
      default: false,
    },
    isNeutral: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    showDates() {
      return this.startDate || this.endDate;
    },
    readableStartDate() {
      const now = new Date();
      const date = new Date(this.startDate);
      return messageStamp(
        date.getTime() / 1_000,
        isSameYear(date, now) ? 'LLL d' : 'LLL d, y'
      );
    },
    readableEndDate() {
      const now = new Date();
      const date = new Date(this.endDate);
      return messageStamp(
        date.getTime() / 1_000,
        isSameYear(date, now) ? 'LLL d, h:mm aa' : 'LLL d, y, h:mm aa'
      );
    },
    title() {
      if (!this.showDates) return undefined;

      const now = new Date().getTime();

      if (!this.endDate) {
        return `${this.startDate < now ? 'Started' : 'Starts'}: ${
          this.readableStartDate
        }`;
      }
      if (!this.startDate) {
        return `${this.readableEndDate}`;
      }

      return `${this.readableStartDate} - ${this.readableEndDate}`;
    },
    elapsed() {
      if (!this.endDate) return undefined;
      const date = new Date(this.endDate);
      const elapsedMs = this.now - date;
      const elapsedHr = elapsedMs / 1_000 / 60 / 60;
      return elapsedHr;
    },
    status() {
      if (this.isNeutral) return 'neutral';
      if (this.complete) return 'complete';
      if (this.elapsed === undefined) return '';
      if (this.elapsed < -24) {
        return 'more-than-24-hours-to-go';
      }
      if (this.elapsed < 0) {
        return '24-hours-to-go';
      }
      if (this.elapsed < 24) {
        return '24-hours-ago';
      }
      return 'more-than-24-hours-ago';
      // if (this.elapsed > 24) {
      //   return "more-than-24-hours-ago"
      // }
      // return "complete";
    },
  },
};
</script>

<template>
  <div class="flex">
    <woot-label
      v-if="status === 'complete'"
      :title="title"
      :show-close="false"
      icon="calendar-clock"
      color-scheme="success"
      class="pointer-events-none !m-0 !rounded-[5px] font-medium due-date-badge"
    />
    <woot-label
      v-else-if="status === '24-hours-to-go'"
      :title="title"
      :show-close="false"
      icon="calendar-clock"
      bg-color="rgb(234 179 8)"
      color="rgb(66 32 6)"
      color-scheme="alert"
      class="pointer-events-none !m-0 !rounded-[5px] font-medium due-date-badge"
    />
    <woot-label
      v-else-if="status === '24-hours-ago'"
      :title="title"
      :show-close="false"
      icon="calendar-clock"
      bg-color="rgb(239 68 68)"
      color-scheme="alert"
      class="pointer-events-none !m-0 !rounded-[5px] font-medium due-date-badge"
    />
    <template v-else-if="status === 'more-than-24-hours-ago'">
      <woot-label
        :title="title"
        :show-close="false"
        icon="calendar-clock"
        bg-color="rgb(254 242 242)"
        color="rgb(185 28 28)"
        color-scheme="alert"
        class="dark:hidden border !border-red-100 pointer-events-none !m-0 !rounded-[5px] font-medium due-date-badge"
      />
      <woot-label
        :title="title"
        :show-close="false"
        icon="calendar-clock"
        bg-color="rgb(127 29 29)"
        color="rgb(252 165 165)"
        color-scheme="alert"
        class="!hidden dark:!flex border !border-red-100 dark:!border-transparent pointer-events-none !m-0 !rounded-[5px] font-medium due-date-badge"
      />
    </template>
    <woot-label
      v-else
      :title="title"
      :show-close="false"
      icon="calendar-clock"
      class="pointer-events-none !m-0 !rounded-[5px] font-medium due-date-badge border-none"
    />
  </div>
</template>

<style lang="scss" scoped>
.due-date-badge ::v-deep {
  @apply px-1.5 gap-0;
}
</style>
