<script>
import { mapGetters } from 'vuex';
import { useAlert } from 'dashboard/composables';
import { calculateScrollTop } from 'dashboard/components/widgets/conversation/helpers/scrollTopCalculationHelper';
import { WGPT_BOARD_LIST_CARDS_QUERY_LIMIT } from './helpers/constants';
import Draggable from 'vuedraggable';
import BoardListItemContextMenu from './ItemContextMenu.vue';
import AddCardForm from './card/AddCard.vue';
import CardItem from './card/Index.vue';
import FluentIcon from 'shared/components/FluentIcon/DashboardIcon.vue';
import IntersectionObserver from 'dashboard/components/IntersectionObserver.vue';
import { emitter } from 'shared/helpers/mitt';

import ButtonNext from 'dashboard/components-next/button/Button.vue';
import CardLayout from 'dashboard/components-next/CardLayout.vue';
import Spinner from 'dashboard/components-next/spinner/Spinner.vue';

export default {
  components: {
    Draggable,
    BoardListItemContextMenu,
    AddCardForm,
    CardItem,
    FluentIcon,
    IntersectionObserver,
    ButtonNext,
    CardLayout,
    Spinner,
  },
  props: {
    now: {
      type: Date,
      default: new Date(),
    },
    canManageLists: {
      type: Boolean,
      default: false,
    },
    canManageCards: {
      type: Boolean,
      default: false,
    },
    list: {
      type: Object,
      default: () => {},
    },
    labels: {
      type: Array,
      default: () => [],
    },
    agents: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['toggleLabelLayout', 'delete', 'edit'],
  data() {
    return {
      boardId: this.$route.params.boardId,
      drag: false,
      showCardsDropdown: false,
      hasThumbnail: true,
      inputPlaceholder: '',
      cardListLoading: false,
      infiniteLoaderOptions: {
        root: this.$refs.cardListRef,
        rootMargin: '100px 0px 100px 0px',
      },
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'wgptBoardListCards/getUIFlags',
    }),
    filters() {
      return this.$store.getters['wgptBoardListCards/getFilters'](this.boardId);
    },
    hasAppliedFilters() {
      return this.$store.getters['wgptBoardListCards/hasFilters'](this.boardId);
    },
    cards() {
      return this.$store.getters['wgptBoardListCards/getCards'](
        this.boardId,
        this.list.id
      );
    },
    hasMore() {
      return this.$store.getters['wgptBoardListCards/hasMoreCards'](
        this.list.id
      );
    },
    listCards: {
      get() {
        return this.cards;
      },
      set(data) {
        this.$store.dispatch('wgptBoardListCards/setBoardCards', {
          listId: this.list.id,
          data,
        });
      },
    },
    dragOptions() {
      return {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost',
      };
    },
    isArchive() {
      return this.list.archive;
    },
  },
  watch: {
    filters: {
      handler() {
        this.fetchListCards({ limit: WGPT_BOARD_LIST_CARDS_QUERY_LIMIT });
      },
      deep: true,
    },
  },
  mounted() {
    this.fetchListCards({ limit: WGPT_BOARD_LIST_CARDS_QUERY_LIMIT });
    emitter.on('WGPT_BOARD_LIST_CARD_UPDATED', card => {
      if (this.hasAppliedFilters && this.list.id === card.listId) {
        const options = {
          limit: this.listCards.length,
        };
        this.fetchListCards(options);
      }
    });
  },
  beforeUnmount() {
    emitter.off('WGPT_BOARD_LIST_CARD_UPDATED');
  },
  methods: {
    async fetchListCards(options = {}) {
      const { limit, offset = 0 } = options;
      this.cardListLoading = true;
      try {
        const getOptions = {
          boardId: this.$route.params.boardId,
          listId: this.list.id,
          offset,
          limit: this.uiFlags.isSearching ? null : limit,
          filters: this.filters,
        };
        await this.$store.dispatch('wgptBoardListCards/get', getOptions);
      } catch (error) {
        // ignore error
      } finally {
        this.cardListLoading = false;
      }
    },
    fetchMoreListCards() {
      this.fetchListCards({
        limit: WGPT_BOARD_LIST_CARDS_QUERY_LIMIT,
        offset: this.cards.length,
      });
    },
    async onCardOrderChange(event) {
      if (event.moved) {
        this.onCardOrderMoved(event);
      } else if (event.added) {
        this.onCardOrderAdded(event);
      }
    },
    async onCardOrderMoved({ moved: event }) {
      try {
        const { element: card, oldIndex, newIndex } = event;

        const cards = this.listCards;
        const previousCard = cards[newIndex - 1];
        const nextCard = cards[newIndex + 1];
        let order = newIndex + 1;
        if (newIndex > oldIndex) {
          order = previousCard.order;
        } else if (newIndex < oldIndex) {
          order = nextCard.order;
        }

        const boardListCard = {
          order,
        };
        const boardId = this.$route.params.boardId;
        const listId = this.list.id;
        const id = card.id;
        await this.$store.dispatch('wgptBoardListCards/update', {
          boardId,
          listId,
          id,
          ...boardListCard,
        });
      } catch (error) {
        const errorMessage =
          error.message ||
          this.$t('WGPT_BOARDS.ITEM.LIST.CARD.REORDER.API.ERROR_MESSAGE');
        useAlert(errorMessage);
      }
    },
    async onCardOrderAdded({ added: event }) {
      try {
        const { element: card, newIndex } = event;

        const cards = this.listCards;
        const previousCard = cards[newIndex - 1];
        const nextCard = cards[newIndex + 1];
        let order = newIndex + 1;
        if (nextCard) {
          order = nextCard.order;
        } else if (previousCard) {
          order = previousCard.order + 1;
        }

        const boardListCard = {
          order,
          wgpt_board_list_id: this.list.id,
        };
        const boardId = this.$route.params.boardId;
        const listId = card.listIdBefore ?? card.listId;
        const id = card.id;
        await this.$store.dispatch('wgptBoardListCards/update', {
          boardId,
          listId,
          id,
          ...boardListCard,
        });
      } catch (error) {
        const errorMessage =
          error.message ||
          this.$t('WGPT_BOARDS.ITEM.LIST.CARD.REORDER.API.ERROR_MESSAGE');
        useAlert(errorMessage);
      }
    },
    async addBoardListCard(data) {
      this.hideCardsDropdown();
      const createCardOptions = {
        boardId: this.$route.params.boardId,
        listId: this.list.id,
        name: data.name,
      };
      await this.$store.dispatch(
        'wgptBoardListCards/create',
        createCardOptions
      );
      if (this.hasMore) {
        await this.fetchListCards({ offset: this.cards.length });
      }
      this.$nextTick(() => {
        this.cardsPanel = this.$refs.cardListRef;
        this.cardsPanel.scrollTop = calculateScrollTop(
          this.cardsPanel.scrollHeight,
          this.$el.scrollHeight,
          []
        );
      });
    },
    openCardsDropdown() {
      this.showCardsDropdown = true;
    },
    hideCardsDropdown() {
      this.showCardsDropdown = false;
    },
    editListItem() {
      this.$emit('edit');
    },
    deleteListItem() {
      this.$emit('delete');
    },
    toggleLabelLayout() {
      this.$emit('toggleLabelLayout');
    },
    async toggleComplete(card) {
      try {
        const boardId = this.$route.params.boardId;
        const listId = this.list.id;
        const id = card.id;
        const previousComplete = card.complete ?? false;
        const data = {
          complete: !previousComplete,
        };
        await this.$store.dispatch('wgptBoardListCards/update', {
          boardId,
          listId,
          id,
          ...data,
        });
      } catch (error) {
        useAlert(
          this.$t('WGPT_BOARDS.ITEM.LIST.CARD.EDIT.DATES.API.ERROR_MESSAGE')
        );
      }
    },
  },
};
</script>

<template>
  <CardLayout
    class="board-list select-none max-h-full overflow-hidden [&>div]:p-0 [&>div]:gap-0"
    :class="{ 'archive-list': isArchive }"
  >
    <div
      class="shrink-0 pt-2"
      :class="{ 'cursor-pointer': canManageLists }"
      draggable="false"
    >
      <div class="flex items-start gap-2">
        <p
          class="my-1 ltr:pl-5 rtl:pr-5 text-slate-700 dark:text-slate-100 m-0"
        >
          <b>{{ list.name }}</b>
        </p>

        <FluentIcon
          v-if="isArchive"
          icon="checkmark"
          class="shrink-0 my-2 text-green-400"
          size="16"
        />
        <BoardListItemContextMenu
          class="mx-2 ltr:ml-auto rtl:mr-auto"
          @edit="editListItem"
          @delete="deleteListItem"
        />
      </div>
      <p v-if="list.description" class="ps-5 pe-4 pt-2 pb-3 truncate m-0">
        {{ list.description }}
      </p>
    </div>

    <div ref="cardListRef" class="overflow-auto">
      <Draggable
        v-model="listCards"
        item-key="id"
        v-bind="dragOptions"
        group="cards"
        draggable=".item"
        class="draggable-item justify-end min-h-4"
        :disabled="!canManageCards"
        force-fallback
        :fallback-tolerance="20"
        @start="drag = true"
        @end="drag = false"
        @change="onCardOrderChange"
      >
        <template #item="{ element: card }">
          <CardItem
            :card="card"
            :can-manage-cards="canManageCards"
            :labels="labels"
            :agents="agents"
            :now="now"
            :is-neutral="isArchive"
            class="card item first:mb-[2px] last:mb-[2px]"
            :class="{ hidden: card.hidden }"
            @toggle-label-layout="toggleLabelLayout"
            @toggle-resolve="toggleComplete"
          />
        </template>
      </Draggable>

      <div
        v-if="listCards.length === 0 && !canManageLists && !cardListLoading"
        class="text-center text-n-slate-8 text-xs py-4"
      >
        {{ $t('WGPT_BOARDS.ITEM.LIST.CARD.EMPTY_STATE') }}
      </div>

      <div v-if="cardListLoading" class="flex justify-center py-4">
        <Spinner />
      </div>

      <IntersectionObserver
        v-else-if="hasMore"
        :options="infiniteLoaderOptions"
        @observed="fetchMoreListCards"
      />
    </div>

    <div
      class="shrink-0 p-2 text-slate-700 dark:text-slate-100"
      draggable="false"
    >
      <AddCardForm
        v-if="showCardsDropdown"
        v-on-clickaway="hideCardsDropdown"
        :on-submit="addBoardListCard"
        @close="hideCardsDropdown"
      />

      <ButtonNext
        v-else-if="canManageCards"
        :label="$t('WGPT_BOARDS.ITEM.LIST.BUTTON_TEXT.ADD_CARD')"
        variant="ghost"
        icon="i-lucide-plus"
        color="slate"
        class="w-full !justify-start !px-2"
        @click="openCardsDropdown"
      />
    </div>
  </CardLayout>
</template>

<style lang="scss" scoped>
.flip-list-move {
  transition: transform 0.5s;
}

.no-move {
  transition: transform 0s;
}

.ghost {
  opacity: 0.25;
}

.list-group {
  min-height: 20px;
}

.list-group-item {
  cursor: move;
  i {
    cursor: pointer;
  }
}

.cover {
  background: var(--background, black);
  color: white;
}

.board-list {
  width: 300px;
  flex-direction: unset;
}

.archive-list ::v-deep {
  .board-card-due-date-badge .due-date-badge {
    @apply bg-transparent dark:text-white dark:border-slate-800;
  }
}
</style>
