<script setup>
import { useI18n } from 'vue-i18n';

import EmptyStateLayout from 'dashboard/components-next/EmptyStateLayout.vue';
import BoardCard from './components/BoardCard.vue';
import boardEmptyStateContent from './helpers/boardEmptyStateContent.js';

const { t } = useI18n();
</script>

<template>
  <EmptyStateLayout
    :title="t('WGPT_BOARDS.OVERVIEW.EMPTY_STATE.TITLE')"
    :subtitle="t('WGPT_BOARDS.OVERVIEW.EMPTY_STATE.SUBTITLE')"
  >
    <template #empty-state-item>
      <div class="grid grid-cols-2 gap-4 p-px">
        <div class="space-y-4">
          <BoardCard
            v-for="board in boardEmptyStateContent"
            :key="board.id"
            :board="board"
          />
        </div>
        <div class="space-y-4">
          <BoardCard
            v-for="board in boardEmptyStateContent.reverse()"
            :key="board.id"
            :board="board"
          />
        </div>
      </div>
    </template>
  </EmptyStateLayout>
</template>
