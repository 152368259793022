<script>
import { useAlert } from 'dashboard/composables';
import MultiselectDropdownItems from './ContactMultiselectDropdownItems.vue';
import filterQueryGenerator from 'dashboard/helper/filterQueryGenerator';

import ButtonNext from 'dashboard/components-next/button/Button.vue';

export default {
  components: {
    MultiselectDropdownItems,
    ButtonNext,
  },
  props: {
    selectedIds: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['add', 'remove', 'close'],
  data() {
    return {};
  },
  methods: {
    onCloseDropdown() {
      this.$emit('close');
    },
    onClickSelectItem(value) {
      if (this.selectedIds.some(selectedId => selectedId === value.id)) {
        this.$emit('remove', value);
        return;
      }

      this.$emit('add', value);
    },
    async onSearch(query) {
      try {
        if (!query) return;

        const attributeKeys = ['name', 'email', 'phone_number'];
        const payload = attributeKeys.map(attributeKey => {
          return {
            attribute_key: attributeKey,
            attribute_model: 'standard',
            custom_attribute_type: '',
            filter_operator: 'contains',
            query_operator: 'or',
            values: query,
          };
        });
        const queryPayload = filterQueryGenerator(payload);
        this.$store.dispatch('contacts/filter', { queryPayload });
      } catch (error) {
        useAlert(
          this.$t('WGPT_BOARDS.ITEM.LIST.CARD.EDIT.SIDEBAR.API.ERROR_MESSAGE')
        );
      }
    },
  },
};
</script>

<template>
  <div>
    <div class="pt-2 ps-3 pe-1 mb-0.5 flex justify-between items-center">
      <label class="text-sm font-medium text-n-slate-12">
        {{
          $t('WGPT_BOARDS.ITEM.LIST.CARD.EDIT.CONTACTS.MULTI_SELECTOR.TITLE')
        }}
      </label>
      <ButtonNext
        icon="i-lucide-x"
        size="xs"
        color="slate"
        variant="ghost"
        class="opacity-50 hover:opacity-100"
        @click="onCloseDropdown"
      />
    </div>
    <MultiselectDropdownItems
      :selected-ids="selectedIds"
      @click="onClickSelectItem"
      @search="onSearch"
    />
  </div>
</template>
