<script>
import Avatar from 'dashboard/components-next/avatar/Avatar.vue';
import DueDateBadge from './components/DueDateBadge.vue';
import CardLayout from 'dashboard/components-next/CardLayout.vue';
import Icon from 'dashboard/components-next/icon/Icon.vue';

export default {
  components: {
    Avatar,
    DueDateBadge,
    CardLayout,
    Icon,
  },
  props: {
    now: {
      type: Date,
      default: new Date(),
    },
    card: {
      type: Object,
      default: () => {},
    },
    canManageCards: {
      type: Boolean,
      default: false,
    },
    labels: {
      type: Array,
      default: () => [],
    },
    agents: {
      type: Array,
      default: () => [],
    },
    isNeutral: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['toggleResolve', 'toggleLabelLayout'],
  computed: {
    cardLabels() {
      const availableCardLabels = this.card.labels ?? [];
      return this.labels.filter(label =>
        availableCardLabels.includes(label.title)
      );
    },
    cardMembers() {
      const memberIds = this.card.config?.members ?? [];
      return this.agents.filter(agent => memberIds.includes(agent.id));
    },
    config() {
      return this.card.config ?? {};
    },
    showDates() {
      return this.card.start_date || this.card.end_date;
    },
    showBottomSection() {
      return this.cardMembers.length > 0;
    },
  },
  methods: {
    open(ev) {
      switch (ev.target.role) {
        case 'wgpt-board-card-label':
          this.$emit('toggleLabelLayout');
          return;

        case 'wgpt-board-card-due-date-badge':
          if (!this.canManageCards) break;
          if (this.isNeutral) break;
          this.$emit('toggleResolve', this.card);
          return;

        default:
          break;
      }

      this.$router.push({
        query: { selectedCard: this.card.id },
      });
    },
  },
};
</script>

<template>
  <div class="m-2" @click="e => open(e, card)">
    <CardLayout
      class="!bg-n-alpha-black2 dark:!bg-n-solid-3 border dark:border-transparent border-n-weak [&>div]:py-2 [&>div]:px-0 [&>div]:gap-0 outline outline-2 outline-transparent cursor-pointer group"
    >
      <div v-if="cardLabels.length > 0" class="px-3 flex flex-wrap">
        <woot-label
          v-for="label in cardLabels"
          :key="label.id"
          :title="label.title"
          :description="label.description"
          :show-close="false"
          :bg-color="label.color + '9F'"
          role="wgpt-board-card-label"
          class="board-card-label hover:opacity-75 cursor-pointer"
        />
      </div>
      <div class="flex gap-3 items-center ps-3 pe-1">
        <div class="user-block flex-1 overflow-hidden">
          <h6 class="m-0 sub-block-title overflow-hidden">
            <div
              class="user-name text-slate-700 dark:text-slate-100 text-truncate"
            >
              {{ card.name }}
            </div>
          </h6>
        </div>
        <Icon
          v-if="canManageCards"
          icon="i-lucide-pencil"
          class="relative end-2 size-4 hidden group-hover:block"
        />
        <Icon
          v-else
          icon="i-lucide-eye"
          class="relative end-2 size-4 hidden group-hover:block"
        />
      </div>
      <div
        v-if="showDates"
        class="mt-1 mb-3 pl-3 pr-2 flex justify-end gap-1 overflow-hidden flex-wrap"
      >
        <DueDateBadge
          v-if="showDates"
          :start-date="card.start_date"
          :end-date="card.end_date"
          :complete="card.complete"
          :is-neutral="isNeutral"
          :now="now"
          class="board-card-due-date-badge me-auto float-left"
          role="wgpt-board-card-due-date-badge"
        />
      </div>
      <div
        class="mt-1 ltr:pl-3 ltr:pr-2 rtl:pl-2 rtl:pr-3 flex justify-between items-end gap-3"
      >
        <span class="text-xs py-1 font-bold text-slate-500 dark:text-slate-400">
          {{ card.key }}
        </span>
        <div class="flex justify-end gap-1 overflow-hidden flex-wrap">
          <Avatar
            v-for="cardMember in cardMembers"
            :key="cardMember.id"
            :src="cardMember.thumbnail"
            :name="cardMember.name"
            :size="24"
            class="justify-self-end"
          />
        </div>
      </div>
    </CardLayout>
  </div>
</template>

<style lang="scss" scoped>
.outline:hover:not(:active) {
  outline-color: #2781f6;
}

.outline:not(:hover) .board-card-settings-button {
  @apply opacity-0;
}

.board-card-label {
  transition: height 300ms ease-out;
  height: 16px;
  border-radius: 5px;
  ::v-deep {
    @apply py-0;
    span {
      @apply pointer-events-none;
    }
  }
}

.board-card-due-date-badge {
  ::v-deep {
    * {
      @apply pointer-events-none;
    }
  }
}
</style>
