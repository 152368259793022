<script setup>
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useAlert } from 'dashboard/composables';
import { useStore } from 'dashboard/composables/store';
import { useRoute } from 'vue-router';
import { useMapGetter } from 'dashboard/composables/store';
import Dialog from 'dashboard/components-next/dialog/Dialog.vue';
import Input from 'dashboard/components-next/input/Input.vue';

const { t } = useI18n();
const store = useStore();
const route = useRoute();

const dialogRef = ref(null);
const uiFlags = useMapGetter('wgptBoardLists/getUIFlags');
const list = ref({});
const confirmationInput = ref('');
const errorMessage = ref('');

const open = selectedList => {
  list.value = selectedList;
  confirmationInput.value = '';
  dialogRef.value.open();
};

const handleDialogConfirm = async () => {
  if (confirmationInput.value === list.value.name) {
    try {
      const boardId = route.params.boardId;
      const id = list.value.id;
      await store.dispatch('wgptBoardLists/delete', { boardId, id });
      dialogRef.value.close();
      useAlert(t('WGPT_BOARDS.ITEM.LIST.DELETE.API.SUCCESS_MESSAGE'));
      errorMessage.value = '';
    } catch (error) {
      useAlert(t('WGPT_BOARDS.ITEM.LIST.DELETE.API.ERROR_MESSAGE'));
    }
  } else {
    errorMessage.value = t(
      'WGPT_BOARDS.ITEM.LIST.DELETE.FORM.CONFIRMATION.ERROR'
    );
  }
};

defineExpose({ open });
</script>

<template>
  <Dialog
    ref="dialogRef"
    :title="t('WGPT_BOARDS.ITEM.LIST.DELETE.TITLE', { listName: list.name })"
    :description="t('WGPT_BOARDS.ITEM.LIST.DELETE.DESC')"
    :confirm-button-label="t('WGPT_BOARDS.ITEM.LIST.DELETE.FORM.DELETE')"
    :is-loading="uiFlags.isDeleting"
    :disable-confirm-button="uiFlags.isDeleting"
    type="alert"
    @confirm="handleDialogConfirm"
  >
    <Input
      v-model="confirmationInput"
      :label="
        t('WGPT_BOARDS.ITEM.LIST.DELETE.FORM.CONFIRMATION.LABEL', {
          listName: list.name,
        })
      "
      :message="errorMessage"
      :message-type="errorMessage ? 'error' : 'info'"
    />
  </Dialog>
</template>
