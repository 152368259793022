<script setup>
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { emitter } from 'shared/helpers/mitt';
import getUuid from 'widget/helpers/uuid';

import Dialog from 'dashboard/components-next/dialog/Dialog.vue';

defineProps({
  card: {
    type: Object,
    default: () => ({}),
  },
  onConfirm: {
    type: Function,
    default: () => ({}),
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
});

const { t } = useI18n();
const dialogRef = ref(null);
const id = getUuid();
const onShow = () => {
  emitter.emit('WGPT_DROPDOWN_TOGGLE', { id, state: true });
};

const onHide = () => {
  emitter.emit('WGPT_DROPDOWN_TOGGLE', { id, state: false });
};

const open = () => {
  dialogRef.value?.open();
  onShow();
};

const close = () => {
  dialogRef.value?.close();
  onHide();
};

defineExpose({ open, close });
</script>

<template>
  <Dialog
    ref="dialogRef"
    type="alert"
    :title="t('WGPT_BOARDS.ITEM.LIST.CARD.DELETE.TITLE')"
    :description="
      t('WGPT_BOARDS.ITEM.LIST.CARD.DELETE.DESC', {
        cardName: card.name,
      })
    "
    :is-loading="isLoading"
    :confirm-button-label="t('WGPT_BOARDS.ITEM.LIST.CARD.DELETE.FORM.DELETE')"
    :cancel-button-label="t('WGPT_BOARDS.ITEM.LIST.CARD.DELETE.FORM.CANCEL')"
    @confirm="onConfirm"
    @close="onHide"
  />
</template>
