<script>
import { debounce } from '@chatwoot/utils';
import { SEARCH_MAX_LENGTH } from '../components/FilterBoard/helpers/constant';

import FilterBoard from '../components/FilterBoard/Index.vue';
import Dropdown from 'dashboard/components-next/wgpt/Dropdown/Dropdown.vue';
import Spinner from 'dashboard/components-next/spinner/Spinner.vue';
import ButtonNext from 'dashboard/components-next/button/Button.vue';

export default {
  components: {
    Spinner,
    ButtonNext,
    Dropdown,
    FilterBoard,
  },
  props: {
    board: {
      type: Object,
      default: () => {},
    },
  },
  emits: ['edit'],
  data() {
    return {
      search: '',
      searching: false,
      onSearch: debounce(() => {
        this.submitSearch();
      }, 750),
    };
  },
  computed: {
    searchFilter() {
      return this.filters.search ?? '';
    },
    boardId() {
      return this.$route.params.boardId;
    },
    filters() {
      return this.$store.getters['wgptBoardListCards/getFilters'](this.boardId);
    },
    hasAppliedFilters() {
      return this.$store.getters['wgptBoardListCards/hasFilters'](this.boardId);
    },
  },
  watch: {
    searchFilter(value) {
      if (value === this.search) return;
      this.search = value;
    },
  },
  methods: {
    onInput() {
      if (this.search.length > SEARCH_MAX_LENGTH) {
        this.search = this.search.slice(0, SEARCH_MAX_LENGTH);
      }
      if (this.search) {
        this.searching = true;
      }
      this.onSearch();
    },
    clearSearch() {
      this.search = '';
      this.onSearch();
    },
    async submitSearch() {
      try {
        const search = this.search || undefined;
        const filterOptions = {
          boardId: this.boardId,
          filters: {
            ...this.filters,
            search,
          },
        };
        await this.$store.dispatch('wgptBoardListCards/filter', filterOptions);
      } catch (error) {
        // ignore error
      } finally {
        this.searching = false;
      }
    },
    openEditPopup() {
      this.$emit('edit');
    },
    clearFilters() {
      const boardId = this.boardId;
      this.$store.dispatch('wgptBoardListCards/clearFilters', boardId);
    },
  },
};
</script>

<template>
  <div class="flex items-center justify-between h-20 gap-2">
    <span class="text-xl font-medium text-n-slate-12">
      {{ board.name }}
    </span>

    <div class="flex items-center gap-2">
      <div
        class="max-w-[400px] min-w-[150px] flex items-center relative mx-2 search-wrap"
      >
        <Spinner v-if="searching" class="absolute size-3.5 top-2 left-3" />
        <span v-else class="absolute i-lucide-search size-4 top-2 left-3" />
        <input
          v-model.trim="search"
          type="search"
          :placeholder="$t('WGPT_BOARDS.FILTER.SEARCH.PLACEHOLDER')"
          class="w-full h-8 py-2 pl-9 pr-2 text-sm rounded-lg bg-n-alpha-black2 dark:bg-n-solid-1 text-n-slate-12"
          @input="onInput"
        />
      </div>

      <div class="flex gap-2">
        <Dropdown align="end">
          <template #trigger="{ toggle }">
            <div class="relative">
              <div
                v-if="hasAppliedFilters"
                class="absolute w-2 h-2 rounded-full top-1 right-1 bg-slate-500 dark:bg-slate-500"
              />
              <ButtonNext
                variant="ghost"
                color="slate"
                icon="i-lucide-list-filter"
                size="sm"
                @click="toggle"
              />
            </div>
          </template>

          <template #default="{ toggle }">
            <FilterBoard
              :board="board"
              :initial-filters="filters"
              @close="toggle"
            />
          </template>
        </Dropdown>

        <ButtonNext
          v-if="hasAppliedFilters"
          :label="$t('WGPT_BOARDS.FILTER.BUTTON_TXT.CLEAR_FILTER')"
          size="sm"
          variant="faded"
          color="ruby"
          @click="clearFilters"
        />

        <ButtonNext
          variant="ghost"
          color="slate"
          icon="i-lucide-bolt"
          size="sm"
          @click="openEditPopup"
        />
      </div>
    </div>
  </div>
</template>
