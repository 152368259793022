<script>
import { mapGetters } from 'vuex';
import WootDropdownItem from 'shared/components/ui/dropdown/DropdownItem.vue';
import WgptDropdownItem from './ContactDropdownItem.vue';
import WootDropdownMenu from 'shared/components/ui/dropdown/DropdownMenu.vue';
import { debounce } from '@chatwoot/utils';

import Avatar from 'dashboard/components-next/avatar/Avatar.vue';

export default {
  components: {
    WootDropdownItem,
    WootDropdownMenu,
    WgptDropdownItem,
    Avatar,
  },
  props: {
    selectedIds: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['click', 'search'],
  data() {
    return {
      search: '',
      searching: false,
      isTyping: false,
      onSearch: debounce(event => {
        this.search = event.target.value;
        this.$emit('search', this.search);
        this.isTyping = false;
      }, 750),
      notFoundContactIds: [],
    };
  },
  computed: {
    ...mapGetters({
      contacts: 'contacts/getContacts',
      uiFlags: 'contacts/getUIFlags',
    }),
    contactIds() {
      return this.selectedIds.filter(
        selectedId => !this.notFoundContactIds.includes(selectedId)
      );
    },
    noResult() {
      return this.contacts.length === 0 && this.search !== '';
    },
    noSelectedIds() {
      if (!this.contactIds.length) return true;
      return this.contactIds.every(contactId =>
        this.notFoundContactIds.includes(contactId)
      );
    },
    isSearching() {
      return this.search !== '';
    },
  },
  mounted() {
    this.focusInput();
  },
  methods: {
    onclick(contact) {
      this.$emit('click', contact);
    },
    onInput(event) {
      const value = event.target.value.trim();
      if (value === this.search) return;

      if (value === '') {
        this.search = '';
      }
      if (value.length === 1) {
        this.search = value;
      }
      this.isTyping = true;
      this.onSearch(event);
    },
    focusInput() {
      if (!this.$refs.searchbar) return;
      this.$refs.searchbar.focus();
    },
    isActive(contact) {
      return this.selectedIds.some(selectedId => selectedId === contact.id);
    },
    onContactNotFound(contactId) {
      if (this.notFoundContactIds.includes(contactId)) return;
      this.notFoundContactIds.push(contactId);
    },
    onContactFound(contactId) {
      if (!this.notFoundContactIds.includes(contactId)) return;
      this.notFoundContactIds = this.notFoundContactIds.filter(
        notFoundContactId => notFoundContactId !== contactId
      );
    },
  },
};
</script>

<template>
  <div class="dropdown-wrap p-2 max-w-[219px]">
    <div class="relative">
      <span class="absolute i-lucide-search size-3.5 top-2 left-3" />
      <input
        ref="searchbar"
        type="search"
        :placeholder="
          $t(
            'WGPT_BOARDS.ITEM.LIST.CARD.EDIT.CONTACTS.MULTI_SELECTOR.INPUT_PLACEHOLDER'
          )
        "
        class="w-full h-8 py-2 pl-9 pr-2 text-sm border-none rounded-lg bg-n-alpha-black2 dark:bg-n-solid-1 text-n-slate-12"
        @input="onInput"
      />
    </div>
    <div class="mt-2 flex justify-start items-start flex-auto overflow-auto">
      <div class="w-full max-h-[10rem]">
        <template v-if="isSearching">
          <h4
            v-if="isTyping || uiFlags.isFetching"
            class="text-slate-500 dark:text-slate-300 py-2 text-center text-wrap text-sm"
          >
            {{
              $t(
                'WGPT_BOARDS.ITEM.LIST.CARD.EDIT.CONTACTS.MULTI_SELECTOR.SEARCH_LOADER'
              )
            }}
          </h4>
          <template v-else>
            <WootDropdownMenu>
              <WootDropdownItem v-for="item in contacts" :key="item.id">
                <button
                  class="inline-flex items-center justify-start w-full h-8 min-w-0 gap-2 px-2 py-1.5 transition-all duration-200 ease-in-out border-0 rounded-lg z-60 hover:bg-n-alpha-1 dark:hover:bg-n-alpha-2 disabled:cursor-not-allowed disabled:pointer-events-none disabled:opacity-50 text-n-slate-12"
                  :class="{
                    'bg-n-alpha-1 dark:bg-n-solid-active': isActive(item),
                  }"
                  @click="() => onclick(item)"
                  @keydown.enter="() => onclick(item)"
                >
                  <slot name="thumbnail" :item="item">
                    <Avatar
                      :name="item.name"
                      :src="item.thumbnail"
                      :size="20"
                      rounded-full
                    />
                  </slot>
                  <span v-if="item.name" class="min-w-0 text-sm truncate">{{
                    item.name
                  }}</span>
                </button>
              </WootDropdownItem>
            </WootDropdownMenu>
            <div
              v-if="noResult"
              class="text-sm text-n-slate-11 px-2 py-1.5 text-center"
            >
              {{
                $t(
                  'WGPT_BOARDS.ITEM.LIST.CARD.EDIT.CONTACTS.MULTI_SELECTOR.NO_RESULT'
                )
              }}
            </div>
          </template>
        </template>

        <WootDropdownMenu v-show="!isSearching">
          <WgptDropdownItem
            v-for="contactId in contactIds"
            :key="contactId"
            :contact-id="contactId"
            @toggle="onclick"
            @found="onContactFound"
            @not-found="onContactNotFound"
          />
          <div
            v-if="noSelectedIds"
            class="text-sm text-n-slate-11 px-2 py-1.5 text-center"
          >
            {{
              $t(
                'WGPT_BOARDS.ITEM.LIST.CARD.EDIT.CONTACTS.MULTI_SELECTOR.SELECTED.EMPTY'
              )
            }}
          </div>
        </WootDropdownMenu>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.dropdown-wrap {
  @apply w-full flex flex-col max-h-[12.5rem];
}

.search-input {
  @apply m-0 w-full border border-solid border-transparent h-8 text-sm text-slate-700 dark:text-slate-100 rounded-md focus:border-woot-500 bg-slate-50 dark:bg-slate-900;
}

.multiselect-dropdown--item {
  @apply justify-between w-full;

  &.active {
    @apply bg-slate-25 dark:bg-slate-700 border-slate-50 dark:border-slate-900 font-medium;
  }

  &:focus {
    @apply bg-slate-25 dark:bg-slate-700;
  }

  &:hover {
    @apply bg-slate-50 dark:bg-slate-800 text-slate-800 dark:text-slate-100;
  }
}
</style>
