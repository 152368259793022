export const mapToOptions = (items, valueKey, labelKey) => {
  return (
    items?.map(item => {
      return {
        ...item,
        value: item[valueKey],
        label: item[labelKey],
      };
    }) ?? []
  );
};
