<script>
import { mapGetters } from 'vuex';
import { useAlert } from 'dashboard/composables/wgpt';
import { useMessageFormatter } from 'shared/composables/useMessageFormatter';
import TimeAgo from './TimeAgo.vue';
import FullEditor from '../../components/FullEditor.vue';
import canDismissMixin from '../../mixins/preventDismissMixin';
import keyboardEventListenerMixins from 'shared/mixins/keyboardEventListenerMixins';
import { emitter } from 'shared/helpers/mitt';
import getUuid from 'widget/helpers/uuid';

import { OnClickOutside } from '@vueuse/components';
import ButtonNext from 'dashboard/components-next/button/Button.vue';
import Avatar from 'dashboard/components-next/avatar/Avatar.vue';

export default {
  components: {
    Avatar,
    TimeAgo,
    FullEditor,
    ButtonNext,
    OnClickOutside,
  },
  mixins: [canDismissMixin, keyboardEventListenerMixins],
  props: {
    currentUserId: {
      type: [String, Number],
      default: '',
    },
    users: {
      type: Array,
      default: () => [],
    },
    comment: {
      type: Object,
      default: () => {},
    },
    onEdit: {
      type: Function,
      default: () => {},
    },
    onDelete: {
      type: Function,
      default: () => {},
    },
    canDeleteComments: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: true,
    },
    uploadFile: {
      type: Function,
      default: () => {},
    },
  },
  setup() {
    const { formatMessage } = useMessageFormatter();
    return { formatMessage };
  },
  data() {
    return {
      content: '',
      contentEditorVisible: false,
      isUploading: false,
      id: getUuid(),
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'wgptBoardListCardActivities/getUIFlags',
      accountId: 'getCurrentAccountId',
    }),
    canEditComment() {
      if (this.readonly) return false;
      if (this.comment.created_by_id === this.currentUserId) return true;
      return false;
    },
    canDeleteComment() {
      if (this.readonly) return false;
      if (this.canDeleteComments) return true;
      if (this.comment.created_by_id === this.currentUserId) return true;
      return false;
    },
    createdBy() {
      if (this.comment.created_by_id === -1) {
        return {
          name: 'Bot',
        };
      }

      const user = this.users.find(u => {
        return u.id === this.comment.created_by_id;
      });

      if (!user) {
        return {
          name: this.$t('APP_GLOBAL.DELETED_USER'),
        };
      }

      return user;
    },
    conversationMessageLink() {
      if (!this.comment.content_attributes.conversation_id) return '';

      const routeData = this.$router.resolve({
        name: 'inbox_conversation',
        params: {
          accountId: this.accountId,
          conversation_id: this.comment.content_attributes.conversation_id,
        },
        query: {
          messageId: this.comment.content_attributes.message_id,
        },
      });

      return routeData.href;
    },
    preventDismiss() {
      return this.contentEditorVisible;
    },
  },
  mounted() {
    emitter.on('wgptCloseAllBoardListCardActivityEditor', () => {
      this.hideMessageEditor();
    });
  },
  beforeUnmount() {
    emitter.off('wgptCloseAllBoardListCardActivityEditor');
  },
  methods: {
    showMessageEditor() {
      emitter.emit('wgptCloseAllBoardListCardActivityEditor');
      this.content = this.comment.content;
      this.contentEditorVisible = true;
      emitter.emit('WGPT_DROPDOWN_TOGGLE', { id: this.id, state: true });
    },
    hideMessageEditor() {
      this.contentEditorVisible = false;
      emitter.emit('WGPT_DROPDOWN_TOGGLE', { id: this.id, state: false });
    },
    deleteComment() {
      this.onDelete(this.comment);
    },
    async editComment() {
      try {
        if (!this.content) return;

        const data = {
          id: this.comment.id,
          content: this.content,
        };
        await this.onEdit(data);

        this.content = '';
        this.selectedActivity = {};
        this.hideMessageEditor();
      } catch (error) {
        const errorMessage =
          error.message ||
          this.$t(
            'WGPT_BOARDS.ITEM.LIST.CARD.EDIT.ACTIVITIES.FORM.API.ERROR_MESSAGE'
          );
        useAlert(errorMessage);
      }
    },
    onFileUploadStart() {
      this.isUploading = true;
    },
    onFileUploadSuccess() {
      this.isUploading = false;
    },
    onFileUploadError() {
      this.isUploading = false;
    },
    getKeyboardEvents() {
      return {
        Escape: {
          action: () => this.hideMessageEditor(),
          allowOnFocusedInput: true,
        },
      };
    },
  },
};
</script>

<template>
  <div class="flex gap-3">
    <Avatar :src="createdBy.thumbnail" :name="createdBy.name" />
    <div class="flex-1 overflow-hidden text-wrap">
      <div class="flex items-center mb-1">
        <label class="font-bold leading-normal">{{ createdBy.name }}</label>
        <TimeAgo
          class="mx-3"
          :created-at="comment.created_at"
          :updated-at="comment.updated_at"
        />
      </div>
      <OnClickOutside v-if="contentEditorVisible" @trigger="hideMessageEditor">
        <FullEditor
          v-model.trim="content"
          :placeholder="
            $t(
              'WGPT_BOARDS.ITEM.LIST.CARD.EDIT.ACTIVITIES.FORM.COMMENT.PLACEHOLDER'
            )
          "
          :upload-file="uploadFile"
          @file-upload-start="onFileUploadStart"
          @file-upload-success="onFileUploadSuccess"
          @file-upload-error="onFileUploadError"
        />
        <div class="flex gap-2 mt-2">
          <ButtonNext
            :label="$t('WGPT_BOARDS.ITEM.LIST.CARD.EDIT.ACTIVITIES.FORM.SAVE')"
            :is-disabled="uiFlags.isUpdating || isUploading"
            :is-loading="uiFlags.isUpdating"
            size="sm"
            @click="editComment"
          />
          <ButtonNext
            :label="
              $t('WGPT_BOARDS.ITEM.LIST.CARD.EDIT.ACTIVITIES.FORM.DISCARD')
            "
            :is-disabled="uiFlags.isUpdating"
            size="sm"
            color="slate"
            variant="faded"
            @click="hideMessageEditor"
          />
        </div>
      </OnClickOutside>
      <div v-else>
        <label>
          <div
            v-dompurify-html="formatMessage(comment.content)"
            class="dompurified-html shadow px-3 py-2 rounded-lg bg-n-alpha-black2 [&>p]:text-slate-900 [&>p]:dark:text-slate-300 [&>p:first-child]:mt-0 [&>p:last-child]:mb-0"
          />
        </label>

        <div class="flex justify-between">
          <div class="actions flex items-center">
            <div v-if="canEditComment">
              <ButtonNext
                :label="
                  $t('WGPT_BOARDS.ITEM.LIST.CARD.EDIT.ACTIVITIES.FORM.EDIT')
                "
                size="xs"
                color="slate"
                variant="link"
                class="px-1 opacity-75"
                @click="showMessageEditor"
              />
            </div>
            <div v-if="canDeleteComment">
              <ButtonNext
                :label="
                  $t('WGPT_BOARDS.ITEM.LIST.CARD.EDIT.ACTIVITIES.FORM.DELETE')
                "
                size="xs"
                color="slate"
                variant="link"
                class="px-1 opacity-75"
                @click="deleteComment"
              />
            </div>
          </div>
          <div>
            <template v-if="conversationMessageLink">
              <div>
                <a :href="conversationMessageLink" target="blank">
                  <ButtonNext
                    :label="
                      $t(
                        'WGPT_BOARDS.ITEM.LIST.CARD.EDIT.ACTIVITIES.FORM.GO_TO_CONVERSATION'
                      )
                    "
                    size="xs"
                    color="blue"
                    variant="link"
                    class="opacity-75"
                    icon="i-lucide-external-link"
                    trailing-icon
                  />
                </a>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.actions > :nth-child(1n + 2)::before {
  content: '·';
  display: inline-block;
}
.dompurified-html {
  ::v-deep {
    ul {
      @apply px-4;
    }
  }
}
</style>
