<script>
import Avatar from 'dashboard/components-next/avatar/Avatar.vue';

export default {
  components: {
    Avatar,
  },
  props: {
    contactId: {
      type: [String, Number],
      default: '',
    },
    isPrimary: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['open', 'found', 'notFound'],
  data() {
    return {
      exists: undefined,
      contactCache: null,
    };
  },
  computed: {
    contactReactive() {
      return this.$store.getters['contacts/getContact'](this.contactId);
    },
    contact() {
      return this.contactCache ?? this.contactReactive;
    },
    loading() {
      return !this.contactCache;
    },
  },
  watch: {
    contactReactive: {
      handler(value) {
        if (!this.contactCache || value.id === this.contactId) {
          this.contactCache = value;
        }
      },
      immediate: true,
    },
    contactCache: {
      handler(value) {
        if (!value) return;
        if (value.id === this.contactId) {
          this.exists = true;
          this.$emit('found');
        } else {
          this.exists = false;
          this.$emit('notFound');
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.setDefaults();
  },
  methods: {
    setDefaults() {
      const contactStore = this.$store.getters['contacts/getContact'](
        this.contactId
      );
      if (contactStore.id === this.contactId) return;
      this.$store.dispatch('contacts/show', { id: this.contactId });
    },
    openContactProfile() {
      this.$emit('open', this.contact);
    },
  },
};
</script>

<!-- eslint-disable-next-line vue/no-root-v-if -->
<template>
  <div v-if="exists">
    <div
      v-if="loading"
      class="w-8 h-8 bg-slate-100 dark:bg-slate-500 rounded-full wgpt-animate-pulse"
    />
    <div v-else @click="openContactProfile">
      <Avatar
        :key="contact.id"
        class="contact-avatar cursor-pointer outline-none"
        :src="contact.thumbnail"
        :name="contact.name"
        tabindex="0"
      />
      <div
        v-if="isPrimary"
        class="absolute z-20 rounded-full bg-orange-600 top-0 end-0.5 -me-1 flex pointer-events-none"
      >
        <span class="i-lucide-asterisk text-white text-xxs" />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.wgpt-animate-pulse {
  animation: wgptAnimatePulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

@keyframes wgptAnimatePulse {
  50% {
    opacity: 0.5;
  }
}
</style>
