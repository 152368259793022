<script>
import { mapGetters } from 'vuex';
import { useAlert } from 'dashboard/composables';
import { useAdmin } from 'dashboard/composables/useAdmin';
import { useUISettings } from 'dashboard/composables/useUISettings';
import Draggable from 'vuedraggable';

import ListItem from './Item.vue';
import AddList from './AddList.vue';
import DeleteListDialog from './DeleteList.vue';
import EditCardDialog from './card/edit/EditCardDialog.vue';
import EditListDialog from './EditListDialog.vue';
import ButtonNext from 'dashboard/components-next/button/Button.vue';
import CardLayout from 'dashboard/components-next/CardLayout.vue';
import ItemEmptyState from '../ItemEmptyState.vue';

export default {
  components: {
    Draggable,
    ListItem,
    AddList,
    DeleteListDialog,
    EditCardDialog,
    ItemEmptyState,
    EditListDialog,
    ButtonNext,
    CardLayout,
  },
  props: {
    board: {
      type: Object,
      default: () => {},
    },
  },
  setup() {
    const { uiSettings, updateUISettings } = useUISettings();
    const { isAdmin } = useAdmin();
    return {
      uiSettings,
      updateUISettings,
      isAdmin,
    };
  },
  data() {
    return {
      loading: {},
      showAddListPopup: false,
      showEditListPopup: false,
      showDeleteListConfirmationPopup: false,
      showEditCardDialogPopup: false,
      selectedList: {},
      drag: false,
      now: new Date(),
    };
  },
  computed: {
    ...mapGetters({
      allLabels: 'labels/getLabels',
      allAgents: 'agents/getAgents',
      currentUserID: 'getCurrentUserID',
      selectedCard: 'wgptBoardListCards/getActiveCard',
    }),
    boardId() {
      return this.board.id;
    },
    list: {
      get() {
        return this.$store.getters['wgptBoardLists/getLists'];
      },
      set(data) {
        this.$store.dispatch('wgptBoardLists/setBoardLists', data);
      },
    },
    dragOptions() {
      return {
        animation: 200,
        group: 'description',
        ghostClass: 'ghost',
      };
    },
    canManageLists() {
      if (this.board.closed) return false;
      if (this.isAdmin) return true;
      const managers = this.board?.config?.authorization?.managers ?? [];
      if (managers.includes(this.currentUserID)) return true;
      return false;
    },
    canManageCards() {
      if (this.board.closed) return false;
      if (this.isAdmin) return true;
      const managers = this.board?.config?.authorization?.managers ?? [];
      if (managers.includes(this.currentUserID)) return true;
      const operators = this.board?.config?.authorization?.operators ?? [];
      if (operators.includes(this.currentUserID)) return true;
      return false;
    },
    canDeleteCards() {
      if (this.board.closed) return false;
      if (this.isAdmin) return true;
      const managers = this.board?.config?.authorization?.managers ?? [];
      if (managers.includes(this.currentUserID)) return true;
      return false;
    },
    wgptMinimizeBoardListCardLabels() {
      return this.uiSettings.wgpt_minimize_board_list_card_labels ?? true;
    },
    showEditCardDialogPopupModel: {
      get() {
        if (!this.selectedCard.id) return false;
        return this.showEditCardDialogPopup;
      },
      set(value) {
        this.showEditCardDialogPopup = value;
      },
    },
    selectedCardId() {
      if (!this.boardId) return null;
      const selectedCardId = this.$route.query.selectedCard;
      if (!selectedCardId) return null;
      return Number(selectedCardId);
    },
  },
  watch: {
    showEditCardDialogPopupModel(value) {
      if (value) {
        this.$refs.editCardDialogRef.open();
      } else {
        this.$refs.editCardDialogRef.close(true);
      }
    },
    boardId: {
      handler() {
        this.fetchLists();
      },
      immediate: true,
    },
    selectedCardId: {
      handler(id) {
        if (id) {
          this.fetchSelectedCard();
          this.openEditCardDialogPopup();
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.$store.dispatch('agents/get');
  },
  methods: {
    fetchLists() {
      if (!this.boardId) return;
      this.$store.dispatch('wgptBoardLists/clear');
      this.$store.dispatch('wgptBoardListCards/clear');
      this.$store.dispatch('wgptBoardLists/get', this.boardId);
      this.$store.dispatch('wgptBoards/getAutomations', this.boardId);
    },
    fetchSelectedCard() {
      const cachedCard = this.$store.getters['wgptBoardListCards/getCard'](
        this.selectedCardId
      );
      if (cachedCard) {
        this.$store.dispatch('wgptBoardListCards/setActiveCard', cachedCard);
        return;
      }
      const getCardOptions = {
        boardId: this.boardId,
        id: this.selectedCardId,
      };
      this.$store.dispatch(
        'wgptBoardListCards/loadSelectedCard',
        getCardOptions
      );
    },
    async onListOrderChange({ moved: event }) {
      try {
        const { element: listItem, oldIndex, newIndex } = event;
        const list = this.list;
        const index = this.list.findIndex(({ id }) => id === listItem.id);

        let order = newIndex + 1;
        if (newIndex > oldIndex) {
          const previousListItem = list[index - 1];
          order = previousListItem.order;
        } else if (newIndex < oldIndex) {
          const nextListItem = list[index + 1];
          order = nextListItem.order;
        }

        const boardList = {
          order,
        };
        const boardId = this.$route.params.boardId;
        const id = listItem.id;
        await this.$store.dispatch('wgptBoardLists/update', {
          boardId,
          id,
          ...boardList,
        });
      } catch (error) {
        const errorMessage =
          error.message ||
          this.$t('WGPT_BOARDS.EDIT_BOARD_LIST.API.ERROR_MESSAGE');
        useAlert(errorMessage);
      }
    },
    openAddListPopup() {
      this.showAddListPopup = true;
    },
    hideAddListPopup() {
      this.showAddListPopup = false;
    },
    openEditListPopup(list) {
      this.selectedList = list;
      this.$refs.editListDialog.open();
      this.showEditListPopup = true;
    },
    hideEditListPopup() {
      this.showEditListPopup = false;
    },
    openDeleteListPopup() {
      this.$refs.deleteListDialog.open(this.selectedList);
      this.hideEditListPopup();
      this.showDeleteListConfirmationPopup = true;
    },
    hideDeleteListPopup() {
      this.showDeleteListConfirmationPopup = false;
    },
    openEditCardDialogPopup() {
      this.showEditCardDialogPopup = true;
    },
    hideEditCardDialogPopup() {
      this.showEditCardDialogPopup = false;
      this.now = new Date();
      this.$router.push({ query: { selectedCard: undefined } });
    },
    toggleLabelLayout() {
      const previousState =
        this.uiSettings.wgpt_minimize_board_list_card_labels ?? true;
      this.updateUISettings({
        wgpt_minimize_board_list_card_labels: !previousState,
      });
    },
  },
};
</script>

<template>
  <div
    class="h-full min-w-full flex gap-4"
    :class="{
      wgptMinimizeBoardListCardLabels: wgptMinimizeBoardListCardLabels,
    }"
  >
    <Draggable
      v-if="list.length > 0"
      v-model="list"
      item-key="id"
      class="flex gap-4 items-start mt-px"
      v-bind="dragOptions"
      group="lists"
      :disabled="!canManageLists"
      force-fallback
      :fallback-tolerance="20"
      dir="ltr"
      @start="drag = true"
      @end="drag = false"
      @change="onListOrderChange"
    >
      <template #item="{ element: listItem }">
        <ListItem
          :list="listItem"
          :can-manage-cards="canManageCards"
          :can-manage-lists="canManageLists"
          :labels="allLabels"
          :agents="allAgents"
          :now="now"
          @edit="openEditListPopup(listItem)"
          @toggle-label-layout="toggleLabelLayout"
        />
      </template>
    </Draggable>

    <ItemEmptyState
      v-else-if="!canManageLists"
      :title="$t('WGPT_BOARDS.ITEM.EMPTY_STATE.TITLE')"
      :subtitle="$t('WGPT_BOARDS.ITEM.EMPTY_STATE.SUBTITLE_READONLY')"
    />

    <div v-if="canManageLists" class="filtered w-[300px] mt-px">
      <CardLayout class="[&>div]:p-0 overflow-hidden">
        <div
          v-if="showAddListPopup"
          v-on-clickaway="hideAddListPopup"
          class="text-slate-700 dark:text-slate-100 p-3"
        >
          <AddList @close="hideAddListPopup" />
        </div>
        <ButtonNext
          v-else-if="canManageCards"
          :label="$t('WGPT_BOARDS.ITEM.LIST.BUTTON_TEXT.ADD_LIST')"
          variant="ghost"
          icon="i-lucide-plus"
          color="slate"
          class="w-full"
          @click="openAddListPopup"
        />
      </CardLayout>
    </div>

    <EditListDialog
      ref="editListDialog"
      :board="board"
      :list="selectedList"
      :can-manage-lists="canManageLists"
      @delete="openDeleteListPopup"
    />

    <DeleteListDialog ref="deleteListDialog" />

    <EditCardDialog
      ref="editCardDialogRef"
      :card="selectedCard"
      :can-manage-cards="canManageCards"
      :can-delete-cards="canDeleteCards"
      @close="hideEditCardDialogPopup"
    />
  </div>
</template>

<style lang="scss" scoped>
.wgptMinimizeBoardListCardLabels ::v-deep .board-card-label {
  height: 8px;
  width: 42px;
  span {
    display: none;
  }
}
</style>
