<script>
import EmojiOrIcon from 'shared/components/EmojiOrIcon.vue';
import { copyTextToClipboard } from 'shared/helpers/clipboard';

import InlineTooltip from 'dashboard/components-next/wgpt/Tooltip/Inline.vue';
import ButtonNext from 'dashboard/components-next/button/Button.vue';

export default {
  components: {
    EmojiOrIcon,
    ButtonNext,
    InlineTooltip,
  },
  props: {
    href: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      required: true,
    },
    emoji: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      default: '',
    },
    showCopy: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    async onCopy(e) {
      e.preventDefault();
      await copyTextToClipboard(this.value);
      this.$refs.tooltipRef.show();
    },
  },
};
</script>

<template>
  <div class="w-full h-5 ltr:-ml-1 rtl:-mr-1">
    <a
      v-if="href"
      :href="href"
      class="flex items-center gap-2 text-slate-800 dark:text-slate-100 hover:underline"
    >
      <EmojiOrIcon
        :icon="icon"
        :emoji="emoji"
        icon-size="14"
        class="flex-shrink-0 ltr:ml-1 rtl:mr-1"
      />
      <span
        v-if="value"
        class="overflow-hidden text-sm whitespace-nowrap text-ellipsis"
        :title="value"
      >
        {{ value }}
      </span>
      <span v-else class="text-sm text-slate-300 dark:text-slate-600">{{
        $t('CONTACT_PANEL.NOT_AVAILABLE')
      }}</span>

      <InlineTooltip
        ref="tooltipRef"
        :content="$t('WGPT_BOARDS.ITEM.LIST.CARD.EDIT.COPY_SUCCESSFUL')"
        popper-class="-mt-0.5"
        placement="auto-start"
      >
        <ButtonNext
          v-if="showCopy"
          variant="ghost"
          size="xs"
          color="slate"
          icon="i-lucide-copy"
          @click="onCopy"
        />
      </InlineTooltip>
    </a>

    <div
      v-else
      class="flex items-center gap-2 text-slate-800 dark:text-slate-100"
    >
      <EmojiOrIcon
        :icon="icon"
        :emoji="emoji"
        icon-size="14"
        class="flex-shrink-0 ltr:ml-1 rtl:mr-1"
      />
      <span
        v-if="value"
        class="overflow-hidden text-sm whitespace-nowrap text-ellipsis"
      >
        {{ value }}
      </span>
      <span v-else class="text-sm text-slate-300 dark:text-slate-600">{{
        $t('CONTACT_PANEL.NOT_AVAILABLE')
      }}</span>
    </div>
  </div>
</template>
