<template>
  <section class="flex flex-col w-full h-full overflow-hidden bg-n-background">
    <header class="sticky top-0 z-10 px-6 pb-3">
      <div class="w-full">
        <slot name="header" />
      </div>
    </header>
    <main class="flex-1 flex flex-column overflow-y-auto">
      <div class="flex-1 flex flex-column px-6 pb-3 w-max">
        <slot name="content" />
      </div>
    </main>
  </section>
</template>
