<script setup>
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import CardLayout from 'dashboard/components-next/CardLayout.vue';
import Button from 'dashboard/components-next/button/Button.vue';
import DropdownMenu from 'dashboard/components-next/dropdown-menu/DropdownMenu.vue';
import Dropdown from 'dashboard/components-next/wgpt/Dropdown/Dropdown.vue';

const props = defineProps({
  board: {
    type: Object,
    required: true,
  },
  isAdmin: {
    type: Boolean,
    default: false,
  },
  currentUser: {
    type: Object,
    default: () => ({}),
  },
});

const emit = defineEmits(['click', 'edit']);

const { t } = useI18n();

const boardMenuItems = [
  {
    label: t('WGPT_BOARDS.OVERVIEW.CONTEXT_MENU.SETTINGS'),
    action: 'edit',
    value: 'edit',
    icon: 'i-lucide-settings',
  },
];

const hasDescription = computed(() => {
  return props.board.description.length > 0;
});

const description = computed(() => {
  return hasDescription.value ? props.board.description : '';
});

const hasMinimalAccess = computed(() => {
  const authorization = props.board.config?.authorization || {};
  if (props.isAdmin) return true;

  const managers = authorization?.managers || [];
  if (managers.includes(props.currentUser.id)) return true;

  const operators = authorization?.operators || [];
  if (operators.includes(props.currentUser.id)) return true;

  const readers = authorization?.readers || [];
  if (readers.includes(props.currentUser.id)) return true;

  return false;
});

const handleClick = slug => {
  emit('click', slug);
};

const handleAction = ({ action }, toggle) => {
  emit('edit', action);
  toggle();
};
</script>

<template>
  <CardLayout>
    <div class="flex gap-2">
      <div class="flex justify-between w-full gap-2">
        <div class="flex items-center justify-start w-full min-w-0 gap-2">
          <span
            class="text-base truncate cursor-pointer hover:underline underline-offset-2 hover:text-n-blue-text text-n-slate-12"
            @click="handleClick(slug)"
          >
            {{ board.name }}
          </span>
        </div>
        <Dropdown>
          <template #trigger="{ toggle }">
            <Button
              v-if="hasMinimalAccess"
              icon="i-lucide-ellipsis-vertical"
              color="slate"
              size="xs"
              variant="ghost"
              class="rounded-md group-hover:bg-n-alpha-2"
              @click="toggle"
            />
          </template>
          <template #default="{ toggle }">
            <DropdownMenu
              class="!static"
              :menu-items="boardMenuItems"
              @action="e => handleAction(e, toggle)"
            />
          </template>
        </Dropdown>
      </div>
    </div>
    <span
      class="text-sm line-clamp-2 min-h-[40px]"
      :class="
        hasDescription
          ? 'text-slate-500 dark:text-slate-400'
          : 'text-slate-400 dark:text-slate-700'
      "
    >
      {{ description }}
    </span>
  </CardLayout>
</template>
