<script>
import { useAlert } from 'dashboard/composables/wgpt';
import { mapToOptions } from '../../../../../helpers/dropdownHelper';

import Dropdown from 'dashboard/components-next/wgpt/Dropdown/Dropdown.vue';
import Avatar from 'dashboard/components-next/avatar/Avatar.vue';
import DropdownMenuContent from '../../../../../components/DropdownMenuContent.vue';
import ButtonNext from 'dashboard/components-next/button/Button.vue';

export default {
  components: {
    DropdownMenuContent,
    Avatar,
    Dropdown,
    ButtonNext,
  },
  props: {
    board: {
      type: Object,
      default: () => {},
    },
    list: {
      type: Object,
      default: () => {},
    },
    card: {
      type: Object,
      default: () => {},
    },
    agents: {
      type: Array,
      default: () => [],
    },
    readonly: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      showSearchDropdown: false,
    };
  },
  computed: {
    selectedMemberIds: {
      get() {
        return this.card.config?.members ?? [];
      },
      set(value) {
        this.onUpdate(value);
      },
    },
    selectedMembers() {
      return this.agents.filter(agent =>
        this.selectedMemberIds.includes(agent.id)
      );
    },
    agentList() {
      return mapToOptions(this.agents, 'id', 'name').map(item => {
        return {
          ...item,
          isSelected: this.selectedMemberIds.includes(item.value),
        };
      });
    },
  },
  methods: {
    async onUpdate(selectedItems) {
      if (this.readonly) return;
      try {
        const boardId = this.board.id;
        const listId = this.list.id;
        const id = this.card.id;
        const data = {
          config: {
            ...this.card.config,
            members: selectedItems,
          },
        };
        await this.$store.dispatch('wgptBoardListCards/update', {
          boardId,
          listId,
          id,
          ...data,
        });
      } catch (error) {
        useAlert(
          this.$t('WGPT_BOARDS.ITEM.LIST.CARD.EDIT.MEMBERS.API.ERROR_MESSAGE')
        );
      }
    },
    toggleDropdown() {
      this.showSearchDropdown = !this.showSearchDropdown;
    },
    closeDropdown() {
      this.showSearchDropdown = false;
    },
    handleAction(item) {
      if (this.selectedMemberIds.includes(item.value)) {
        this.selectedMemberIds = this.selectedMemberIds.filter(
          selectedMember => selectedMember !== item.value
        );
      } else {
        this.selectedMemberIds = [...this.selectedMemberIds, item.value];
      }
    },
  },
};
</script>

<!-- eslint-disable-next-line vue/no-root-v-if -->
<template>
  <div v-if="selectedMemberIds.length">
    <label>
      {{ $t('WGPT_BOARDS.ITEM.LIST.CARD.EDIT.MEMBERS.LABEL') }}
    </label>

    <div class="flex gap-1 overflow-hidden flex-wrap">
      <Avatar
        v-for="selectedMember in selectedMembers"
        :key="selectedMember.id"
        :src="selectedMember.thumbnail"
        :name="selectedMember.name"
      />

      <Dropdown>
        <template #trigger="{ toggle }">
          <ButtonNext
            v-if="!readonly"
            size="sm"
            color="slate"
            variant="faded"
            icon="i-lucide-plus"
            class="!rounded-xl"
            @click="toggle"
          />
        </template>
        <template #default="{ toggle }">
          <div>
            <div
              class="pt-2 ps-3 pe-1 mb-0.5 flex justify-between items-center"
            >
              <label class="text-sm font-medium text-n-slate-12">
                {{
                  $t(
                    'WGPT_BOARDS.ITEM.LIST.CARD.EDIT.SIDEBAR.MEMBERS.DROPDOWN.TITLE'
                  )
                }}
              </label>
              <ButtonNext
                icon="i-lucide-x"
                size="xs"
                color="slate"
                variant="ghost"
                class="opacity-50 hover:opacity-100"
                @click="toggle"
              />
            </div>
            <DropdownMenuContent
              :menu-items="agentList"
              show-search
              :search-placeholder="
                $t(
                  'WGPT_BOARDS.ITEM.LIST.CARD.EDIT.SIDEBAR.MEMBERS.DROPDOWN.PLACEHOLDER'
                )
              "
              :search-empty-state="
                $t(
                  'WGPT_BOARDS.ITEM.LIST.CARD.EDIT.SIDEBAR.MEMBERS.DROPDOWN.NO_RESULT'
                )
              "
              class="ltr:right-0 rtl:left-0 xl:ltr:left-0 xl:rtl:right-0 top-full max-w-[219px]"
              @action="handleAction"
            >
              <template #thumbnail="{ item }">
                <Avatar :src="item.thumbnail" :name="item.label" :size="20" />
              </template>
            </DropdownMenuContent>
          </div>
        </template>
      </Dropdown>
    </div>
  </div>
</template>
