<script>
import { useAlert } from 'dashboard/composables/wgpt';
import { checkFileSizeLimit } from 'shared/helpers/FileHelper';
import { MAXIMUM_FILE_UPLOAD_SIZE } from '../../helpers/contants';

import ButtonNext from 'dashboard/components-next/button/Button.vue';

export default {
  components: {
    ButtonNext,
  },
  props: {
    board: {
      type: Object,
      default: () => {},
    },
    list: {
      type: Object,
      default: () => {},
    },
    card: {
      type: Object,
      default: () => {},
    },
    contacts: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      showSearchDropdown: false,
      uploading: false,
    };
  },
  computed: {
    selectedContacts() {
      const contactIds = this.card.config?.contacts ?? [];
      return this.contacts.filter(contact => contactIds.includes(contact.id));
    },
  },
  methods: {
    openFileBrowser() {
      this.$refs.imageUploadInput.click();
    },
    onFileChange() {
      const file = this.$refs.imageUploadInput.files[0];

      if (checkFileSizeLimit(file, MAXIMUM_FILE_UPLOAD_SIZE)) {
        this.uploadImageToStorage(file);
      } else {
        useAlert(
          this.$t(
            'WGPT_BOARDS.ITEM.LIST.CARD.EDIT.ATTACHMENTS.API.FILE_SIZE_ERROR',
            {
              size: MAXIMUM_FILE_UPLOAD_SIZE,
            }
          )
        );
      }

      this.$refs.imageUploadInput.value = '';
    },
    async uploadImageToStorage(file) {
      try {
        this.uploading = true;
        const data = {
          file,
          boardId: this.board.id,
          listId: this.list.id,
          id: this.card.id,
        };
        await this.$store.dispatch('wgptBoardListCards/addAttachment', data);
      } catch (error) {
        if (error.message === 'FILE_SIZE_ERROR') {
          useAlert(
            this.$t(
              'WGPT_BOARDS.ITEM.LIST.CARD.EDIT.ATTACHMENTS.API.FILE_SIZE_ERROR',
              {
                size: MAXIMUM_FILE_UPLOAD_SIZE,
              }
            )
          );
        } else {
          useAlert(
            this.$t(
              'WGPT_BOARDS.ITEM.LIST.CARD.EDIT.ATTACHMENTS.API.UPLOAD_ERROR'
            )
          );
        }
      } finally {
        this.uploading = false;
      }
    },
  },
};
</script>

<template>
  <div class="relative">
    <ButtonNext
      :label="$t('WGPT_BOARDS.ITEM.LIST.CARD.EDIT.SIDEBAR.ATTACHMENTS.LABEL')"
      size="sm"
      variant="faded"
      color="slate"
      icon="i-lucide-paperclip"
      :disabled="disabled || uploading"
      class="w-full !justify-start"
      @click="openFileBrowser"
    />
    <input ref="imageUploadInput" type="file" hidden @change="onFileChange" />
  </div>
</template>
