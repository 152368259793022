<script setup>
import { ref, computed, onMounted } from 'vue';
import { useMapGetter } from 'dashboard/composables/store';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { frontendURL } from 'dashboard/helper/URLHelper';

import BoardList from './BoardList.vue';
import OverviewHeaderControls from './OverviewHeaderControls.vue';
import OverviewLayout from './OverviewLayout.vue';
import Spinner from 'dashboard/components-next/spinner/Spinner.vue';

const emit = defineEmits(['addBoard', 'editBoard']);

const store = useStore();
const { t } = useI18n();

const boardFilter = ref([]);

const uiFlags = useMapGetter('wgptBoards/getUIFlags');
const accountId = computed(() => store.getters.getCurrentAccountId);
const allBoards = computed(() => store.getters['wgptBoards/getBoards']);

const boards = computed(() =>
  allBoards.value
    .filter(board => !board.closed)
    .map(board => ({
      ...board,
      toState: frontendURL(
        `accounts/${accountId.value}/wgpt-boards/${board.id}`
      ),
    }))
);

const closedBoards = computed(() =>
  allBoards.value
    .filter(board => board.closed)
    .map(board => ({
      ...board,
      toState: frontendURL(
        `accounts/${accountId.value}/wgpt-boards/${board.id}`
      ),
    }))
);

const handleBoardFilterChange = value => {
  boardFilter.value = value;
};

const handleBoardEdit = (action, board) => {
  emit('editBoard', board);
};

onMounted(() => {
  store.dispatch('wgptBoards/get');
});
</script>

<template>
  <OverviewLayout :show-header-actions="!uiFlags.isFetching" class="lg:px-6">
    <template #header-actions>
      <OverviewHeaderControls
        :boards="allBoards"
        :is-category-articles="false"
        @add-board="emit('addBoard')"
        @board-filter-change="handleBoardFilterChange"
      />
    </template>
    <template #content>
      <div
        v-if="uiFlags.isFetching"
        class="flex items-center justify-center py-10 text-n-slate-11"
      >
        <Spinner />
      </div>
      <BoardList
        v-else-if="allBoards.length === 0"
        show-empty-state
        @edit="handleBoardEdit"
      />
      <BoardList
        v-else-if="boardFilter === 'open'"
        :boards="boards"
        show-empty-state
        @edit="handleBoardEdit"
      />
      <BoardList
        v-else-if="boardFilter === 'closed'"
        :boards="closedBoards"
        show-empty-state
        @edit="handleBoardEdit"
      />
      <div v-else class="flex flex-col gap-16">
        <BoardList :boards="boards" @edit="handleBoardEdit" />
        <BoardList
          v-if="closedBoards.length"
          :title="t('WGPT_BOARDS.OVERVIEW.CLOSED_BOARDS.HEADER')"
          :boards="closedBoards"
          @edit="handleBoardEdit"
        />
      </div>
    </template>
  </OverviewLayout>
</template>
