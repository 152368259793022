<script setup>
import { ref, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { OnClickOutside } from '@vueuse/components';
import { useAdmin } from 'dashboard/composables/useAdmin';

import Button from 'dashboard/components-next/button/Button.vue';
import DropdownMenu from 'dashboard/components-next/dropdown-menu/DropdownMenu.vue';
import Dropdown from 'dashboard/components-next/wgpt/Dropdown/Dropdown.vue';
import AddBoardDropdown from './components/AddBoardDropdown.vue';

const props = defineProps({
  boards: {
    type: Array,
    default: () => [],
  },
});

const emit = defineEmits(['boardFilterChange']);

const { t } = useI18n();
const { isAdmin } = useAdmin();

const isFilterMenuOpen = ref(false);

const boardFilterOptions = [
  {
    label: t('WGPT_BOARDS.OVERVIEW.HEADER.FILTER_OPTIONS.ALL'),
    value: 'all',
    action: 'filter',
  },
  {
    label: t('WGPT_BOARDS.OVERVIEW.HEADER.FILTER_OPTIONS.OPEN'),
    value: 'open',
    action: 'filter',
  },
  {
    label: t('WGPT_BOARDS.OVERVIEW.HEADER.FILTER_OPTIONS.CLOSED'),
    value: 'closed',
    action: 'filter',
  },
];

const selectedBoardFilter = ref(boardFilterOptions[0].value);

const filteredBoards = computed(() => {
  const boards = props.boards ?? [];
  if (selectedBoardFilter.value === 'closed') {
    const isClosed = selectedBoardFilter.value === 'closed';
    return boards.filter(board => board.closed === isClosed);
  }
  return boards.filter(board => !board.closed);
});

const handleBoardFilterChange = ({ value }) => {
  selectedBoardFilter.value = value;
  isFilterMenuOpen.value = false;
  emit('boardFilterChange', value);
};

const showCount = computed(() => {
  if (selectedBoardFilter.value !== 'all') return true;
  return filteredBoards.value.length > 0;
});

const boardFilterLabel = computed(() => {
  return boardFilterOptions.find(
    option => option.value === selectedBoardFilter.value
  ).label;
});
</script>

<template>
  <div class="flex items-center justify-between w-full">
    <div class="flex items-center gap-4">
      <div class="relative group">
        <OnClickOutside @trigger="isFilterMenuOpen = false">
          <Button
            :label="boardFilterLabel"
            size="sm"
            trailing-icon
            icon="i-lucide-chevron-down"
            color="slate"
            @click="isFilterMenuOpen = !isFilterMenuOpen"
          />
          <DropdownMenu
            v-if="isFilterMenuOpen"
            :menu-items="boardFilterOptions"
            class="left-0 w-40 mt-2 overflow-y-auto xl:right-0 top-full max-h-60"
            @action="handleBoardFilterChange"
          />
        </OnClickOutside>
      </div>
      <div class="w-px h-3.5 rounded my-auto bg-slate-75 dark:bg-slate-800" />
      <span
        v-if="showCount"
        class="min-w-0 text-sm font-medium truncate text-slate-800 dark:text-slate-100"
      >
        {{
          t('WGPT_BOARDS.OVERVIEW.HEADER.BOARDS_COUNT', {
            count: filteredBoards.length,
          })
        }}
      </span>
    </div>
    <div v-if="isAdmin" class="relative">
      <Dropdown align="end">
        <template #trigger="{ toggle }">
          <Button
            :label="t('WGPT_BOARDS.OVERVIEW.HEADER.NEW_BOARD')"
            icon="i-lucide-plus"
            size="sm"
            @click="toggle"
          />
        </template>
        <template #default="{ toggle }">
          <AddBoardDropdown @close="toggle" />
        </template>
      </Dropdown>
    </div>
  </div>
</template>
