<script>
import { required } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import { useAlert } from 'dashboard/composables/wgpt';

import Icon from 'dashboard/components-next/icon/Icon.vue';
import InputNext from 'dashboard/components-next/input/Input.vue';

export default {
  components: {
    Icon,
    InputNext,
  },
  props: {
    board: {
      type: Object,
      default: () => {},
    },
    list: {
      type: Object,
      default: () => {},
    },
    card: {
      type: Object,
      default: () => {},
    },
    readonly: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      name: '',
    };
  },
  validations: {
    name: {
      required,
    },
  },
  watch: {
    card() {
      this.setDefaults();
    },
  },
  mounted() {
    this.setDefaults();
  },
  methods: {
    setDefaults() {
      this.name = this.card.name ?? '';
    },
    async onSubmit(event) {
      try {
        const name = this.name;
        if (!name) return;

        const input = event.target;
        input.blur();

        const boardId = this.board.id;
        const listId = this.list.id;
        const id = this.card.id;
        const data = {
          name: this.name,
        };
        await this.$store.dispatch('wgptBoardListCards/update', {
          boardId,
          listId,
          id,
          ...data,
        });
      } catch (error) {
        const errorMessage =
          error.message ||
          this.$t('WGPT_BOARDS.ITEM.LIST.CARD.EDIT.NAME.API.ERROR_MESSAGE');
        useAlert(errorMessage);
      }
    },
  },
};
</script>

<template>
  <div class="flex gap-4 mb-4">
    <Icon icon="i-lucide-id-card" class="mt-2.5 w-5 h-5 text-slate-500" />
    <div class="flex-1 w-full">
      <label :class="{ error: v$.name.$error }">
        <InputNext
          v-model.trim="name"
          class="name-input"
          custom-input-class="name-native-input !bg-transparent disabled:!bg-transparent disabled:!cursor-text !text-slate-900 dark:!text-slate-100 !border-none !outline !outline-2 !outline-transparent -outline-offset-2 !m-0 text-xl !font-semibold"
          type="text"
          data-testid="board-list-card-name"
          :disabled="readonly"
          @change="onSubmit"
          @keyup.enter="onSubmit"
          @keyup.esc="onSubmit"
        />
      </label>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.name-input {
  ::v-deep {
    input {
      padding: 8px !important;
      height: 40px !important;
      &:focus {
        outline-color: #1f93ff !important;
      }
    }
  }
}
</style>
