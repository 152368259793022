<script>
import { mapGetters } from 'vuex';
import Sidebar from './sidebar/Index.vue';
import Activities from './activities/Index.vue';
import NameEditor from './name/Index.vue';
import Attachments from './attachments/Index.vue';
import DescriptionEditor from './description/Index.vue';
import Labels from './labels/Index.vue';
import Contacts from './contacts/Index.vue';
import Members from './members/Index.vue';
import Dates from './dates/Index.vue';
import CardHeader from './header/Index.vue';
import CustomFields from './customFields/Index.vue';

import ButtonNext from 'dashboard/components-next/button/Button.vue';
import SnackbarContainer from 'dashboard/components-next/wgpt/Snackbar/SnackbarContainer.vue';

export default {
  components: {
    ButtonNext,
    SnackbarContainer,
    CardHeader,
    Sidebar,
    NameEditor,
    Attachments,
    DescriptionEditor,
    Activities,
    Labels,
    Contacts,
    Members,
    Dates,
    CustomFields,
  },
  props: {
    canManageCards: {
      type: Boolean,
      default: false,
    },
    card: {
      type: Object,
      default: () => {},
    },
  },
  emits: ['close'],
  computed: {
    ...mapGetters({
      allLabels: 'labels/getLabels',
      agents: 'agents/getAgents',
    }),
    board() {
      const boardId = this.card.boardId;
      return this.$store.getters['wgptBoards/getBoard'](boardId) ?? {};
    },
    list() {
      const listId = this.card.listId;
      return this.$store.getters['wgptBoardLists/getList'](listId) ?? {};
    },
    availableAgents() {
      const boardConfig = this.board.config ?? {};
      const cardConfig = this.card.config ?? {};

      const boardAuthorization = boardConfig.authorization ?? {};
      const managerIds = boardAuthorization.managers ?? [];
      const operatorIds = boardAuthorization.operators ?? [];
      const readerIds = boardAuthorization.readers ?? [];
      const boardUserIds = [...managerIds, ...operatorIds, ...readerIds];

      const cardMemberIds = cardConfig.members ?? [];

      return this.agents.filter(agent => {
        if (cardMemberIds.includes(agent.id)) return true;
        return boardUserIds.includes(agent.id);
      });
    },
    readonly() {
      return this.board.closed || !this.canManageCards;
    },
    showAttachments() {
      return this.card.attachments?.length > 0;
    },
  },
  methods: {
    onClose() {
      this.$emit('close');
    },
  },
};
</script>

<template>
  <div class="wrapper h-auto pt-4 pb-8 text-start">
    <SnackbarContainer class="top-8 fixed" />

    <ButtonNext
      variant="ghost"
      icon="i-lucide-x"
      color="slate"
      class="absolute end-0 top-0 m-2"
      @click="onClose"
    />
    <CardHeader :card="card" />

    <div class="flex">
      <div class="flex-1 flex flex-col mx-4 overflow-hidden">
        <NameEditor
          :board="board"
          :list="list"
          :card="card"
          :readonly="readonly"
        />

        <div class="[&>:last-child]:mb-8 pl-11 flex flex-wrap gap-4">
          <Labels
            :board="board"
            :list="list"
            :card="card"
            :labels="allLabels"
            :readonly="readonly"
          />
          <Contacts
            :board="board"
            :list="list"
            :card="card"
            :readonly="readonly"
          />
          <Members
            :board="board"
            :list="list"
            :card="card"
            :agents="availableAgents"
            :readonly="readonly"
          />
          <Dates
            :board="board"
            :list="list"
            :card="card"
            :readonly="readonly"
          />
        </div>

        <CustomFields
          :board="board"
          :list="list"
          :card="card"
          :readonly="readonly"
        />

        <DescriptionEditor
          :board="board"
          :list="list"
          :card="card"
          :readonly="readonly"
        />

        <Attachments
          v-if="showAttachments"
          class="mt-8"
          :board="board"
          :list="list"
          :card="card"
          :readonly="readonly"
        />

        <Activities
          class="mt-8"
          :board="board"
          :list="list"
          :card="card"
          :readonly="readonly"
        />
      </div>

      <aside class="mx-4 w-48">
        <Sidebar
          :board="board"
          :list="list"
          :card="card"
          :readonly="readonly"
          :labels="allLabels"
          :agents="availableAgents"
          @on-delete="onClose"
        />
      </aside>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.wrapper {
  width: 768px;
}
</style>
