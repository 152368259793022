<script>
import { mapGetters } from 'vuex';
import { required } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import { useAlert } from 'dashboard/composables';
import { useAdmin } from 'dashboard/composables/useAdmin';
import languages from 'dashboard/components/widgets/conversation/advancedFilterItems/languages.js';

import InputNext from 'dashboard/components-next/input/Input.vue';
import TextAreaNext from 'dashboard/components-next/textarea/TextArea.vue';
import ButtonNext from 'dashboard/components-next/button/Button.vue';
import TagMultiSelectComboBox from 'dashboard/components-next/combobox/TagMultiSelectComboBox.vue';
import ComboBox from 'dashboard/components-next/combobox/ComboBox.vue';
import CheckBox from 'dashboard/components-next/wgpt/Form/CheckBox.vue';

export default {
  components: {
    InputNext,
    TextAreaNext,
    ButtonNext,
    TagMultiSelectComboBox,
    ComboBox,
    CheckBox,
  },
  props: {
    board: {
      type: Object,
      default: () => {},
    },
  },
  emits: ['delete', 'dismiss', 'close', 'submit'],
  setup() {
    const { isAdmin } = useAdmin();
    return { v$: useVuelidate(), isAdmin };
  },
  data() {
    return {
      name: '',
      key: '',
      description: '',
      closed: false,
      managers: [],
      cardCustomFields: [],
      operators: [],
      readers: [],
      autoCardManagementEnabled: false,
      autoCardManagementLanguage: 'en',
      autoCardManagementLabels: [],
      autoCardManagementInboxes: [],
      languages: languages,
    };
  },
  validations: {
    name: {
      required,
    },
    key: {
      isFormatted(value) {
        if (!value) return true;
        if (/^[A-Z][A-Z0-9]*$/.test(value)) return true;
        return false;
      },
      between(value) {
        if (!value) return true;
        if (value.length >= 2 && value.length <= 10) return true;
        return false;
      },
    },
    description: {},
    managers: {},
    operators: {},
    readers: {},
    cardCustomFields: {},
    autoCardManagementEnabled: {},
    autoCardManagementLanguage: {},
    autoCardManagementLabels: {},
    autoCardManagementInboxes: {},
  },
  computed: {
    ...mapGetters({
      allLabels: 'labels/getLabels',
      agents: 'agents/getAgents',
      currentUserID: 'getCurrentUserID',
      customAttributes: 'attributes/getAttributes',
      inboxes: 'inboxes/getInboxes',
    }),
    agentList() {
      const boardManagerIds = this.managers || [];
      const boardOperatorsIds = this.operators || [];
      const boardReadersIds = this.readers || [];

      return field => {
        const items = this.agents.filter(({ id }) => {
          const isManager = boardManagerIds.includes(id);
          const isOperator = boardOperatorsIds.includes(id);
          const isReader = boardReadersIds.includes(id);

          switch (field) {
            case 'managers':
              return !isOperator && !isReader;
            case 'operators':
              return !isManager && !isReader;
            case 'readers':
              return !isManager && !isOperator;
            default:
              return true;
          }
        });

        return this.mapToOptions(items, 'id', 'name');
      };
    },
    customFieldsList() {
      const conversationAttributes =
        this.customAttributes?.filter(
          ({ attribute_model }) => attribute_model === 'conversation_attribute'
        ) || [];
      const items = conversationAttributes.map(
        ({ attribute_key, attribute_display_name }) => ({
          id: attribute_key,
          name: attribute_display_name,
        })
      );
      return this.mapToOptions(items, 'id', 'name');
    },
    readonly() {
      return this.closed || !this.canEditBoard;
    },
    canDeleteBoard() {
      return this.isAdmin;
    },
    canEditBoard() {
      if (this.isAdmin) return true;
      const boardManagerIds = this.board.config?.authorization?.managers || [];
      return boardManagerIds.includes(this.currentUserID);
    },
    labels() {
      return this.mapToOptions(this.allLabels, 'title', 'title');
    },
    languageList() {
      return this.mapToOptions(languages, 'id', 'name');
    },
    inboxList() {
      return this.mapToOptions(this.inboxes, 'id', 'name');
    },
    state() {
      return {
        name: this.name,
        key: this.key || null,
        description: this.description,
        config: {
          authorization: {
            managers: this.managers,
            operators: this.operators,
            readers: this.readers,
          },
          card_custom_fields: {
            fields: this.cardCustomFields,
          },
          auto_card_management: {
            enabled: this.autoCardManagementEnabled,
            language: this.autoCardManagementLanguage,
            labels: this.autoCardManagementLabels,
            inboxes: this.autoCardManagementInboxes,
          },
        },
      };
    },
  },
  watch: {
    agents() {
      // Filter when agents are loaded
      this.managers = this.filterValidAgentIds(this.managers);
      this.operators = this.filterValidAgentIds(this.operators);
      this.readers = this.filterValidAgentIds(this.readers);
    },
  },
  mounted() {
    this.$store.dispatch('agents/get');
    this.$store.dispatch('inboxes/get');
    this.setDefaults();
  },
  methods: {
    setDefaults() {
      this.name = this.board.name;
      this.key = this.board.key || '';
      this.description = this.board.description;
      this.closed = this.board.closed;

      this.managers = this.filterValidAgentIds(
        this.board.config?.authorization?.managers
      );
      this.operators = this.filterValidAgentIds(
        this.board.config?.authorization?.operators
      );
      this.readers = this.filterValidAgentIds(
        this.board.config?.authorization?.readers
      );

      this.cardCustomFields =
        this.board.config?.card_custom_fields?.fields ?? [];

      const autoCardManagement = this.board.config?.auto_card_management ?? {};
      this.autoCardManagementEnabled = autoCardManagement.enabled || false;
      this.autoCardManagementLanguage = autoCardManagement.language || 'en';
      this.autoCardManagementLabels = autoCardManagement.labels || [];
      this.autoCardManagementInboxes = autoCardManagement.inboxes || [];
    },
    filterValidAgentIds(ids) {
      if (!ids || !this.agents) return [];
      return ids.filter(id => this.agents.find(agent => agent.id === id));
    },
    mapToOptions(items, valueKey, labelKey) {
      return (
        items?.map(item => {
          return {
            value: item[valueKey],
            label: item[labelKey],
          };
        }) ?? []
      );
    },
    async onReopen() {
      try {
        const id = this.board.id;
        const data = {
          closed: false,
        };
        await this.$store.dispatch('wgptBoards/update', { id, ...data });

        useAlert(this.$t('WGPT_BOARDS.EDIT.API.SUCCESS_MESSAGE'));
        this.closed = false;
      } catch (error) {
        const errorMessage =
          error.message || this.$t('WGPT_BOARDS.EDIT.API.ERROR_MESSAGE');
        useAlert(errorMessage);
      }
    },
    onClose() {
      this.$emit('close');
    },
    onDismiss() {
      this.$emit('dismiss');
    },
    onDelete() {
      this.$emit('delete');
    },
    onSubmit() {
      this.$emit('submit');
    },
    onKeyInput(ev) {
      this.$nextTick(() => {
        const value = ev.target.value;
        this.key = value.toUpperCase();
        this.v$.key.$touch();
      });
    },
    closeMultiSelect(target) {
      this.$refs[target].deactivate();
    },
  },
};
</script>

<template>
  <form class="flex flex-col gap-8 pb-16" @submit.prevent="onSubmit">
    <ButtonNext class="hidden" />

    <div class="flex flex-col gap-4">
      <label :class="{ error: v$.name.$error }">
        {{ $t('WGPT_BOARDS.EDIT.FORM.NAME.LABEL') }}
        <InputNext
          v-model.trim="name"
          type="text"
          data-testid="board-name"
          :disabled="readonly"
          @input="v$.name.$touch"
        />
        <span v-if="v$.name.$error" class="message">
          {{ $t('WGPT_BOARDS.EDIT.FORM.NAME.ERROR') }}
        </span>
      </label>

      <label :class="{ error: v$.key.$error }">
        {{ $t('WGPT_BOARDS.EDIT.FORM.KEY.LABEL') }}
        <InputNext
          v-model.trim="key"
          type="text"
          data-testid="board-key"
          :disabled="readonly"
          @input="onKeyInput"
        />
        <template v-if="v$.key.$error">
          <span v-if="v$.key.isFormatted.$invalid" class="message">
            {{ $t('WGPT_BOARDS.EDIT.FORM.KEY.ERROR_FORMAT') }}
          </span>
          <span v-else-if="v$.key.between.$invalid" class="message">
            {{ $t('WGPT_BOARDS.EDIT.FORM.KEY.ERROR_BETWEEN') }}
          </span>
        </template>
      </label>

      <label :class="{ error: v$.description.$error }">
        {{ $t('WGPT_BOARDS.EDIT.FORM.DESCRIPTION.LABEL') }}
        <TextAreaNext
          v-model.trim="description"
          custom-text-area-class="!outline !outline-0"
          data-testid="board-description"
          :disabled="readonly"
          rows="3"
          @input="v$.description.$touch"
        />
      </label>
    </div>

    <div class="w-full h-px bg-slate-50 dark:bg-slate-800/50" />

    <div class="flex flex-col gap-4">
      <div class="flex flex-col gap-2">
        <h6 class="text-base font-medium text-n-slate-12">
          {{ $t('WGPT_BOARDS.EDIT.FORM.PERMISSIONS.HEADER') }}
        </h6>
        <span class="text-sm text-n-slate-11">
          {{ $t('WGPT_BOARDS.EDIT.FORM.PERMISSIONS.SUBHEADER') }}
        </span>
      </div>

      <div>
        <label>
          {{ $t('WGPT_BOARDS.EDIT.FORM.PERMISSIONS.MANAGERS.LABEL') }}
        </label>
        <TagMultiSelectComboBox
          v-model="managers"
          :options="agentList('managers')"
          :label="$t('WGPT_BOARDS.EDIT.FORM.PERMISSIONS.MANAGERS.LABEL')"
          :placeholder="$t('WGPT_BOARDS.EDIT.FORM.MULTISELECT.ENTER_TO_SELECT')"
          :disabled="readonly || !agents"
          @select="v$.managers.$touch"
          @remove="v$.managers.$touch"
        />
      </div>

      <div>
        <label>
          {{ $t('WGPT_BOARDS.EDIT.FORM.PERMISSIONS.OPERATORS.LABEL') }}
        </label>
        <TagMultiSelectComboBox
          v-model="operators"
          :options="agentList('operators')"
          :label="$t('WGPT_BOARDS.EDIT.FORM.PERMISSIONS.OPERATORS.LABEL')"
          :placeholder="$t('WGPT_BOARDS.EDIT.FORM.MULTISELECT.ENTER_TO_SELECT')"
          :disabled="readonly || !agents"
          @change="v$.operators.$touch"
          @select="v$.operators.$touch"
          @remove="v$.operators.$touch"
        />
      </div>

      <div>
        <label>
          {{ $t('WGPT_BOARDS.EDIT.FORM.PERMISSIONS.READERS.LABEL') }}
        </label>
        <TagMultiSelectComboBox
          v-model="readers"
          :options="agentList('readers')"
          :label="$t('WGPT_BOARDS.EDIT.FORM.PERMISSIONS.READERS.LABEL')"
          :placeholder="$t('WGPT_BOARDS.EDIT.FORM.MULTISELECT.ENTER_TO_SELECT')"
          :disabled="readonly || !agents"
          @select="v$.readers.$touch"
          @remove="v$.readers.$touch"
        />
      </div>
    </div>

    <div class="w-full h-px bg-slate-50 dark:bg-slate-800/50" />

    <div class="flex flex-col gap-4">
      <div class="flex flex-col gap-2">
        <h6 class="text-base font-medium text-n-slate-12">
          {{ $t('WGPT_BOARDS.EDIT.FORM.CARD_CUSTOM_FIELDS.HEADER') }}
        </h6>
        <span class="text-sm text-n-slate-11">
          {{ $t('WGPT_BOARDS.EDIT.FORM.CARD_CUSTOM_FIELDS.SUBHEADER') }}
        </span>
      </div>

      <label>
        {{ $t('WGPT_BOARDS.EDIT.FORM.CARD_CUSTOM_FIELDS.FIELDS.LABEL') }}
        <TagMultiSelectComboBox
          v-model="cardCustomFields"
          :options="customFieldsList"
          :label="$t('WGPT_BOARDS.EDIT.FORM.CARD_CUSTOM_FIELDS.FIELDS.LABEL')"
          :placeholder="$t('WGPT_BOARDS.EDIT.FORM.MULTISELECT.ENTER_TO_SELECT')"
          :disabled="readonly || !customFieldsList"
          @select="v$.cardCustomFields.$touch"
          @remove="v$.cardCustomFields.$touch"
        />
      </label>
    </div>

    <div class="w-full h-px bg-slate-50 dark:bg-slate-800/50" />

    <div class="flex flex-col gap-4">
      <div class="flex flex-col gap-2">
        <h6 class="text-base font-medium text-n-slate-12">
          {{ $t('WGPT_BOARDS.EDIT.FORM.AUTO_CARD_MANAGEMENT.HEADER') }}
        </h6>
        <span class="text-sm text-n-slate-11">
          {{ $t('WGPT_BOARDS.EDIT.FORM.AUTO_CARD_MANAGEMENT.SUBHEADER') }}
        </span>
      </div>
      <label class="mb-3 flex items-center gap-3">
        <CheckBox
          v-model="autoCardManagementEnabled"
          :disabled="readonly"
          @update="v$.autoCardManagementEnabled.$touch"
        />
        <span>{{
          $t('WGPT_BOARDS.EDIT.FORM.AUTO_CARD_MANAGEMENT.ENABLED.LABEL')
        }}</span>
      </label>

      <template v-if="autoCardManagementEnabled">
        <div>
          <label>
            {{ $t('WGPT_BOARDS.EDIT.FORM.AUTO_CARD_MANAGEMENT.INBOXES.LABEL') }}
          </label>
          <TagMultiSelectComboBox
            v-model="autoCardManagementInboxes"
            :options="inboxList"
            :label="
              $t('WGPT_BOARDS.EDIT.FORM.AUTO_CARD_MANAGEMENT.INBOXES.LABEL')
            "
            :placeholder="
              $t('WGPT_BOARDS.EDIT.FORM.MULTISELECT.ENTER_TO_SELECT')
            "
            :disabled="readonly || !inboxList"
            @select="v$.autoCardManagementInboxes.$touch"
            @remove="v$.autoCardManagementInboxes.$touch"
          />
          <p
            class="min-w-0 mt-1 mb-0 text-xs truncate transition-all duration-500 ease-in-out text-n-slate-11 dark:text-n-slate-11"
          >
            {{ $t('WGPT_BOARDS.EDIT.FORM.AUTO_CARD_MANAGEMENT.INBOXES.DESC') }}
          </p>
        </div>

        <label>
          {{ $t('WGPT_BOARDS.EDIT.FORM.AUTO_CARD_MANAGEMENT.LANGUAGE.LABEL') }}
          <ComboBox
            v-model="autoCardManagementLanguage"
            :options="languageList"
            :label="
              $t('WGPT_BOARDS.EDIT.FORM.AUTO_CARD_MANAGEMENT.LANGUAGE.LABEL')
            "
            :placeholder="
              $t('WGPT_BOARDS.EDIT.FORM.MULTISELECT.ENTER_TO_SELECT')
            "
            :has-error="v$.autoCardManagementLanguage.$error"
            :disabled="readonly"
            class="[&>div>button]:bg-n-alpha-black2 [&>div>button:not(.focused)]:dark:outline-n-weak [&>div>button:not(.focused)]:hover:!outline-n-slate-6"
            @change="v$.autoCardManagementLanguage.$touch"
          />
        </label>

        <div>
          <label>
            {{ $t('WGPT_BOARDS.EDIT.FORM.AUTO_CARD_MANAGEMENT.LABELS.LABEL') }}
          </label>
          <TagMultiSelectComboBox
            v-model="autoCardManagementLabels"
            :options="labels"
            :label="
              $t('WGPT_BOARDS.EDIT.FORM.AUTO_CARD_MANAGEMENT.LABELS.LABEL')
            "
            :placeholder="
              $t('WGPT_BOARDS.EDIT.FORM.MULTISELECT.ENTER_TO_SELECT')
            "
            :disabled="readonly || !labels"
            @select="v$.autoCardManagementLabels.$touch"
            @remove="v$.autoCardManagementLabels.$touch"
          />
          <p
            class="min-w-0 mt-1 mb-0 text-xs truncate transition-all duration-500 ease-in-out text-n-slate-11 dark:text-n-slate-11"
          >
            {{ $t('WGPT_BOARDS.EDIT.FORM.AUTO_CARD_MANAGEMENT.LABELS.DESC') }}
          </p>
        </div>
      </template>
    </div>

    <template v-if="canEditBoard">
      <div class="w-full h-px bg-slate-50 dark:bg-slate-800/50" />

      <div class="flex flex-col-">
        <div v-if="closed" class="flex items-end justify-between w-full gap-4">
          <div class="flex flex-col gap-2">
            <h6 class="text-base font-medium text-n-slate-12">
              {{ $t('WGPT_BOARDS.EDIT.FORM.REOPEN_BOARD') }}
            </h6>
            <span class="text-sm text-n-slate-11">
              {{ $t('WGPT_BOARDS.EDIT.FORM.REOPEN_BOARD_DESC') }}
            </span>
          </div>
          <div class="shrink-0">
            <ButtonNext
              :label="$t('WGPT_BOARDS.EDIT.FORM.OPEN')"
              color="blue"
              class="max-w-56 !w-fit"
              type="button"
              @click.prevent="onReopen"
            />
          </div>
        </div>

        <div v-else class="flex items-end justify-between w-full gap-4">
          <div class="flex flex-col gap-2">
            <h6 class="text-base font-medium text-n-slate-12">
              {{ $t('WGPT_BOARDS.EDIT.FORM.CLOSE_BOARD') }}
            </h6>
            <span class="text-sm text-n-slate-11">
              {{ $t('WGPT_BOARDS.EDIT.FORM.CLOSE_BOARD_DESC') }}
            </span>
          </div>
          <div class="shrink-0">
            <ButtonNext
              :label="$t('WGPT_BOARDS.EDIT.FORM.CLOSE')"
              color="slate"
              class="max-w-56 !w-fit"
              type="button"
              @click.prevent="onClose"
            />
          </div>
        </div>
      </div>
    </template>

    <template v-if="canDeleteBoard && closed">
      <div class="w-full h-px bg-slate-50 dark:bg-slate-800/50" />

      <div class="flex flex-col-">
        <div class="flex items-end justify-between w-full gap-4">
          <div class="flex flex-col gap-2">
            <h6 class="text-base font-medium text-n-slate-12">
              {{ $t('WGPT_BOARDS.EDIT.FORM.DELETE_BOARD') }}
            </h6>
            <span class="text-sm text-n-slate-11">
              {{ $t('WGPT_BOARDS.EDIT.FORM.DELETE_BOARD_DESC') }}
            </span>
          </div>
          <div class="shrink-0">
            <ButtonNext
              :label="$t('WGPT_BOARDS.EDIT.FORM.DELETE')"
              color="ruby"
              class="max-w-56 !w-fit"
              type="button"
              @click.prevent="onDelete"
            />
          </div>
        </div>
      </div>
    </template>
  </form>
</template>
