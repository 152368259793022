<script>
import { debounce } from '@chatwoot/utils';
import { SEARCH_MAX_LENGTH } from './helpers/constant';

import Spinner from 'dashboard/components-next/spinner/Spinner.vue';

export default {
  components: {
    Spinner,
  },
  props: {
    modelValue: {
      type: String,
      default: '',
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      searching: false,
      updatedValue: this.modelValue,
      onSearch: debounce(() => {
        this.$emit('update:modelValue', this.updatedValue);
        this.searching = false;
      }, 750),
    };
  },
  watch: {
    updatedValue(value) {
      if (value.length > SEARCH_MAX_LENGTH) {
        this.updatedValue = value.slice(0, SEARCH_MAX_LENGTH);
        return;
      }
      if (value) {
        this.searching = true;
      }
      this.onSearch();
    },
    modelValue(value, valueBefore) {
      if (value === valueBefore) return;
      this.updatedValue = value;
    },
  },
  mounted() {
    this.focusInput();
  },
  methods: {
    focusInput() {
      // if (!this.$refs.searchbar) return;
      // this.$refs.searchbar.focus();
    },
  },
};
</script>

<template>
  <div class="relative flex items-center mb-2">
    <Spinner v-if="searching" class="absolute size-3.5 top-3 left-3" />
    <span v-else class="absolute i-lucide-search size-3.5 top-3 left-3" />
    <input
      v-model="updatedValue"
      type="search"
      :placeholder="$t('WGPT_BOARDS.FILTER.SEARCH.PLACEHOLDER')"
      class="w-full py-2 pl-9 pr-2 text-sm border border-n-weak rounded-lg bg-n-alpha-black2 dark:bg-n-solid-1 text-n-slate-12"
    />
  </div>
</template>
