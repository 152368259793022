<script>
import { useAlert } from 'dashboard/composables';

import EditBoardDialog from './components/EditBoardDialog.vue';
import BoardActionDialog from './components/BoardActionDialog.vue';

export default {
  components: {
    EditBoardDialog,
    BoardActionDialog,
  },
  data() {
    return {
      selectedBoard: {},
    };
  },
  computed: {
    filters() {
      return this.$store.getters['wgptBoardListCards/getFilters'](this.boardId);
    },
    hasAppliedFilters() {
      return this.$store.getters['wgptBoardListCards/hasFilters'](this.boardId);
    },
    boardId() {
      return this.$route.params.boardId;
    },
    board() {
      return this.$store.getters['wgptBoards/getBoard'](this.boardId);
    },
  },
  methods: {
    showAddBoardPopup() {
      this.$refs.addBoardDialog.open();
    },
    showEditBoardPopup(board) {
      this.selectedBoard = JSON.parse(JSON.stringify(board));
      this.$refs.editBoardDialog.open();
    },
    handleEditBoardDialogAction(action, board) {
      if (action === 'close') {
        this.$refs.boardActionDialog.open('close', board);
      } else if (action === 'delete') {
        this.$refs.boardActionDialog.open('delete', board);
      }
    },
    handleBoardActionDialogConfirmation(action, board) {
      if (action === 'close') {
        this.closeBoard(board);
      } else if (action === 'delete') {
        this.deleteBoard(board);
      }
    },
    async closeBoard(board) {
      try {
        const id = board.id;
        const data = {
          closed: true,
        };
        await this.$store.dispatch('wgptBoards/update', { id, ...data });
        useAlert(this.$t('WGPT_BOARDS.CLOSE.API.SUCCESS_MESSAGE'));
      } catch (error) {
        const errorMessage =
          error.message || this.$t('WGPT_BOARDS.CLOSE.API.ERROR_MESSAGE');
        useAlert(errorMessage);
      }
    },
    async deleteBoard(board) {
      try {
        const id = board.id;
        await this.$store.dispatch('wgptBoards/delete', id);
        this.$router.replace({ name: 'boards_overview' });
        useAlert(this.$t('WGPT_BOARDS.DELETE.API.SUCCESS_MESSAGE'));
      } catch (error) {
        useAlert(this.$t('WGPT_BOARDS.DELETE.API.ERROR_MESSAGE'));
      }
    },
  },
};
</script>

<template>
  <div class="w-full h-full">
    <router-view
      @add-board="showAddBoardPopup"
      @edit-board="showEditBoardPopup"
    />

    <EditBoardDialog
      ref="editBoardDialog"
      :board="selectedBoard"
      @close="handleEditBoardDialogAction('close', selectedBoard)"
      @delete="handleEditBoardDialogAction('delete', selectedBoard)"
    />
    <BoardActionDialog
      ref="boardActionDialog"
      @confirm="handleBoardActionDialogConfirmation"
    />
  </div>
</template>
