<script>
import ButtonNext from 'dashboard/components-next/button/Button.vue';
import DropdownMenu from 'dashboard/components-next/dropdown-menu/DropdownMenu.vue';
import Dropdown from 'dashboard/components-next/wgpt/Dropdown/Dropdown.vue';

export default {
  components: {
    ButtonNext,
    DropdownMenu,
    Dropdown,
  },
  emits: ['delete', 'edit'],
  data() {
    return {
      menuItems: [
        {
          label: this.$t('WGPT_BOARDS.ITEM.LIST.CONTEXT_MENU.SETTINGS'),
          action: 'edit',
          value: 'edit',
          icon: 'i-lucide-settings',
        },
      ],
    };
  },
  methods: {
    handleAction(e, toggle) {
      const { action } = e;
      if (action === 'edit') {
        this.$emit('edit', e);
        toggle();
      } else if (action === 'delete') {
        this.$emit('delete', e);
        toggle();
      }
    },
  },
};
</script>

<template>
  <Dropdown>
    <template #trigger="{ toggle }">
      <ButtonNext
        icon="i-lucide-ellipsis-vertical"
        color="slate"
        size="xs"
        variant="ghost"
        class="rounded-md group-hover:bg-n-alpha-2"
        @click="toggle"
      />
    </template>
    <template #default="{ toggle }">
      <DropdownMenu
        class="!static"
        :menu-items="menuItems"
        @action="e => handleAction(e, toggle)"
      />
    </template>
  </Dropdown>
</template>
