<script>
import { mapGetters } from 'vuex';
import { useAlert } from 'dashboard/composables/wgpt';
import FullEditor from '../../components/FullEditor.vue';
import canDismissMixin from '../../mixins/preventDismissMixin';
import keyboardEventListenerMixins from 'shared/mixins/keyboardEventListenerMixins';
import { emitter } from 'shared/helpers/mitt';
import getUuid from 'widget/helpers/uuid';

import { OnClickOutside } from '@vueuse/components';
import ButtonNext from 'dashboard/components-next/button/Button.vue';
import Avatar from 'dashboard/components-next/avatar/Avatar.vue';

export default {
  components: {
    FullEditor,
    ButtonNext,
    Avatar,
    OnClickOutside,
  },
  mixins: [canDismissMixin, keyboardEventListenerMixins],
  props: {
    board: {
      type: Object,
      default: () => {},
    },
    list: {
      type: Object,
      default: () => {},
    },
    card: {
      type: Object,
      default: () => {},
    },
    uploadFile: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      content: '',
      contentEditorVisible: false,
      selectedActivity: {},
      isUploading: false,
      id: getUuid(),
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
      uiFlags: 'wgptBoardListCardActivities/getUIFlags',
    }),
    preventDismiss() {
      return this.contentEditorVisible;
    },
  },
  mounted() {
    emitter.on('wgptCloseAllBoardListCardActivityEditor', () => {
      this.hideMessageEditor();
    });
  },
  beforeUnmount() {
    emitter.off('wgptCloseAllBoardListCardActivityEditor');
  },
  methods: {
    showMessageEditor() {
      emitter.emit('wgptCloseAllBoardListCardActivityEditor');
      this.contentEditorVisible = true;
      emitter.emit('WGPT_DROPDOWN_TOGGLE', { id: this.id, state: true });
    },
    hideMessageEditor() {
      this.contentEditorVisible = false;
      emitter.emit('WGPT_DROPDOWN_TOGGLE', { id: this.id, state: false });
    },
    async submitComment() {
      try {
        if (!this.content) return;

        const boardId = this.board.id;
        const listId = this.list.id;
        const cardId = this.card.id;
        const data = {
          content: this.content,
        };
        await this.$store.dispatch('wgptBoardListCardActivities/create', {
          boardId,
          listId,
          cardId,
          ...data,
        });

        this.content = '';
        this.selectedActivity = {};
        this.hideMessageEditor();
      } catch (error) {
        const errorMessage =
          error.message ||
          this.$t(
            'WGPT_BOARDS.ITEM.LIST.CARD.EDIT.ACTIVITIES.FORM.API.ERROR_MESSAGE'
          );
        useAlert(errorMessage);
      }
    },
    onFileUploadStart() {
      this.isUploading = true;
    },
    onFileUploadSuccess() {
      this.isUploading = false;
    },
    onFileUploadError() {
      this.isUploading = false;
    },
    getKeyboardEvents() {
      return {
        Escape: {
          action: () => this.hideMessageEditor(),
          allowOnFocusedInput: true,
        },
      };
    },
  },
};
</script>

<template>
  <div class="flex gap-3">
    <Avatar :src="currentUser.avatar_url" :name="currentUser.name" :size="32" />
    <div class="flex-1">
      <div v-if="contentEditorVisible">
        <OnClickOutside @trigger="hideMessageEditor">
          <FullEditor
            v-model.trim="content"
            :placeholder="
              $t(
                'WGPT_BOARDS.ITEM.LIST.CARD.EDIT.ACTIVITIES.FORM.COMMENT.PLACEHOLDER'
              )
            "
            :upload-file="uploadFile"
            @file-upload-start="onFileUploadStart"
            @file-upload-success="onFileUploadSuccess"
            @file-upload-error="onFileUploadError"
          />

          <div class="flex gap-2 mt-2">
            <ButtonNext
              :label="
                $t('WGPT_BOARDS.ITEM.LIST.CARD.EDIT.ACTIVITIES.FORM.SAVE')
              "
              :disabled="uiFlags.isCreating || isUploading"
              :is-loading="uiFlags.isCreating"
              size="sm"
              @click="submitComment"
            />
            <ButtonNext
              :label="$t('WGPT_BOARDS.ITEM.LIST.CARD.EDIT.DESCRIPTION.CANCEL')"
              :disabled="uiFlags.isCreating"
              size="sm"
              variant="faded"
              color="slate"
              @click="hideMessageEditor"
            />
          </div>
        </OnClickOutside>
      </div>
      <label v-else @click="showMessageEditor">
        <div
          class="px-3 py-1 rounded-lg cursor-pointer bg-n-alpha-black2 text-slate-400 dark:text-slate-500 shadow"
        >
          {{
            $t(
              'WGPT_BOARDS.ITEM.LIST.CARD.EDIT.ACTIVITIES.FORM.COMMENT.PLACEHOLDER'
            )
          }}
        </div>
      </label>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.message-editor {
  @apply px-3 outline-none;
  outline-color: #2781f6;
  outline-offset: -2px;

  ::v-deep {
    .ProseMirror-menubar {
      @apply rounded-tl-[4px] mt-1;
    }
  }
}
</style>
