<script>
import { mapGetters } from 'vuex';
import Comment from './Comment.vue';
import { useAdmin } from 'dashboard/composables/useAdmin';
import canDismissMixin from '../../mixins/preventDismissMixin';
import IntersectionObserver from 'dashboard/components/IntersectionObserver.vue';
import { WGPT_BOARD_LIST_CARD_ACTIVITES_QUERY_LIMIT } from './helpers/constants';

import ConfirmDeleteActivityDialog from './ConfirmDeleteActivityDialog.vue';
import Spinner from 'dashboard/components-next/spinner/Spinner.vue';

export default {
  components: {
    ConfirmDeleteActivityDialog,
    Comment,
    IntersectionObserver,
    Spinner,
  },
  mixins: [canDismissMixin],
  props: {
    currentUserId: {
      type: [String, Number],
      default: '',
    },
    board: {
      type: Object,
      default: () => {},
    },
    list: {
      type: Object,
      default: () => {},
    },
    card: {
      type: Object,
      default: () => {},
    },
    readonly: {
      type: Boolean,
      default: true,
    },
    uploadFile: {
      type: Function,
      default: () => {},
    },
  },
  setup() {
    const { isAdmin } = useAdmin();
    return {
      isAdmin,
    };
  },
  data() {
    return {
      selectedComment: {},
      infiniteLoaderOptions: {
        root: this.$refs.commentList,
        rootMargin: '100px 0px 100px 0px',
      },
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'wgptBoardListCardActivities/getUIFlags',
      agents: 'agents/getAgents',
    }),
    comments() {
      return this.$store.getters['wgptBoardListCardActivities/getActivities'](
        this.list.id,
        this.card.id
      );
    },
    canDeleteComments() {
      if (this.readonly) return false;
      if (this.isAdmin) return true;
      const managers = this.board?.config?.authorization?.managers ?? [];
      if (managers.includes(this.currentUserId)) return true;
      return false;
    },
    preventDismiss() {
      return this.showDeleteConfirmationPopup;
    },
  },
  watch: {
    card: {
      handler(card = {}, cardBefore = {}) {
        if (card.id !== cardBefore.id) {
          this.fetchComments();
        }
      },
      immediate: true,
      deep: true,
    },
  },
  mounted() {
    this.fetchUsers();
  },
  beforeUnmount() {
    this.$store.dispatch('wgptBoardListCardActivities/clearActivity');
  },
  methods: {
    fetchComments(limit = WGPT_BOARD_LIST_CARD_ACTIVITES_QUERY_LIMIT) {
      const boardId = this.board.id;
      const listId = this.list.id;
      const cardId = this.card.id;
      if (!cardId || !listId || !boardId) return;

      const getOptions = {
        boardId,
        listId,
        cardId,
        offset: this.comments.length,
        limit,
      };
      this.$store.dispatch('wgptBoardListCardActivities/get', getOptions);
    },
    fetchUsers() {
      this.$store.dispatch('agents/get');
    },
    loadMoreComments() {
      this.fetchComments();
    },
    openDeletePopup(comment) {
      this.selectedComment = comment;
      this.$refs.confirmDeleteCardDialogRef.open();
    },
    async editComment(data) {
      const boardId = this.board.id;
      const listId = this.list.id;
      const cardId = this.card.id;
      await this.$store.dispatch('wgptBoardListCardActivities/update', {
        boardId,
        listId,
        cardId,
        ...data,
      });
    },
  },
};
</script>

<template>
  <div ref="commentList" class="flex flex-col gap-5">
    <Comment
      v-for="comment in comments"
      :key="comment.id"
      :users="agents"
      :comment="comment"
      :on-edit="editComment"
      :on-delete="openDeletePopup"
      :can-delete-comments="canDeleteComments"
      :current-user-id="currentUserId"
      :readonly="readonly"
      :upload-file="uploadFile"
    />

    <div v-if="uiFlags.isFetching" class="text-center">
      <Spinner class="my-4 mx-auto text-n-slate-10" />
    </div>

    <IntersectionObserver
      v-if="uiFlags.hasMore && !uiFlags.isFetching"
      :options="infiniteLoaderOptions"
      @observed="loadMoreComments"
    />

    <ConfirmDeleteActivityDialog
      ref="confirmDeleteCardDialogRef"
      :board="board"
      :list="list"
      :card="card"
      :activity="selectedComment"
    />
  </div>
</template>
