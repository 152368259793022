<script setup>
import { useStore } from 'vuex';
import { computed, ref, watch } from 'vue';
import { useMapGetter } from 'dashboard/composables/store';
import { useAdmin } from 'dashboard/composables/useAdmin';
import { useRouter } from 'vue-router';

import EditCardContent from 'dashboard/routes/dashboard/wgptBoards/item/list/card/edit/Index.vue';
import CardLayout from 'dashboard/components-next/CardLayout.vue';
import Button from 'dashboard/components-next/button/Button.vue';

const props = defineProps({
  notification: {
    type: Object,
    required: true,
  },
});

const wgptInboxViewRef = ref(null);

const router = useRouter();
const store = useStore();
const { isAdmin } = useAdmin();
const currentUserID = useMapGetter('getCurrentUserID');
const getBoard = useMapGetter('wgptBoards/getBoard');
const getList = useMapGetter('wgptBoardLists/getList');
const getCard = useMapGetter('wgptBoardListCards/getCard');

const primaryActor = computed(() => props.notification.primary_actor ?? {});
const card = computed(() => getCard.value(primaryActor.value.id) ?? {});
const board = computed(() => getBoard.value(primaryActor.value.board_id) ?? {});
const list = computed(() => getList.value(primaryActor.value.list_id) ?? {});

const isCardLoading = computed(() => {
  const { board_id: boardId, list_id: listId, id: cardId } = primaryActor.value;
  if (board.value.id !== boardId) return true;
  if (list.value.id !== listId) return true;
  if (card.value.id !== cardId) return true;
  return false;
});

const canManageCards = computed(() => {
  if (board.value.closed) return false;
  if (isAdmin.value) return true;
  const managers = board.value?.config?.authorization?.managers ?? [];
  if (managers.includes(currentUserID.value)) return true;
  const operators = board.value?.config?.authorization?.operators ?? [];
  if (operators.includes(currentUserID.value)) return true;
  return false;
});

const boardLink = computed(() => {
  if (!board.value?.id) return '';
  const routeData = router.resolve({
    name: 'board_item',
    params: {
      boardId: board.value.id,
    },
    query: {
      selectedCard: card.value?.id,
    },
  });
  return routeData.href;
});

const resetScroll = () => {
  if (!wgptInboxViewRef.value) return;
  wgptInboxViewRef.value.scrollTop = 0;
};

watch(
  () => board,
  () => {
    const { board_id: boardId } = primaryActor.value;
    if (board.value.id === boardId) return;
    store.dispatch('wgptBoards/show', boardId);
  },
  {
    deep: true,
    immediate: true,
  }
);

watch(
  () => list,
  () => {
    const { board_id: boardId, list_id: listId } = primaryActor.value;
    if (list.value.id === listId) return;
    store.dispatch('wgptBoardLists/show', { boardId, id: listId });
  },
  {
    deep: true,
    immediate: true,
  }
);

watch(
  () => primaryActor,
  () => {
    store.dispatch('wgptBoardListCardActivities/clearActivity');
    resetScroll();

    const { board_id: boardId, list_id: listId, id } = primaryActor.value;
    if (card.value.id === id) return;

    store.dispatch('wgptBoardListCards/show', { boardId, listId, id });
  },
  {
    deep: true,
    immediate: true,
  }
);
</script>

<template>
  <section class="flex flex-col w-full h-full overflow-hidden bg-n-background">
    <header class="sticky top-0 z-10 px-6 pb-0">
      <div class="w-full max-w-[768px] mx-auto pt-7 pb-3 flex flex-col gap-4">
        <div class="flex items-center justify-between overflow-hidden">
          <div
            class="flex items-center gap-2 overflow-hidden text-xl font-medium text-n-slate-12"
          >
            <span class="truncate flex-inline items-center">
              {{ board?.name }}
              <span class="text-sm font-medium text-n-slate-12">
                / {{ list?.name }}
              </span>
            </span>
          </div>
          <a :href="boardLink" target="blank">
            <Button
              :label="$t('WGPT_BOARDS.INBOX.OPEN_BUTTON_TXT')"
              icon="i-lucide-external-link"
              trailing-icon
              size="sm"
              variant="faded"
              color="slate"
            />
          </a>
        </div>
      </div>
    </header>
    <main
      ref="wgptInboxViewRef"
      class="flex-1 overflow-y-auto overflow-x-visible"
    >
      <div class="w-full max-w-[900px] mx-auto py-3">
        <div class="overflow-auto">
          <CardLayout class="[&>div]:!p-0 w-max mx-auto">
            <div
              v-if="isCardLoading"
              class="flex items-center h-[calc(100%-56px)] justify-center bg-slate-25 dark:bg-slate-800"
            >
              <span class="my-4 spinner" />
            </div>
            <EditCardContent
              v-else
              class="!bg-transparent"
              :card="card"
              :can-manage-cards="canManageCards"
            />
          </CardLayout>
        </div>
      </div>
    </main>
    <!-- Do not remove this slot. It can be used to add dialogs. -->
    <slot />
  </section>
</template>
