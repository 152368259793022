<script>
import Attachment from './Attachment.vue';
import canDismissMixin from '../../mixins/preventDismissMixin';

import Icon from 'dashboard/components-next/icon/Icon.vue';
import ConfirmDeleteAttachmentDialog from './ConfirmDeleteAttachmentDialog.vue';

export default {
  components: {
    Attachment,
    Icon,
    ConfirmDeleteAttachmentDialog,
  },
  mixins: [canDismissMixin],
  props: {
    board: {
      type: Object,
      default: () => {},
    },
    list: {
      type: Object,
      default: () => {},
    },
    card: {
      type: Object,
      default: () => {},
    },
    readonly: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      showDeleteConfirmationPopup: false,
      selectedAttachment: {},
    };
  },
  computed: {
    preventDismiss() {
      return this.showDeleteConfirmationPopup;
    },
  },
  methods: {
    downloadAttachment(attachment) {
      const link = document.createElement('a');
      link.href = attachment.file_url;
      link.download = attachment.filename;
      link.click();
    },
    showDeletePopup(attachment) {
      this.selectedAttachment = attachment;
      this.$refs.confirmDeleteAttachmentDialogRef.open();
    },
  },
};
</script>

<template>
  <div>
    <div class="flex gap-6">
      <Icon icon="i-lucide-paperclip" class="mt-1 w-5 h-5 text-slate-500" />
      <div class="flex-1 w-full overflow-hidden text-wrap">
        <label>
          {{ $t('WGPT_BOARDS.ITEM.LIST.CARD.EDIT.ATTACHMENTS.LABEL') }}
        </label>

        <div class="flex gap-3 overflow-auto p-0.5">
          <Attachment
            v-for="attachment in card.attachments"
            :key="attachment.id"
            :attachment="attachment"
            :readonly="readonly"
            @download="downloadAttachment(attachment)"
            @delete="showDeletePopup(attachment)"
          />
        </div>
      </div>
    </div>

    <ConfirmDeleteAttachmentDialog
      ref="confirmDeleteAttachmentDialogRef"
      :board="board"
      :list="list"
      :card="card"
      :attachment="selectedAttachment"
    />
  </div>
</template>
