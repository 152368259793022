<script>
import { useAdmin } from 'dashboard/composables/useAdmin';
import { useAlert } from 'dashboard/composables/wgpt';
import { mapToOptions } from '../../../../../helpers/dropdownHelper';

import Dropdown from 'dashboard/components-next/wgpt/Dropdown/Dropdown.vue';
import ButtonNext from 'dashboard/components-next/button/Button.vue';
import DropdownMenuContent from '../../../../../components/DropdownMenuContent.vue';

export default {
  components: {
    Dropdown,
    ButtonNext,
    DropdownMenuContent,
  },
  props: {
    board: {
      type: Object,
      default: () => {},
    },
    list: {
      type: Object,
      default: () => {},
    },
    card: {
      type: Object,
      default: () => {},
    },
    labels: {
      type: Array,
      default: () => [],
    },
    readonly: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    const { isAdmin } = useAdmin();
    return {
      isAdmin,
    };
  },
  data() {
    return {
      showDropdown: false,
    };
  },
  computed: {
    selectedLabelIds: {
      get() {
        return this.card.labels ?? [];
      },
      set(value) {
        this.onUpdateLabels(value);
      },
    },
    selectedLabels() {
      return this.labels.filter(label =>
        this.selectedLabelIds.includes(label.title)
      );
    },
    labelList() {
      return mapToOptions(this.labels, 'title', 'title').map(item => {
        return {
          ...item,
          isSelected: this.selectedLabelIds.includes(item.value),
        };
      });
    },
  },
  methods: {
    async onUpdateLabels(selectedLabels) {
      if (this.readonly) return;
      try {
        const boardId = this.board.id;
        const listId = this.list.id;
        const id = this.card.id;
        const originalId = this.card.originalId;
        await this.$store.dispatch('wgptBoardListCards/updateLabels', {
          boardId,
          listId,
          id,
          originalId,
          labels: selectedLabels,
        });
      } catch (error) {
        useAlert(
          this.$t('WGPT_BOARDS.ITEM.LIST.CARD.EDIT.SIDEBAR.API.ERROR_MESSAGE')
        );
      }
    },
    removeItem(label) {
      this.handleAction({ value: label });
    },
    toggleLabels() {
      this.showDropdown = !this.showDropdown;
    },
    closeDropdown() {
      this.showDropdown = false;
    },
    handleAction(item) {
      if (this.selectedLabelIds.includes(item.value)) {
        this.selectedLabelIds = this.selectedLabelIds.filter(
          label => label !== item.value
        );
      } else {
        this.selectedLabelIds = [...this.selectedLabelIds, item.value];
      }
    },
  },
};
</script>

<!-- eslint-disable-next-line vue/no-root-v-if -->
<template>
  <div v-if="selectedLabelIds.length">
    <label>
      {{ $t('WGPT_BOARDS.ITEM.LIST.CARD.EDIT.LABELS.LABEL') }}
    </label>

    <div class="flex overflow-hidden flex-wrap gap-x-1">
      <div
        v-for="label in selectedLabels"
        :key="label.id"
        class="my-0.5 bg-n-alpha-2 rounded-md flex items-center h-7 w-fit py-1 ps-1 pe-1"
      >
        <div
          class="w-2 h-2 m-1 rounded-sm"
          :style="{ backgroundColor: label.color }"
        />
        <span class="text-sm text-n-slate-12">
          {{ label.title }}
        </span>
        <ButtonNext
          v-if="!readonly"
          size="xs"
          color="slate"
          variant="ghost"
          icon="i-lucide-x"
          class="!rounded-md ms-1 !w-5 !h-5"
          @click="removeItem(label.title)"
        />
      </div>

      <Dropdown>
        <template #trigger="{ toggle }">
          <ButtonNext
            v-if="!readonly"
            size="sm"
            color="slate"
            variant="faded"
            icon="i-lucide-plus"
            class="!rounded-xl"
            @click="toggle"
          />
        </template>
        <template #default="{ toggle }">
          <div class="max-w-[219px]">
            <div
              class="pt-2 ps-3 pe-1 mb-0.5 flex justify-between items-center"
            >
              <label class="text-sm font-medium text-n-slate-12">
                {{
                  $t(
                    'WGPT_BOARDS.ITEM.LIST.CARD.EDIT.SIDEBAR.LABELS.DROPDOWN.TITLE'
                  )
                }}
              </label>
              <ButtonNext
                icon="i-lucide-x"
                size="xs"
                color="slate"
                variant="ghost"
                class="opacity-50 hover:opacity-100"
                @click="toggle"
              />
            </div>
            <DropdownMenuContent
              :menu-items="labelList"
              show-search
              :search-placeholder="
                $t(
                  'WGPT_BOARDS.ITEM.LIST.CARD.EDIT.SIDEBAR.LABELS.DROPDOWN.PLACEHOLDER'
                )
              "
              :search-empty-state="
                $t(
                  'WGPT_BOARDS.ITEM.LIST.CARD.EDIT.SIDEBAR.LABELS.DROPDOWN.NO_RESULT'
                )
              "
              class="ltr:right-0 rtl:left-0 xl:ltr:left-0 xl:rtl:right-0 top-full"
              @action="handleAction"
            >
              <template #thumbnail="{ item }">
                <div
                  class="rounded-sm size-2 shrink-0"
                  :style="{ backgroundColor: item.color }"
                />
              </template>
            </DropdownMenuContent>
          </div>
        </template>
      </Dropdown>
    </div>
  </div>
</template>
