<script>
import { useAlert } from 'dashboard/composables/wgpt';
import { mapToOptions } from '../../../../../helpers/dropdownHelper';

import ButtonNext from 'dashboard/components-next/button/Button.vue';
import DropdownMenuContent from '../../../../../components/DropdownMenuContent.vue';
import Dropdown from 'dashboard/components-next/wgpt/Dropdown/Dropdown.vue';

export default {
  components: {
    ButtonNext,
    Dropdown,
    DropdownMenuContent,
  },
  props: {
    board: {
      type: Object,
      default: () => {},
    },
    list: {
      type: Object,
      default: () => {},
    },
    card: {
      type: Object,
      default: () => {},
    },
    labels: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      showDropdown: false,
    };
  },
  computed: {
    selectedLabels: {
      get() {
        return this.card.labels ?? [];
      },
      set(value) {
        this.onUpdateLabels(value);
      },
    },
    labelList() {
      return mapToOptions(this.labels, 'title', 'title').map(item => {
        return {
          ...item,
          isSelected: this.selectedLabels.includes(item.value),
        };
      });
    },
  },
  methods: {
    async onUpdateLabels(selectedLabels) {
      try {
        const boardId = this.board.id;
        const listId = this.list.id;
        const id = this.card.id;
        const originalId = this.card.originalId;
        await this.$store.dispatch('wgptBoardListCards/updateLabels', {
          boardId,
          listId,
          id,
          originalId,
          labels: selectedLabels,
        });
      } catch (error) {
        useAlert(
          this.$t('WGPT_BOARDS.ITEM.LIST.CARD.EDIT.SIDEBAR.API.ERROR_MESSAGE')
        );
      }
    },
    openDropdown() {
      this.showDropdown = true;
    },
    closeDropdown() {
      this.showDropdown = false;
    },
    handleAction(item) {
      if (this.selectedLabels.includes(item.value)) {
        this.selectedLabels = this.selectedLabels.filter(
          label => label !== item.value
        );
      } else {
        this.selectedLabels = [...this.selectedLabels, item.value];
      }
    },
  },
};
</script>

<template>
  <div class="relative">
    <Dropdown align="end">
      <template #trigger="{ toggle }">
        <ButtonNext
          :label="$t('WGPT_BOARDS.ITEM.LIST.CARD.EDIT.SIDEBAR.LABELS.LABEL')"
          size="sm"
          variant="faded"
          color="slate"
          icon="i-lucide-tag"
          :disabled="disabled"
          class="w-full !justify-start"
          @click="toggle"
        />
      </template>
      <template #default="{ toggle }">
        <div class="max-w-[219px]">
          <div class="pt-2 ps-3 pe-1 mb-0.5 flex justify-between items-center">
            <label class="text-sm font-medium text-n-slate-12">
              {{
                $t(
                  'WGPT_BOARDS.ITEM.LIST.CARD.EDIT.SIDEBAR.LABELS.DROPDOWN.TITLE'
                )
              }}
            </label>
            <ButtonNext
              icon="i-lucide-x"
              size="xs"
              color="slate"
              variant="ghost"
              class="opacity-50 hover:opacity-100"
              @click="toggle"
            />
          </div>
          <DropdownMenuContent
            :menu-items="labelList"
            show-search
            :search-placeholder="
              $t(
                'WGPT_BOARDS.ITEM.LIST.CARD.EDIT.SIDEBAR.LABELS.DROPDOWN.PLACEHOLDER'
              )
            "
            :search-empty-state="
              $t(
                'WGPT_BOARDS.ITEM.LIST.CARD.EDIT.SIDEBAR.LABELS.DROPDOWN.NO_RESULT'
              )
            "
            class="ltr:right-0 rtl:left-0 xl:ltr:left-0 xl:rtl:right-0 top-full"
            @action="handleAction"
          >
            <template #thumbnail="{ item }">
              <div
                class="rounded-sm size-2 shrink-0"
                :style="{ backgroundColor: item.color }"
              />
            </template>
          </DropdownMenuContent>
        </div>
      </template>
    </Dropdown>
  </div>
</template>
