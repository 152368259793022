<script>
import { mapGetters } from 'vuex';
import DatePicker from 'vue-datepicker-next';
import { useVuelidate } from '@vuelidate/core';
import format from 'date-fns/format';

import ButtonNext from 'dashboard/components-next/button/Button.vue';
import InputNext from 'dashboard/components-next/input/Input.vue';
import CheckBox from 'dashboard/components-next/wgpt/Form/CheckBox.vue';
import Dropdown from 'dashboard/components-next/wgpt/Dropdown/Dropdown.vue';
import Icon from 'dashboard/components-next/icon/Icon.vue';

export default {
  components: {
    DatePicker,
    ButtonNext,
    InputNext,
    CheckBox,
    Dropdown,
    Icon,
  },
  props: {
    card: {
      type: Object,
      default: () => {},
    },
    onSubmit: {
      type: Function,
      default: () => {},
    },
  },
  emits: ['dropdownClose', 'dropdownOpen', 'close'],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    const enableStartDate = !!this.card.start_date;
    const startDate = new Date(this.card.start_date);
    const startDateInput = this.formatDateForInput(startDate);

    const enableEndDate = !!this.card.end_date;
    const endDate = new Date(this.card.end_date);
    const endDateInput = this.formatDatetimeForInput(endDate);

    return {
      startDateInput,
      enableStartDate,
      endDateInput,
      enableEndDate,
      showStartDateDropdown: false,
      showEndDateDropdown: false,
    };
  },
  validations: {
    startDateInput: {
      isEarlierThanEndDate(value) {
        if (!this.enableStartDate) return true;
        if (!this.enableEndDate) return true;
        if (!this.endDateInput) return true;
        const endDate = new Date(this.endDateInput);
        const startDateExact = new Date(value);
        const startDate = new Date(
          startDateExact.getFullYear(),
          startDateExact.getMonth(),
          startDateExact.getDate()
        );
        return startDate < endDate;
      },
    },
    endDateInput: {
      isLaterThanStartDate(value) {
        if (!this.enableEndDate) return true;
        if (!this.enableStartDate) return true;
        if (!this.startDateInput) return true;
        const startDateExact = new Date(this.startDateInput);
        const startDate = new Date(
          startDateExact.getFullYear(),
          startDateExact.getMonth(),
          startDateExact.getDate()
        );
        const endDate = new Date(value);
        return endDate > startDate;
      },
    },
  },
  computed: {
    ...mapGetters({
      uiFlags: 'wgptBoardListCards/getUIFlags',
    }),
    startDateErrorMessage() {
      if (!this.v$.startDateInput.$error) return '';
      if (!this.v$.startDateInput.isEarlierThanEndDate) {
        return this.$t(
          'WGPT_BOARDS.ITEM.LIST.CARD.EDIT.DATES.START_DATE.EARLIER_THAN_END_DATE'
        );
      }
      return this.$t('WGPT_BOARDS.ITEM.LIST.CARD.EDIT.DATES.START_DATE.ERROR');
    },
    endDateErrorMessage() {
      if (!this.v$.endDateInput.$error) return '';
      if (!this.v$.endDateInput.isLaterThanStartDate) {
        return this.$t(
          'WGPT_BOARDS.ITEM.LIST.CARD.EDIT.DATES.END_DATE.IS_LATER_THEN_START_DATE'
        );
      }
      return this.$t('WGPT_BOARDS.ITEM.LIST.CARD.EDIT.DATES.END_DATE.ERROR');
    },
  },
  watch: {
    enableStartDate(value) {
      if (!value) return;
      let startDateInput;
      if (this.endDateInput) {
        const endDate = new Date(this.endDateInput);
        startDateInput = new Date(
          endDate.getFullYear(),
          endDate.getMonth(),
          endDate.getDate() - 1
        );
      } else {
        startDateInput = new Date();
      }
      this.startDateInput = this.formatDateForInput(startDateInput);
    },
    enableEndDate(value) {
      if (!value) return;
      let endDateInput;
      if (this.enableStartDate) {
        const startDate = new Date(this.startDateInput);
        endDateInput = new Date(
          startDate.getFullYear(),
          startDate.getMonth(),
          startDate.getDate() + 1,
          8
        );
      } else {
        const now = new Date();
        endDateInput = new Date(
          now.getFullYear(),
          now.getMonth(),
          now.getDate() + 1,
          8
        );
      }
      this.endDateInput = this.formatDatetimeForInput(endDateInput);
    },
    startDateInput(value) {
      if (!this.enableEndDate) return;
      const startDateExact = new Date(value);
      const startDate = new Date(
        startDateExact.getFullYear(),
        startDateExact.getMonth(),
        startDateExact.getDate()
      );
      const endDate = new Date(this.endDateInput);
      if (startDate < endDate) return;
      this.enableEndDate = false;
    },
    endDateInput(value) {
      if (!this.enableStartDate) return;
      const endDate = new Date(value);
      const startDateExact = new Date(this.startDateInput);
      const startDate = new Date(
        startDateExact.getFullYear(),
        startDateExact.getMonth(),
        startDateExact.getDate()
      );
      if (endDate > startDate) return;
      this.enableStartDate = false;
    },
  },
  mounted() {
    document.addEventListener('keydown', this.onKeydown);
    if (!this.enableStartDate && !this.enableEndDate) {
      this.enableEndDate = true;
    }
  },
  beforeUnmount() {
    document.removeEventListener('keydown', this.onKeydown);
  },
  methods: {
    formatDateForInput(date) {
      if (!date) return '';
      return format(date, 'yyyy-MM-dd');
    },
    formatDatetimeForInput(date) {
      if (!date) return '';
      return format(date, "yyyy-MM-dd'T'HH:mm");
    },
    changeDates() {
      this.v$.endDateInput.$touch();
      if (this.v$.endDateInput.$error) return;

      const data = {
        start_date: this.enableStartDate ? new Date(this.startDateInput) : null,
        end_date: this.enableEndDate ? new Date(this.endDateInput) : null,
      };
      if (!this.enableStartDate && !this.enableEndDate) {
        data.complete = false;
      }
      this.$emit('close');
      this.onSubmit(data);
    },
    removeDates() {
      const data = {
        start_date: null,
        end_date: null,
        complete: false,
      };
      this.$emit('close');
      this.onSubmit(data);
    },
    onStartDateChange(value) {
      this.startDateInput = this.formatDateForInput(value);
    },
    onEndDateChange(value) {
      this.endDateInput = this.formatDatetimeForInput(value);
    },
    openStartDateDropdown() {
      this.showStartDateDropdown = true;
      this.$emit('dropdownOpen');
    },
    hideStartDateDropdown() {
      this.showStartDateDropdown = false;
      this.$emit('dropdownClose');
    },
    openEndDateDropdown() {
      this.showEndDateDropdown = true;
      this.$emit('dropdownOpen');
    },
    hideEndDateDropdown() {
      this.showEndDateDropdown = false;
      this.$emit('dropdownClose');
    },
    onKeydown(e) {
      if (e.key === 'Escape') {
        this.hideEndDateDropdown();
        this.hideStartDateDropdown();
      }
    },
  },
};
</script>

<template>
  <div class="pt-2 px-2 pb-6 flex flex-col gap-4">
    <div class="flex items-center gap-2">
      <CheckBox
        v-model="enableStartDate"
        :is-checked="enableStartDate"
        class="mt-6"
      />
      <Dropdown class="w-full">
        <template #trigger="{ toggle }">
          <div v-if="enableStartDate" class="flex items-center relative">
            <InputNext
              v-model="startDateInput"
              :label="
                $t('WGPT_BOARDS.ITEM.LIST.CARD.EDIT.DATES.START_DATE.LABEL')
              "
              :placeholder="
                $t(
                  'WGPT_BOARDS.ITEM.LIST.CARD.EDIT.DATES.START_DATE.PLACEHOLDER'
                )
              "
              custom-input-class="!h-10 date-input cursor-pointer"
              type="text"
              readonly
              class="w-full"
              :message="startDateErrorMessage"
              :message-type="startDateErrorMessage ? 'error' : undefined"
              @click="toggle"
            />
            <Icon
              icon="i-lucide-calendar"
              class="absolute end-2 top-9 my-0.5 text-slate-500"
            />
          </div>
          <InputNext
            v-else
            :label="
              $t('WGPT_BOARDS.ITEM.LIST.CARD.EDIT.DATES.START_DATE.LABEL')
            "
            disabled
            custom-input-class="!h-10"
            type="text"
          />
        </template>
        <template #default="{ toggle }">
          <DatePicker
            class="date-picker"
            type="date"
            confirm
            :clearable="false"
            :editable="false"
            open
            :confirm-text="
              $t('WGPT_BOARDS.ITEM.LIST.CARD.EDIT.DATES.START_DATE.CONFIRM')
            "
            :disabled="!enableStartDate"
            :value="startDateInput"
            :append-to-body="false"
            @change="onStartDateChange"
            @confirm="toggle"
          />
        </template>
      </Dropdown>
    </div>

    <div class="flex items-center gap-2">
      <CheckBox
        v-model="enableEndDate"
        :is-checked="enableEndDate"
        class="mt-6"
      />
      <Dropdown class="w-full">
        <template #trigger="{ toggle }">
          <div v-if="enableEndDate" class="flex items-center relative">
            <InputNext
              v-model="endDateInput"
              :label="
                $t('WGPT_BOARDS.ITEM.LIST.CARD.EDIT.DATES.END_DATE.LABEL')
              "
              :placeholder="
                $t('WGPT_BOARDS.ITEM.LIST.CARD.EDIT.DATES.END_DATE.PLACEHOLDER')
              "
              custom-input-class="!h-10 date-input cursor-pointer"
              type="text"
              readonly
              class="w-full"
              :message="endDateErrorMessage"
              :message-type="endDateErrorMessage ? 'error' : undefined"
              @click="toggle"
            />
            <Icon
              icon="i-lucide-calendar"
              class="absolute end-2 top-9 my-0.5 text-slate-500"
            />
          </div>
          <InputNext
            v-else
            :label="$t('WGPT_BOARDS.ITEM.LIST.CARD.EDIT.DATES.END_DATE.LABEL')"
            disabled
            custom-input-class="!h-10"
            type="text"
          />
        </template>
        <template #default="{ toggle }">
          <DatePicker
            class="date-picker"
            type="datetime"
            confirm
            :clearable="false"
            :editable="false"
            open
            :confirm-text="
              $t('WGPT_BOARDS.ITEM.LIST.CARD.EDIT.DATES.END_DATE.CONFIRM')
            "
            :disabled="!enableEndDate"
            :value="endDateInput"
            :append-to-body="false"
            @change="onEndDateChange"
            @confirm="toggle"
          />
        </template>
      </Dropdown>
    </div>

    <div class="flex justify-end gap-2">
      <ButtonNext
        :label="$t('WGPT_BOARDS.ITEM.LIST.CARD.EDIT.DATES.FORM.REMOVE')"
        :disabled="
          uiFlags.isUpdating || showEndDateDropdown || showStartDateDropdown
        "
        variant="ghost"
        color="slate"
        size="sm"
        @click.prevent="removeDates"
      />
      <ButtonNext
        :label="$t('WGPT_BOARDS.ITEM.LIST.CARD.EDIT.DATES.FORM.SAVE')"
        :disabled="
          uiFlags.isUpdating || showEndDateDropdown || showStartDateDropdown
        "
        :is-loading="uiFlags.isUpdating"
        size="sm"
        @click="changeDates"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.date-picker {
  @apply w-[248px];
  ::v-deep {
    .mx-calendar-panel-date {
      @apply px-3 py-1;
    }
    .mx-datepicker-footer {
      @apply border-t-0 p-3 pt-0 pb-2;
    }
    .mx-input-wrapper {
      @apply hidden;
    }
    .mx-datepicker-popup {
      @apply bg-n-solid-2 shadow-none rounded-xl;
      position: static !important;
    }
    .mx-time {
      @apply bg-n-solid-2 shadow-none rounded-xl;
    }
    .mx-time-column {
      @apply border-n-weak;
    }
    .mx-datepicker-btn-confirm {
      @apply bg-n-brand text-white hover:brightness-110 outline-transparent;
      @apply h-8 px-3;
      @apply text-sm;
      @apply inline-flex items-center justify-center min-w-0 gap-2 transition-all duration-200 ease-in-out border-0 rounded-lg outline-1 outline disabled:cursor-not-allowed disabled:pointer-events-none disabled:opacity-50;
    }
  }
}
// .date-picker {
//   @apply w-56;
//   ::v-deep {
//     input[name='date'] {
//       @apply rounded-md dark:bg-slate-900 dark:text-slate-200 text-sm border border-slate-500 p-2 m-0 h-8;
//     }
//     input[type='date']::-webkit-inner-spin-button,
//     input[type='datetime-local']::-webkit-inner-spin-button,
//     input[type='date']::-webkit-calendar-picker-indicator,
//     input[type='datetime-local']::-webkit-calendar-picker-indicator {
//       display: none;
//       -webkit-appearance: none;
//     }
//     .mx-icon-calendar {
//       @apply mt-3;
//     }
//   }
//   &.disabled {
//     ::v-deep {
//       .mx-icon-calendar {
//         @apply hidden;
//       }
//     }
//   }
// }
</style>
