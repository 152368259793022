export default [
  {
    id: 1,
    name: '📍 Project Roadmap',
    description:
      "Visualize your project's journey with clearly defined goals and milestones.",
  },
  {
    id: 2,
    name: '🗓️ Content Calendar',
    description: 'Plan, draft, and schedule your content effortlessly.',
  },
  {
    id: 3,
    name: '💼 Sales Pipeline',
    description: 'Track leads and deals from prospecting to closing.',
  },
];
