<script>
import { useAdmin } from 'dashboard/composables/useAdmin';
import { mapGetters } from 'vuex';
import FilterItem from './FilterItem.vue';
import { FILTER_ITEM_MAX_LENGTH } from './helpers/constant';
import { mapToOptions } from '../../helpers/dropdownHelper';

import ButtonNext from 'dashboard/components-next/button/Button.vue';
import Icon from 'dashboard/components-next/icon/Icon.vue';
import CheckBox from 'dashboard/components-next/wgpt/Form/CheckBox.vue';
import Dropdown from 'dashboard/components-next/wgpt/Dropdown/Dropdown.vue';
import DropdownMenuContent from '../DropdownMenuContent.vue';

export default {
  components: {
    FilterItem,
    Dropdown,
    DropdownMenuContent,
    ButtonNext,
    Icon,
    CheckBox,
  },
  props: {
    modelValue: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['update:modelValue'],
  setup() {
    const { isAdmin } = useAdmin();
    return {
      isAdmin,
    };
  },
  data() {
    return {
      updatedValue: this.modelValue,
      event: {},
    };
  },
  computed: {
    ...mapGetters({
      allLabels: 'labels/getLabels',
    }),
    allLabelList() {
      return mapToOptions(this.allLabels, 'title', 'title').map(item => {
        return {
          ...item,
          isSelected: this.updatedValue.includes(item.value),
        };
      });
    },
    labels() {
      return this.allLabels.slice(0, FILTER_ITEM_MAX_LENGTH);
    },
    unloadedLabels() {
      return this.updatedValue.filter(
        title => !this.labels.find(label => label.title === title)
      );
    },
    hasAnyItem() {
      return this.labels.length > 0;
    },
    showBadge() {
      return this.unloadedLabels.length > 0;
    },
    showMoreOptions() {
      return this.allLabels.length > FILTER_ITEM_MAX_LENGTH;
    },
  },
  watch: {
    updatedValue(value) {
      this.$emit('update:modelValue', value);
    },
    modelValue(value, oldValue) {
      if (value.join() === oldValue.join()) return;
      this.updatedValue = value;
    },
  },
  methods: {
    openDropdownLabel(e) {
      this.event = e;
    },
    handleAction({ value }) {
      if (this.updatedValue.includes(value)) {
        this.updatedValue = this.updatedValue.filter(v => v !== value);
      } else {
        this.updatedValue.push(value);
      }
    },
  },
};
</script>

<template>
  <div v-show="hasAnyItem">
    <label class="text-sm mb-2 text-slate-600 dark:text-slate-400">{{
      $t('WGPT_BOARDS.FILTER.LABELS.TITLE')
    }}</label>
    <FilterItem v-for="label in labels" :key="label.id" class="group py-1.5">
      <template #start>
        <CheckBox v-model="updatedValue" :value="label.title" />
      </template>
      <woot-label
        :key="label.id"
        :title="label.title"
        :description="label.description"
        :show-close="false"
        :bg-color="label.color + '9F'"
        class="!w-full !h-8 !m-0"
      />
    </FilterItem>

    <Dropdown>
      <template #trigger="{ toggle }">
        <ButtonNext
          v-if="showMoreOptions"
          variant="link"
          class="!ms-6 !mt-2 [&>*]:pointer-events-none"
          @click="toggle"
        >
          <div v-if="showBadge" class="w-[24px] flex justify-center">
            <div class="badge">
              <span>
                {{ unloadedLabels.length }}
              </span>
            </div>
          </div>
          {{ $t('WGPT_BOARDS.FILTER.BUTTON_TXT.SHOW_MORE_OPTIONS') }}
          <Icon icon="i-lucide-chevron-down" class="w-4 h-4" />
        </ButtonNext>
      </template>

      <template #default="{ toggle }">
        <div class="max-w-[219px]">
          <div class="pt-2 ps-3 pe-1 mb-0.5 flex justify-between items-center">
            <label class="text-sm font-medium text-n-slate-12">
              {{
                $t(
                  'WGPT_BOARDS.ITEM.LIST.CARD.EDIT.SIDEBAR.LABELS.DROPDOWN.TITLE'
                )
              }}
            </label>
            <ButtonNext
              icon="i-lucide-x"
              size="xs"
              color="slate"
              variant="ghost"
              class="opacity-50 hover:opacity-100"
              @click="toggle"
            />
          </div>
          <DropdownMenuContent
            :menu-items="allLabelList"
            show-search
            :search-placeholder="
              $t(
                'WGPT_BOARDS.ITEM.LIST.CARD.EDIT.SIDEBAR.LABELS.DROPDOWN.PLACEHOLDER'
              )
            "
            :search-empty-state="
              $t(
                'WGPT_BOARDS.ITEM.LIST.CARD.EDIT.SIDEBAR.LABELS.DROPDOWN.NO_RESULT'
              )
            "
            class="ltr:right-0 rtl:left-0 xl:ltr:left-0 xl:rtl:right-0 top-full"
            @action="handleAction"
          >
            <template #thumbnail="{ item }">
              <div
                class="rounded-sm size-2 shrink-0"
                :style="{ backgroundColor: item.color }"
              />
            </template>
          </DropdownMenuContent>
        </div>
      </template>
    </Dropdown>
  </div>
</template>

<style lang="scss" scoped>
.badge {
  @apply inline-block bg-slate-50 dark:bg-woot-800 rounded-md text-slate-600 dark:text-slate-100 h-5 text-xxs font-semibold px-1 min-w-[16px] text-center;
}
</style>
