<script>
import { useAlert } from 'dashboard/composables';
import { mapGetters } from 'vuex';
import { required } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';

import ButtonNext from 'dashboard/components-next/button/Button.vue';
import InputNext from 'dashboard/components-next/input/Input.vue';

export default {
  components: {
    ButtonNext,
    InputNext,
  },
  emits: ['close'],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      name: '',
    };
  },
  validations: {
    name: {
      required,
    },
  },
  computed: {
    ...mapGetters({
      uiFlags: 'wgptBoardLists/getUIFlags',
    }),
  },
  mounted() {
    this.autoFocusNameField();
  },
  methods: {
    autoFocusNameField() {
      this.$nextTick(() => {
        const inputEl = this.$refs.addCardNameField.$el.querySelector('input');
        if (inputEl) {
          inputEl.focus();
        }
      });
    },
    onClose() {
      this.$emit('close');
    },
    async onSubmit() {
      try {
        if (this.v$.$invalid) {
          this.v$.$touch();
          return;
        }

        const boardId = this.$route.params.boardId;
        const boardList = {
          name: this.name,
        };
        await this.$store.dispatch('wgptBoardLists/create', {
          boardId,
          ...boardList,
        });

        useAlert(this.$t('WGPT_BOARDS.ITEM.LIST.ADD.API.SUCCESS_MESSAGE'));
        this.onClose();
      } catch (error) {
        const errorMessage =
          error.message ||
          this.$t('WGPT_BOARDS.ITEM.LIST.ADD.API.ERROR_MESSAGE');
        useAlert(errorMessage);
      }
    },
  },
};
</script>

<template>
  <form
    class="mx-0 flex flex-wrap w-full gap-4 pb-4"
    @submit.prevent="onSubmit"
  >
    <div class="w-full">
      <label :class="{ error: v$.name.$error }">
        <InputNext
          ref="addCardNameField"
          v-model.trim="name"
          :placeholder="$t('WGPT_BOARDS.ITEM.LIST.ADD.FORM.NAME.PLACEHOLDER')"
          :message-type="v$.name.$error ? 'error' : 'info'"
          custom-input-class="!h-10"
          @input="v$.name.$touch"
        />
      </label>
    </div>

    <div class="gap-2 w-full flex">
      <ButtonNext
        :label="$t('WGPT_BOARDS.ITEM.LIST.ADD.FORM.CREATE')"
        size="sm"
        color="blue"
        :disabled="uiFlags.isCreating"
        :is-loading="uiFlags.isCreating"
      />
      <ButtonNext
        size="sm"
        variant="ghost"
        color="slate"
        icon="i-lucide-x"
        @click.prevent="onClose"
      />
    </div>
  </form>
</template>
