<script setup>
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { emitter } from 'shared/helpers/mitt';
import getUuid from 'widget/helpers/uuid';
import Dialog from 'dashboard/components-next/dialog/Dialog.vue';
defineProps({
  automation: {
    type: Object,
    default: () => ({}),
  },
  onConfirm: {
    type: Function,
    default: () => ({}),
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
});
const { t } = useI18n();
const dialogRef = ref(null);
const id = getUuid();
const onShow = () => {
  emitter.emit('WGPT_DROPDOWN_TOGGLE', { id, state: true });
};
const onHide = () => {
  emitter.emit('WGPT_DROPDOWN_TOGGLE', { id, state: false });
};
const open = () => {
  dialogRef.value?.open();
  onShow();
};
const close = () => {
  dialogRef.value?.close();
  onHide();
};
defineExpose({ open, close });
</script>

<template>
  <Dialog
    ref="dialogRef"
    :title="
      t('WGPT_BOARDS.ITEM.LIST.CARD.EDIT.SIDEBAR.AUTOMATION.CONFIRM.TITLE')
    "
    :description="
      t('WGPT_BOARDS.ITEM.LIST.CARD.EDIT.SIDEBAR.AUTOMATION.CONFIRM.DESC', {
        automationName: automation.name,
      })
    "
    :is-loading="isLoading"
    :confirm-button-label="
      t('WGPT_BOARDS.ITEM.LIST.CARD.EDIT.SIDEBAR.AUTOMATION.CONFIRM.EXECUTE')
    "
    :cancel-button-label="
      t('WGPT_BOARDS.ITEM.LIST.CARD.EDIT.SIDEBAR.AUTOMATION.CONFIRM.CANCEL')
    "
    @confirm="onConfirm"
    @close="onHide"
  />
</template>
