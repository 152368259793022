<script>
import { useAlert } from 'dashboard/composables/wgpt';
import ContactMultiselectDropdown from '../../../../../components/ContactMultiselectDropdown.vue';

import ButtonNext from 'dashboard/components-next/button/Button.vue';
import Dropdown from 'dashboard/components-next/wgpt/Dropdown/Dropdown.vue';

export default {
  components: {
    ContactMultiselectDropdown,
    Dropdown,
    ButtonNext,
  },
  props: {
    board: {
      type: Object,
      default: () => {},
    },
    list: {
      type: Object,
      default: () => {},
    },
    card: {
      type: Object,
      default: () => {},
    },
    disabled: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      showSearchDropdown: false,
    };
  },
  computed: {
    contactIds() {
      return this.card.config?.contacts ?? [];
    },
  },
  methods: {
    async onUpdate(selectedItems) {
      try {
        const boardId = this.board.id;
        const listId = this.list.id;
        const id = this.card.id;
        const data = {
          config: {
            ...this.card.config,
            contacts: selectedItems,
          },
        };
        await this.$store.dispatch('wgptBoardListCards/update', {
          boardId,
          listId,
          id,
          ...data,
        });
      } catch (error) {
        useAlert(
          this.$t('WGPT_BOARDS.ITEM.LIST.CARD.EDIT.SIDEBAR.API.ERROR_MESSAGE')
        );
      }
    },
    openDropdown() {
      this.showSearchDropdown = true;
    },
    closeDropdown() {
      this.showSearchDropdown = false;
    },
    addItem({ id }) {
      const result = this.contactIds.concat(id);
      this.onUpdate(result);
    },
    removeItem({ id }) {
      const result = this.contactIds.filter(
        selectedContact => selectedContact !== id
      );
      this.onUpdate(result);

      if (result.length > 0) return;
      this.closeDropdown();
    },
  },
};
</script>

<template>
  <div class="relative">
    <Dropdown align="end">
      <template #trigger="{ toggle }">
        <ButtonNext
          :label="$t('WGPT_BOARDS.ITEM.LIST.CARD.EDIT.SIDEBAR.CONTACTS.LABEL')"
          size="sm"
          variant="faded"
          color="slate"
          icon="i-lucide-square-user"
          :disabled="disabled"
          class="w-full !justify-start"
          @click="toggle"
        />
      </template>
      <template #default="{ toggle }">
        <ContactMultiselectDropdown
          :selected-ids="contactIds"
          @add="addItem"
          @remove="removeItem"
          @close="toggle"
          @toggle="toggle"
        />
      </template>
    </Dropdown>
  </div>
</template>

<style lang="scss" scoped>
li.disabled {
  @apply opacity-50 pointer-events-none select-none;
}

.contact-dropdown ::v-deep {
  .dropdown-pane {
    @apply w-44;
  }
}
</style>
