<script>
import { mapGetters } from 'vuex';
import { useAlert } from 'dashboard/composables/wgpt';
import FullEditor from '../../components/FullEditor.vue';
import { useMessageFormatter } from 'shared/composables/useMessageFormatter';
import canDismissMixin from '../../mixins/preventDismissMixin';
import keyboardEventListenerMixins from 'shared/mixins/keyboardEventListenerMixins';
import { emitter } from 'shared/helpers/mitt';
import getUuid from 'widget/helpers/uuid';

import { OnClickOutside } from '@vueuse/components';
import Icon from 'dashboard/components-next/icon/Icon.vue';
import ButtonNext from 'dashboard/components-next/button/Button.vue';

export default {
  components: {
    FullEditor,
    Icon,
    ButtonNext,
    OnClickOutside,
  },
  mixins: [canDismissMixin, keyboardEventListenerMixins],
  props: {
    board: {
      type: Object,
      default: () => {},
    },
    list: {
      type: Object,
      default: () => {},
    },
    card: {
      type: Object,
      default: () => {},
    },
    readonly: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    const { formatMessage } = useMessageFormatter();
    return { formatMessage };
  },
  data() {
    return {
      content: '',
      contentEditorVisible: false,
      isUploading: false,
      id: getUuid(),
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'wgptBoardListCards/getUIFlags',
    }),
    placeholder() {
      return this.$t(
        this.readonly
          ? 'WGPT_BOARDS.ITEM.LIST.CARD.EDIT.DESCRIPTION.EMPTY'
          : 'WGPT_BOARDS.ITEM.LIST.CARD.EDIT.DESCRIPTION.PLACEHOLDER'
      );
    },
    preventDismiss() {
      return this.contentEditorVisible;
    },
  },
  methods: {
    showMessageEditor() {
      if (this.readonly) return;
      this.content = (this.content || this.card.description) ?? '';

      emitter.emit('wgptCloseAllBoardListCardActivityEditor');
      this.contentEditorVisible = true;
      emitter.emit('WGPT_DROPDOWN_TOGGLE', { id: this.id, state: true });
    },
    hideMessageEditor(persistInput) {
      const ProseMirrorPrompt = document.querySelector('.ProseMirror-prompt');
      if (ProseMirrorPrompt) return;
      this.contentEditorVisible = false;
      emitter.emit('WGPT_DROPDOWN_TOGGLE', { id: this.id, state: false });
      if (!persistInput) {
        this.content = '';
      }
    },
    async onSubmit() {
      try {
        const boardId = this.board.id;
        const listId = this.list.id;
        const id = this.card.id;
        const data = {
          description: this.content,
        };
        await this.$store.dispatch('wgptBoardListCards/update', {
          boardId,
          listId,
          id,
          ...data,
        });

        this.hideMessageEditor();
      } catch (error) {
        const errorMessage =
          error.message ||
          this.$t(
            'WGPT_BOARDS.ITEM.LIST.CARD.EDIT.DESCRIPTION.API.ERROR_MESSAGE'
          );
        useAlert(errorMessage);
      }
    },
    async uploadFileToStorage(file) {
      const data = {
        file,
        boardId: this.board.id,
        listId: this.list.id,
        id: this.card.id,
      };
      const fileInfo = await this.$store.dispatch(
        'wgptBoardListCards/addAttachment',
        data
      );
      return fileInfo.file_url;
    },
    onFileUploadStart() {
      this.isUploading = true;
    },
    onFileUploadSuccess() {
      this.isUploading = false;
    },
    onFileUploadError() {
      this.isUploading = false;
    },
    getKeyboardEvents() {
      return {
        Escape: {
          action: () => this.hideMessageEditor(),
          allowOnFocusedInput: true,
        },
      };
    },
  },
};
</script>

<template>
  <div>
    <div class="flex gap-6">
      <Icon icon="i-lucide-align-left" class="mt-1 w-5 h-5 text-slate-500" />
      <div class="flex-1 w-full overflow-hidden text-wrap">
        <label class="mb-1">
          {{ $t('WGPT_BOARDS.ITEM.LIST.CARD.EDIT.DESCRIPTION.LABEL') }}
        </label>

        <div v-if="contentEditorVisible">
          <OnClickOutside @trigger="hideMessageEditor(true)">
            <FullEditor
              v-model.trim="content"
              :placeholder="placeholder"
              :upload-file="uploadFileToStorage"
              @file-upload-start="onFileUploadStart"
              @file-upload-success="onFileUploadSuccess"
              @file-upload-error="onFileUploadError"
            />

            <div class="flex gap-2 mt-2">
              <ButtonNext
                :label="$t('WGPT_BOARDS.ITEM.LIST.CARD.EDIT.DESCRIPTION.SAVE')"
                :disabled="uiFlags.isCreating || isUploading"
                :is-loading="uiFlags.isCreating"
                size="sm"
                @click="onSubmit"
              />
              <ButtonNext
                :is-disabled="uiFlags.isCreating"
                size="sm"
                variant="faded"
                color="slate"
                @click="() => hideMessageEditor()"
              >
                {{ $t('WGPT_BOARDS.ITEM.LIST.CARD.EDIT.DESCRIPTION.CANCEL') }}
              </ButtonNext>
            </div>
          </OnClickOutside>
        </div>
        <label v-else @click="showMessageEditor">
          <div
            v-if="card.description"
            v-dompurify-html="formatMessage(card.description)"
            class="dompurified-html min-h-[3.5em] shadow px-3 py-2 rounded-lg bg-n-alpha-black2 [&>p]:text-slate-900 [&>p]:dark:text-slate-300 [&>p:first-child]:mt-0"
            :class="{ 'cursor-pointer': !readonly }"
          />
          <div
            v-else
            class="min-h-[3.5em] px-3 py-1 rounded-lg cursor-pointer bg-n-alpha-black2 text-slate-400 dark:text-slate-500 shadow"
          >
            {{ placeholder }}
          </div>
        </label>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.dompurified-html {
  ::v-deep {
    ul {
      @apply pl-4;
    }
  }
}
</style>
