<script setup>
import { ref, computed } from 'vue';
import { useEmitter } from 'dashboard/composables/emitter';

import Dialog from 'dashboard/components-next/dialog/Dialog.vue';
import EditCard from './Index.vue';

defineProps({
  card: {
    type: Object,
    default: () => ({}),
  },
  canManageCards: {
    type: Boolean,
    default: false,
  },
  canDeleteCards: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['close']);

const dialogRef = ref(null);
const isOpen = ref(false);

const stack = ref([]);
const canDismiss = computed(() => stack.value.length === 0);

const open = () => {
  dialogRef.value?.open();
  isOpen.value = true;
};

const close = (force = false) => {
  if (!canDismiss.value && !force) return;
  dialogRef.value?.close();
};

const onDialogClose = () => {
  if (!canDismiss.value) return;
  isOpen.value = false;
  emit('close');
};

useEmitter('WGPT_DROPDOWN_TOGGLE', ({ id, state }) => {
  if (state) {
    stack.value = [...stack.value, id];
  } else {
    stack.value = stack.value.filter(item => item !== id);
  }
});

defineExpose({ open, close });
</script>

<template>
  <!-- eslint-disable -->
  <Dialog
    ref="dialogRef"
    title=""
    :show-confirm-button="false"
    :show-cancel-button="false"
    :can-dismiss="canDismiss"
    width="3xl"
    overflow-y-auto
    custom-dialog-class="[&>div>div]:p-0 [&>div>div]:gap-0 [&>div>div]:!transform-none outline-none"
    @close="onDialogClose"
  >
    <EditCard
      v-if="isOpen"
      :card="card"
      :can-manage-cards="canManageCards"
      :can-delete-cards="canDeleteCards"
      @close="close"
    />
  </Dialog>
  <!--eslint-enable-->
</template>
