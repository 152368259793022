<script setup>
import { nextTick, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'dashboard/composables/store';
import { useMapGetter } from 'dashboard/composables/store';
import { useAlert } from 'dashboard/composables';

import Dialog from 'dashboard/components-next/dialog/Dialog.vue';
import EditBoardForm from './EditBoardForm.vue';

const props = defineProps({
  board: {
    type: Object,
    default: () => ({}),
  },
});

const emit = defineEmits(['delete', 'close']);
const { t } = useI18n();

const store = useStore();

const isOpen = ref(false);
const dialogRef = ref(null);
const boardFormRef = ref(null);

const uiFlags = useMapGetter('wgptBoards/getUIFlags');

const handleSubmit = async () => {
  if (!boardFormRef.value) return;
  const { state, v$ } = boardFormRef.value;

  try {
    if (v$.$invalid) {
      v$.$touch();
      return;
    }

    const id = props.board.id;
    const payload = state;

    await store.dispatch('wgptBoards/update', { id, ...payload });

    const successMessage = t(`WGPT_BOARDS.EDIT.API.SUCCESS_MESSAGE`);
    useAlert(successMessage);
    dialogRef.value.close();
  } catch (error) {
    const errorMessage =
      error?.message || t(`WGPT_BOARDS.EDIT.API.ERROR_MESSAGE`);
    useAlert(errorMessage);
  }
};

const handleOpen = () => {
  isOpen.value = true;
};

const handleDismiss = () => {
  isOpen.value = false;
};

const dismiss = () => {
  dialogRef.value.close();
  handleDismiss();
};

const open = () => {
  handleOpen();
  nextTick(() => {
    dialogRef.value.open();
  });
};

const handleClose = () => {
  dismiss();
  emit('close');
};

const handleDelete = () => {
  dismiss();
  emit('delete');
};

defineExpose({ open, dismiss });
</script>

<template>
  <Dialog
    ref="dialogRef"
    type="edit"
    :title="t('WGPT_BOARDS.EDIT.TITLE')"
    :description="t('WGPT_BOARDS.EDIT.DESC')"
    :is-loading="uiFlags.isUpdating"
    :disable-confirm-button="uiFlags.isUpdating"
    :show-confirm-button="!boardFormRef?.readonly"
    :confirm-button-label="t('WGPT_BOARDS.EDIT.FORM.SAVE')"
    overflow-y-auto
    @confirm="handleSubmit"
    @close="handleDismiss"
  >
    <EditBoardForm
      v-if="isOpen"
      ref="boardFormRef"
      :board="board"
      @submit="handleSubmit"
      @close="handleClose"
      @delete="handleDelete"
    />
  </Dialog>
</template>
