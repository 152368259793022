<script setup>
import { ref } from 'vue';
import { copyTextToClipboard } from 'shared/helpers/clipboard';

import Icon from 'dashboard/components-next/icon/Icon.vue';
import ButtonNext from 'dashboard/components-next/button/Button.vue';
import InlineTooltip from 'dashboard/components-next/wgpt/Tooltip/Inline.vue';
import { useI18n } from 'vue-i18n';

defineProps({
  card: {
    type: Object,
    default: () => {},
  },
});

const { t } = useI18n();

const tooltipRef = ref(null);

const copyLink = async () => {
  await copyTextToClipboard(window.location.href);
  tooltipRef.value.show();
};
</script>

<template>
  <div class="ltr:pl-14 rtl:pr-14 ml-1">
    <InlineTooltip
      ref="tooltipRef"
      placement="right-start"
      popper-class="-mt-0.5"
      :content="t('WGPT_BOARDS.ITEM.LIST.CARD.EDIT.COPY_SUCCESSFUL')"
    >
      <ButtonNext
        variant="link"
        color="slate"
        size="xs"
        class="!font-semibold hover:underline [&:not(:hover)>.icon]:opacity-0 inline-flex items-center gap-1.5"
        @click="copyLink"
      >
        <span>{{ card.key }}</span>
        <Icon icon="i-lucide-copy" class="icon w-3 h-3" />
      </ButtonNext>
    </InlineTooltip>
  </div>
</template>
