<script>
import { isSameYear } from 'date-fns';
import { useAlert } from 'dashboard/composables/wgpt';
import { messageStamp } from 'shared/helpers/timeHelper';
import DueDateBadge from './DueDateBadge.vue';
import EditDates from './EditDates.vue';

import ButtonNext from 'dashboard/components-next/button/Button.vue';
import Icon from 'dashboard/components-next/icon/Icon.vue';
import CheckBox from 'v3/components/Form/CheckBox.vue';
import Dropdown from 'dashboard/components-next/wgpt/Dropdown/Dropdown.vue';

export default {
  components: {
    DueDateBadge,
    EditDates,
    Dropdown,
    ButtonNext,
    Icon,
    CheckBox,
  },
  props: {
    board: {
      type: Object,
      default: () => {},
    },
    list: {
      type: Object,
      default: () => {},
    },
    card: {
      type: Object,
      default: () => {},
    },
    readonly: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      showDropdown: false,
      innerDropdownOpen: false,
      complete: false,
    };
  },
  computed: {
    startDate() {
      return this.card.start_date;
    },
    endDate() {
      return this.card.end_date;
    },
    showDates() {
      return this.startDate || this.endDate;
    },
    showDueDateBadge() {
      return this.endDate;
    },
    readableStartDate() {
      const startDateInput = this.card.start_date;
      if (!startDateInput) return '';
      const now = new Date();
      const startDate = new Date(startDateInput);
      return messageStamp(
        startDate.getTime() / 1_000,
        isSameYear(startDate, now) ? 'LLL d' : 'LLL d, y'
      );
    },
    readableEndDate() {
      const endDateInput = this.card.end_date;
      if (!endDateInput) return '';
      const now = new Date();
      const endDate = new Date(endDateInput);
      return messageStamp(
        endDate.getTime() / 1_000,
        isSameYear(endDate, now) ? 'LLL d, h:mm aa' : 'LLL d, y, h:mm aa'
      );
    },
  },
  watch: {
    card() {
      this.setDefaults();
    },
  },
  mounted() {
    this.setDefaults();
  },
  methods: {
    setDefaults() {
      this.complete = this.card.complete ?? false;
    },
    async onCompleteToggle() {
      if (this.readonly) return;
      try {
        const boardId = this.board.id;
        const listId = this.list.id;
        const id = this.card.id;
        const data = {
          complete: !this.complete,
        };
        await this.$store.dispatch('wgptBoardListCards/update', {
          boardId,
          listId,
          id,
          ...data,
        });
      } catch (error) {
        useAlert(
          this.$t('WGPT_BOARDS.ITEM.LIST.CARD.EDIT.DATES.API.ERROR_MESSAGE')
        );
      }
    },
    async onDatesChange(data) {
      if (this.readonly) return;
      try {
        const boardId = this.board.id;
        const listId = this.list.id;
        const id = this.card.id;
        await this.$store.dispatch('wgptBoardListCards/update', {
          boardId,
          listId,
          id,
          ...data,
        });
      } catch (error) {
        useAlert(
          this.$t('WGPT_BOARDS.ITEM.LIST.CARD.EDIT.DATES.API.ERROR_MESSAGE')
        );
      }
    },
    toggleDropdown() {
      this.showDropdown = true;
    },
    closeDropdown() {
      if (this.innerDropdownOpen) return;
      this.showDropdown = false;
    },
    onDropdownOpen() {
      this.innerDropdownOpen = true;
    },
    onDropdownClose() {
      this.innerDropdownOpen = false;
    },
  },
};
</script>

<!-- eslint-disable-next-line vue/no-root-v-if -->
<template>
  <div v-if="showDates">
    <label>{{ $t('WGPT_BOARDS.ITEM.LIST.CARD.EDIT.DATES.LABEL') }}</label>

    <div class="flex items-center gap-2">
      <CheckBox
        v-if="!readonly && endDate"
        :disabled="readonly"
        :is-checked="complete"
        @update="onCompleteToggle"
      />

      <Dropdown>
        <template #trigger="{ toggle }">
          <ButtonNext
            size="sm"
            color="slate"
            variant="outline"
            :disabled="readonly"
            :class="{ '!opacity-100': readonly }"
            @click="toggle"
          >
            <label class="date-labels pointer-events-none">
              <span v-if="readableStartDate">{{ readableStartDate }}</span>
              <span v-if="readableEndDate">{{ readableEndDate }}</span>
            </label>

            <DueDateBadge
              v-if="showDueDateBadge"
              class="dates-badge ml-2 mr-1"
              :start-date="startDate"
              :end-date="endDate"
              :complete="complete"
            />
            <Icon
              v-if="!readonly"
              icon="i-lucide-chevron-down"
              class="flex-shrink-0"
            />
          </ButtonNext>
        </template>
        <template #default="{ toggle }">
          <EditDates
            :board="board"
            :list="list"
            :card="card"
            :on-submit="onDatesChange"
            @close="toggle"
            @dropdown-open="onDropdownOpen"
            @dropdown-close="onDropdownClose"
          />
        </template>
      </Dropdown>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.date-labels > *:nth-child(1n + 2)::before {
  content: ' - ';
  display: inline;
}

.dates-badge {
  ::v-deep {
    .due-date-badge {
      height: 16px;
      @apply py-0 px-1 border-none;
      span {
        @apply pointer-events-none;
      }
    }
  }
}

.disabled {
  @apply pointer-events-none;
}

.edit-dates-dropdown ::v-deep .dropdown-pane {
  @apply fixed;
}
</style>
