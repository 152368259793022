<script setup>
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import Dialog from 'dashboard/components-next/dialog/Dialog.vue';
import Input from 'dashboard/components-next/input/Input.vue';

const emit = defineEmits(['confirm']);
const { t } = useI18n();

const dialogRef = ref(null);
const actionType = ref('close');
const board = ref({});
const confirmationInput = ref('');
const errorMessage = ref('');

const open = (action, selectedBoard) => {
  actionType.value = action;
  board.value = selectedBoard;
  confirmationInput.value = '';
  dialogRef.value.open();
};

const handleDialogConfirm = () => {
  if (confirmationInput.value === board.value.name) {
    emit('confirm', actionType.value, board.value);
    dialogRef.value.close();
    errorMessage.value = '';
  } else {
    errorMessage.value = t('WGPT_BOARDS.ACTION_DIALOG.ERROR_MESSAGE');
  }
};

defineExpose({ open });
</script>

<template>
  <Dialog
    ref="dialogRef"
    :title="t(`WGPT_BOARDS.ACTION_DIALOG.${actionType.toUpperCase()}_HEADER`)"
    :confirm-button-label="
      t(
        `WGPT_BOARDS.ACTION_DIALOG.${actionType.toUpperCase()}_CONFIRM_BUTTON_LABEL`
      )
    "
    :type="actionType === 'delete' ? 'alert' : 'edit'"
    @confirm="handleDialogConfirm"
  >
    <p>
      {{ t(`WGPT_BOARDS.ACTION_DIALOG.${actionType.toUpperCase()}_MESSAGE`) }}
    </p>
    <Input
      v-model="confirmationInput"
      :label="
        t('WGPT_BOARDS.ACTION_DIALOG.CONFIRMATION_LABEL', {
          boardName: board.name,
        })
      "
      :message="errorMessage"
      :message-type="errorMessage ? 'error' : 'info'"
    />
  </Dialog>
</template>
