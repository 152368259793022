<script setup>
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'dashboard/composables/store';
import { useMapGetter } from 'dashboard/composables/store';
import { useAlert } from 'dashboard/composables';
import { useRouter } from 'vue-router';

import BoardForm from '../../components/AddBoardForm.vue';
import Button from 'dashboard/components-next/button/Button.vue';

const emit = defineEmits(['close']);
const { t } = useI18n();

const store = useStore();
const router = useRouter();

const addBoardFormRef = ref(null);

const uiFlags = useMapGetter('wgptBoards/getUIFlags');

const handleSubmit = async () => {
  if (!addBoardFormRef.value) return;
  const { v$, state } = addBoardFormRef.value;

  try {
    if (v$.$invalid) {
      v$.$touch();
      return;
    }

    const data = {
      name: state.name,
      description: state.description,
    };
    const newBoard = await store.dispatch('wgptBoards/create', data);

    useAlert(t('WGPT_BOARDS.ADD.API.SUCCESS_MESSAGE'));
    emit('close');
    router.replace({
      name: 'board_item',
      params: { boardId: newBoard.id },
    });
  } catch (error) {
    const errorMessage =
      error.message || t('WGPT_BOARDS.ADD.API.ERROR_MESSAGE');
    useAlert(errorMessage);
  }
};

const handleCancel = () => {
  emit('close');
};
</script>

<template>
  <div class="w-[400px] p-6 flex flex-col gap-6">
    <h3 class="text-base font-medium text-slate-900 dark:text-slate-50">
      {{ t(`WGPT_BOARDS.ADD.TITLE`) }}
    </h3>
    <BoardForm ref="addBoardFormRef" @submit="handleSubmit" />
    <div class="flex items-center justify-between w-full gap-3">
      <Button
        variant="faded"
        color="slate"
        :label="t('WGPT_BOARDS.ADD.FORM.CANCEL')"
        class="w-full bg-n-alpha-2 n-blue-text hover:bg-n-alpha-3"
        @click="handleCancel"
      />
      <Button
        :label="t(`WGPT_BOARDS.ADD.FORM.CREATE`)"
        class="w-full"
        :disabled="uiFlags.isCreating"
        :is-loading="uiFlags.isCreating"
        @click="handleSubmit"
      />
    </div>
  </div>
</template>
