<script>
import { useAlert } from 'dashboard/composables/wgpt';
import { mapToOptions } from '../../../../../helpers/dropdownHelper';

import ButtonNext from 'dashboard/components-next/button/Button.vue';
import DropdownMenuContent from '../../../../../components/DropdownMenuContent.vue';
import Avatar from 'dashboard/components-next/avatar/Avatar.vue';
import Dropdown from 'dashboard/components-next/wgpt/Dropdown/Dropdown.vue';

export default {
  components: {
    ButtonNext,
    Dropdown,
    DropdownMenuContent,
    Avatar,
  },
  props: {
    board: {
      type: Object,
      default: () => {},
    },
    list: {
      type: Object,
      default: () => {},
    },
    card: {
      type: Object,
      default: () => {},
    },
    agents: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      showDropdown: false,
    };
  },
  computed: {
    selectedMembers: {
      get() {
        return this.card.config?.members ?? [];
      },
      set(value) {
        this.onUpdate(value);
      },
    },
    agentList() {
      return mapToOptions(this.agents, 'id', 'name').map(item => {
        return {
          ...item,
          isSelected: this.selectedMembers.includes(item.value),
        };
      });
    },
  },
  methods: {
    async onUpdate(selectedItems) {
      try {
        const boardId = this.board.id;
        const listId = this.list.id;
        const id = this.card.id;
        const data = {
          config: {
            ...this.card.config,
            members: selectedItems,
          },
        };
        await this.$store.dispatch('wgptBoardListCards/update', {
          boardId,
          listId,
          id,
          ...data,
        });
      } catch (error) {
        useAlert(
          this.$t('WGPT_BOARDS.ITEM.LIST.CARD.EDIT.SIDEBAR.API.ERROR_MESSAGE')
        );
      }
    },
    toggleDropdown() {
      this.showDropdown = !this.showDropdown;
    },
    closeDropdown() {
      this.showDropdown = false;
    },
    handleAction(item) {
      if (this.selectedMembers.includes(item.value)) {
        this.selectedMembers = this.selectedMembers.filter(
          selectedMember => selectedMember !== item.value
        );
      } else {
        this.selectedMembers = [...this.selectedMembers, item.value];
      }
    },
  },
};
</script>

<template>
  <div class="relative">
    <Dropdown align="end">
      <template #trigger="{ toggle }">
        <ButtonNext
          :label="$t('WGPT_BOARDS.ITEM.LIST.CARD.EDIT.SIDEBAR.MEMBERS.LABEL')"
          size="sm"
          variant="faded"
          color="slate"
          icon="i-lucide-users"
          :disabled="disabled"
          class="w-full !justify-start"
          @click="toggle"
        />
      </template>
      <template #default="{ toggle }">
        <div>
          <div class="pt-2 ps-3 pe-1 mb-0.5 flex justify-between items-center">
            <label class="text-sm font-medium text-n-slate-12">
              {{
                $t(
                  'WGPT_BOARDS.ITEM.LIST.CARD.EDIT.SIDEBAR.MEMBERS.DROPDOWN.TITLE'
                )
              }}
            </label>
            <ButtonNext
              icon="i-lucide-x"
              size="xs"
              color="slate"
              variant="ghost"
              class="opacity-50 hover:opacity-100"
              @click="toggle"
            />
          </div>
          <DropdownMenuContent
            :menu-items="agentList"
            show-search
            :search-placeholder="
              $t(
                'WGPT_BOARDS.ITEM.LIST.CARD.EDIT.SIDEBAR.MEMBERS.DROPDOWN.PLACEHOLDER'
              )
            "
            :search-empty-state="
              $t(
                'WGPT_BOARDS.ITEM.LIST.CARD.EDIT.SIDEBAR.MEMBERS.DROPDOWN.NO_RESULT'
              )
            "
            class="ltr:right-0 rtl:left-0 xl:ltr:left-0 xl:rtl:right-0 top-full"
            @action="handleAction"
          >
            <template #thumbnail="{ item }">
              <Avatar :src="item.thumbnail" :name="item.label" :size="20" />
            </template>
          </DropdownMenuContent>
        </div>
      </template>
    </Dropdown>
  </div>
</template>
