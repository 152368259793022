<script>
import { url } from '@vuelidate/validators';
import { copyTextToClipboard } from 'shared/helpers/clipboard';
import { getRegexp } from 'shared/helpers/Validators';
import canDismissMixin from '../../mixins/preventDismissMixin';
import DatePicker from 'vue-datepicker-next';
import format from 'date-fns/format';
import { useVuelidate } from '@vuelidate/core';

import ButtonNext from 'dashboard/components-next/button/Button.vue';
import Dropdown from 'dashboard/components-next/wgpt/Dropdown/Dropdown.vue';
import CheckBox from 'v3/components/Form/CheckBox.vue';
import Icon from 'dashboard/components-next/icon/Icon.vue';
import InlineTooltip from 'dashboard/components-next/wgpt/Tooltip/Inline.vue';

const DATE_FORMAT = 'yyyy-MM-dd';

export default {
  components: {
    DatePicker,
    ButtonNext,
    Dropdown,
    CheckBox,
    Icon,
    InlineTooltip,
  },
  mixins: [canDismissMixin],
  props: {
    label: { type: String, required: true },
    description: { type: String, default: '' },
    values: { type: Array, default: () => [] },
    value: { type: [String, Number, Boolean], default: '' },
    attributeType: { type: String, default: 'text' },
    attributeRegex: {
      type: String,
      default: null,
    },
    regexCue: { type: String, default: null },
    attributeKey: { type: String, required: true },
    readonly: { type: Boolean, default: true },
    loading: { type: Boolean, default: false },
    onUpdate: { type: Function, required: true },
  },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      isEditing: false,
      editedValue: null,
      showDateDropdown: false,
      lang: {
        days: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
        yearFormat: 'YYYY',
        monthFormat: 'MMMM',
      },
    };
  },
  computed: {
    displayValue() {
      if (this.isAttributeTypeDate) {
        return this.value ? format(new Date(this.value), DATE_FORMAT) : '';
      }
      if (this.isAttributeTypeCheckbox) {
        return this.value === 'false' ? false : this.value;
      }
      return this.value;
    },
    formattedValue() {
      if (!this.value) return this.value;
      if (this.isAttributeTypeDate) return new Date(this.value);
      if (this.isAttributeTypeCheckbox)
        return this.value === true ? 'true' : 'false';
      return this.value;
    },
    isAttributeTypeCheckbox() {
      return this.attributeType === 'checkbox';
    },
    isAttributeTypeList() {
      return this.attributeType === 'list';
    },
    isAttributeTypeLink() {
      return this.attributeType === 'link';
    },
    isAttributeTypeDate() {
      return this.attributeType === 'date';
    },
    isAttributeTypeNumber() {
      return this.attributeType === 'number';
    },
    notAttributeTypeCheckboxAndListAndDate() {
      return (
        !this.isAttributeTypeCheckbox &&
        !this.isAttributeTypeList &&
        !this.isAttributeTypeDate
      );
    },
    shouldShowErrorMessage() {
      return this.v$.editedValue.$error;
    },
    shouldShowActionButtons() {
      if (this.isEditing) return false;
      if (this.isAttributeTypeNumber) {
        return Number.isFinite(this.value);
      }
      return !!this.value;
    },
    errorMessage() {
      if (this.v$.editedValue.url === false) {
        return this.$t('CUSTOM_ATTRIBUTES.VALIDATIONS.INVALID_URL');
      }
      if (this.v$.editedValue.numeric === false) {
        return this.$t(
          'WGPT_BOARDS.ITEM.LIST.CARD.EDIT.CUSTOM_FIELDS.VALIDATIONS.INVALID_NUMBER'
        );
      }
      if (this.v$.editedValue.regexValidation === false) {
        return this.regexCue
          ? this.regexCue
          : this.$t('CUSTOM_ATTRIBUTES.VALIDATIONS.INVALID_INPUT');
      }
      return this.$t('CUSTOM_ATTRIBUTES.VALIDATIONS.REQUIRED');
    },
    preventDismiss() {
      return this.isEditing || this.showDateDropdown;
    },
  },
  watch: {
    value() {
      this.editedValue = this.formattedValue;
    },
  },
  validations() {
    if (this.isAttributeTypeLink) {
      return {
        editedValue: {
          url,
        },
      };
    }
    if (this.isAttributeTypeNumber) {
      return {
        editedValue: {
          numeric: value => {
            if (!value) return true;
            const valueNumber = Number(value);
            return !Number.isNaN(valueNumber);
          },
        },
      };
    }
    return {
      editedValue: {
        regexValidation: value => {
          if (!value) return true;
          if (!this.attributeRegex) return true;
          return getRegexp(this.attributeRegex).test(value);
        },
      },
    };
  },
  mounted() {
    this.setDefault();
  },
  methods: {
    setDefault() {
      this.editedValue = this.formattedValue;
    },
    focusInput() {
      if (this.$refs.inputfield) {
        this.$refs.inputfield.focus();
      }
    },
    blurInput() {
      if (this.$refs.inputfield) {
        this.$refs.inputfield.blur();
      }
    },
    onFocus() {
      if (this.readonly) return;
      this.isEditing = true;
    },
    onBlur(e) {
      if (e.relatedTarget?.classList?.contains('submit-button')) {
        this.onUpdateValue();
      } else {
        this.isEditing = false;
        this.setDefault();
      }
    },
    onInput(event) {
      if (this.isAttributeTypeNumber) {
        const value = event.target.value;
        const valueNumber = Number(value);
        if (Number.isNaN(valueNumber)) {
          this.editedValue = value.slice(0, value.length - 1);
        }
      }
    },
    getKeyboardEvents() {
      return {
        Escape: {
          action: () => {
            this.blurInput();
            this.hideDateDropdown();
          },
          allowOnFocusedInput: true,
        },
      };
    },
    async onUpdateValue() {
      this.v$.$touch();
      if (this.v$.$invalid) {
        this.focusInput();
        return;
      }

      let updatedValue = this.editedValue;
      if (this.isAttributeTypeNumber) {
        updatedValue = this.editedValue ? Number(this.editedValue) : null;
      }

      try {
        await this.onUpdate(this.attributeKey, updatedValue);
        this.isEditing = false;
        this.blurInput();
      } catch (error) {
        this.focusInput();
      }
    },
    onUpdateListValue(value) {
      this.editedValue = value;
      this.onUpdateValue();
    },
    onDateChange(value, toggle) {
      this.editedValue = value;
      this.showDateDropdown = false;
      toggle();
      this.onUpdateValue();
    },
    openDateDropdown() {
      this.showDateDropdown = true;
    },
    hideDateDropdown() {
      this.showDateDropdown = false;
    },
    async onCopy(tooltip) {
      await copyTextToClipboard(this.displayValue);
      tooltip.show();
    },
  },
};
</script>

<template>
  <div>
    <div class="group flex items-center mb-1">
      <h4 class="text-sm flex items-center m-0 error gap-1 overflow-hidden">
        <div
          class="grow inline-flex items-start font-semibold text-[13px] mb-0 overflow-hidden"
          :class="
            v$.editedValue.$error
              ? 'text-ruby-700'
              : 'text-slate-800 dark:text-slate-100'
          "
        >
          <span class="truncate">{{ label }}</span>
        </div>
        <div class="hidden group-hover:flex">
          <Icon
            v-if="description"
            v-tooltip.top="{
              content: description,
              container: false,
            }"
            icon="i-lucide-info"
            class="text-slate-600 dark:text-slate-400 shrink-0"
          />
        </div>
      </h4>
    </div>

    <div v-if="notAttributeTypeCheckboxAndListAndDate" class="group relative">
      <div>
        <label class="w-full flex items-center" for="inputField">
          <input
            ref="inputfield"
            v-model="editedValue"
            type="text"
            class="text-field reset-base"
            :readonly="readonly || loading"
            :class="{ error: v$.editedValue.$error, focus: isEditing }"
            @keyup.enter="onUpdateValue"
            @focus="onFocus"
            @blur="onBlur"
          />
          <ButtonNext
            v-if="isEditing || loading"
            size="xs"
            icon="i-lucide-check"
            tabindex="0"
            class="submit-button shrink-0 !w-[32px] !h-[32px] ltr:!rounded-l-none rtl:!rounded-r-none"
            :is-loading="loading"
            :disabled="loading"
            @click="onUpdateValue"
          />
        </label>
        <span
          v-if="shouldShowErrorMessage"
          class="text-ruby-700 text-[13px] block font-normal -mt-px w-full"
        >
          {{ errorMessage }}
        </span>
      </div>

      <div
        v-if="shouldShowActionButtons"
        class="absolute top-0 end-0 flex flex-wrap-reverse gap-0.5 m-1"
      >
        <InlineTooltip
          ref="tooltipRef1"
          :content="$t('WGPT_BOARDS.ITEM.LIST.CARD.EDIT.COPY_SUCCESSFUL')"
        >
          <ButtonNext
            v-tooltip="$t('CUSTOM_ATTRIBUTES.ACTIONS.COPY')"
            size="xs"
            color="slate"
            icon="i-lucide-clipboard"
            variant="solid"
            class="hidden group-hover:flex hover:!bg-n-slate-3 flex-shrink-0"
            @click="onCopy($refs.tooltipRef1)"
          />
        </InlineTooltip>
        <a v-if="isAttributeTypeLink" :href="displayValue" target="blank">
          <ButtonNext
            size="xs"
            color="slate"
            variant="solid"
            icon="i-lucide-external-link"
            class="hidden group-hover:flex hover:!bg-n-slate-3 flex-shrink-0"
          />
        </a>
      </div>
    </div>
    <div v-if="isAttributeTypeDate" class="group relative">
      <div class="flex items-center min-h-[1.75rem]">
        <Dropdown>
          <template #trigger="{ toggle }">
            <div
              v-if="shouldShowActionButtons"
              class="absolute top-0 end-0 flex flex-wrap-reverse gap-0.5 m-1"
            >
              <InlineTooltip
                ref="tooltipRef2"
                :content="$t('WGPT_BOARDS.ITEM.LIST.CARD.EDIT.COPY_SUCCESSFUL')"
              >
                <ButtonNext
                  v-tooltip="$t('CUSTOM_ATTRIBUTES.ACTIONS.COPY')"
                  size="xs"
                  color="slate"
                  icon="i-lucide-clipboard"
                  class="hidden group-hover:flex hover:!bg-n-slate-3 flex-shrink-0"
                  @click="onCopy($refs.tooltipRef2)"
                />
              </InlineTooltip>
            </div>

            <input
              readonly
              type="text"
              :value="displayValue"
              class="text-field reset-base !bg-n-alpha-2"
              :class="{ '!cursor-pointer': !readonly }"
              :disabled="readonly"
              @click="toggle"
            />
          </template>
          <template #default="{ toggle }">
            <DatePicker
              class="date-picker"
              type="date"
              :confirm="false"
              :clearable="false"
              :editable="false"
              :disabled="readonly || loading"
              :append-to-body="false"
              :lang="lang"
              open
              :value="editedValue"
              @change="e => onDateChange(e, toggle)"
              @clear="e => onDateChange(e, toggle)"
              @open="openDateDropdown"
              @confirm="toggle"
            >
              <template #footer>
                <div>
                  <ButtonNext
                    :label="
                      $t(
                        'WGPT_BOARDS.ITEM.LIST.CARD.EDIT.CUSTOM_FIELDS.CLEAR_BUTTON_TEXT'
                      )
                    "
                    size="sm"
                    variant="faded"
                    color="slate"
                    @click="onDateChange(null, toggle)"
                  />
                </div>
              </template>
            </DatePicker>
          </template>
        </Dropdown>
      </div>
    </div>
    <div v-if="isAttributeTypeCheckbox">
      <div class="flex items-center min-h-[1.75rem]">
        <CheckBox
          v-model="editedValue"
          :is-checked="displayValue"
          :disabled="readonly || loading"
          @change="onUpdateValue"
        />
      </div>
    </div>
    <div v-if="isAttributeTypeList">
      <input
        v-if="readonly"
        type="text"
        readonly
        :value="displayValue"
        class="text-field reset-base"
      />
      <select
        v-else
        v-model="editedValue"
        class="!bg-n-alpha-2 rounded-lg mb-0 break-all h-8 py-0 pl-2 min-h-[1.75rem] font-semibold border-none truncate text-sm text-slate-800 dark:text-slate-50 cursor-pointer"
        :disabled="readonly || loading"
        @change="onUpdateListValue($event.target.value)"
      >
        <option selected value="">---</option>
        <option v-for="(option, index) in values" :key="index" :value="option">
          {{ option }}
        </option>
      </select>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.date-picker {
  @apply w-[248px];
  ::v-deep {
    .mx-calendar-panel-date {
      @apply px-3 py-1;
    }
    .mx-datepicker-footer {
      @apply border-t-0 p-3 pt-0 pb-2;
    }
    .mx-input-wrapper {
      @apply hidden;
    }
    .mx-datepicker-popup {
      @apply bg-n-solid-2 shadow-none rounded-xl;
      position: static !important;
    }
  }
}

.submit-button ::v-deep .spinner.small {
  @apply m-0;
}

.text-field {
  @apply h-8 text-sm transition-none font-semibold rounded-lg w-full px-2 bg-n-alpha-2 text-slate-800 dark:text-slate-50 border-0;
  &.focus {
    @apply bg-n-alpha-black2 dark:bg-n-solid-1 border border-n-brand rounded-e-none ltr:border-r-0 rtl:border-l-0 cursor-default;
  }
  &:not(.focus) {
    @apply cursor-pointer read-only:cursor-text;
  }
}
</style>
